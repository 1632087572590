<template>
  <Table
    :items="filteredCourses"
    :headers="headers"
    :search.sync="searchTerm"
    :itemsPerPage="20"
  >
    <template v-slot:button>
      <ExportDialog type="course" css-class="mr-8"></ExportDialog>
      <v-btn @click="$emit('item-new')"
        ><v-icon left>fal fa-plus</v-icon> Neuer Kurs</v-btn
      >
    </template>
    <template v-slot:actions>
      <v-select
        label="Ort"
        v-model="filterBath"
        :items="baths"
        item-value="id"
        item-text="title"
      ></v-select>
    </template>
    <template v-slot:item.category="{ item }">
      {{ getCategory(item.category).title }}
    </template>
    <template v-slot:item.bathTitle="{ item }">
      <v-chip label outlined @click="searchTerm = item.bathTitle"
        >{{ item.bathShortcut }} {{ item.bathLaneTitle }}</v-chip
      >
    </template>
    <template v-slot:item.type="{ item }">
      <v-chip
        label
        outlined
        @click="searchTerm = 'Kurs'"
        v-if="item.type === 'course'"
        >Kurs</v-chip
      >
      <v-chip
        label
        outlined
        @click="searchTerm = 'Club'"
        v-else-if="item.type === 'club'"
        >Club</v-chip
      >
      <v-chip
        label
        outlined
        @click="searchTerm = 'Hybrid Kurs'"
        v-else-if="item.type === 'hybrid-course'"
        >Hybrid Kurs</v-chip
      >
    </template>
    <template v-slot:item.price="{ item }"> {{ item.price }} € </template>
    <template v-slot:item.instructorNamesSearchable="{ item }">
      <v-chip
        class="mr-2"
        label
        outlined
        v-for="instructor in item.instructors"
        :key="instructor.shortcut"
        @click="searchTerm = instructor.name"
        >{{ instructor.shortcut }}</v-chip
      >
    </template>
    <template v-slot:item.workload="{ item }">
      <CourseWorkloadText
        :capacity="item.capacity"
        :capacity-min="item.capacityMin"
        :capacity-used="item.capacityUsed"
        :on-waiting-list="item.onWaitingList"
      ></CourseWorkloadText>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon
            ><v-icon>fas fa-info</v-icon></v-btn
          >
        </template>
        <div v-if="item.comment !== ''">
          Kommentar: {{ item.comment }}<br /><br />
        </div>
        <div>Termine:</div>
        <ul class="eventList">
          <li v-for="(event, index) in getEvents(item.id)" :key="index">
            <template v-if="event.archived === false">
              {{ event.startDate | formatDateWeekday }}
              {{ event.startDate | formatDateTime }} -
              {{ event.endDate | formatTime }}
            </template>
          </li>
        </ul>
      </v-tooltip>
      <v-btn icon v-on:click="$emit('item-edit', item.id)"
        ><v-icon>fal fa-edit</v-icon></v-btn
      >
      <v-btn
        icon
        v-on:click="$emit('item-delete', item)"
        :disabled="item.capacityUsed > 0"
        ><v-icon>fal fa-trash</v-icon></v-btn
      >
      <v-btn icon @click="getMailString(item)"
        ><v-icon>fal fa-envelope</v-icon></v-btn
      >
      <v-btn icon @click="$emit('item-notification', item)"
        ><v-icon>fal fa-sms</v-icon></v-btn
      >
      <v-btn
        icon
        :to="{ name: 'bookingEdit', params: { id: 0, course: item.id } }"
        ><v-icon>fal fa-cart-plus</v-icon></v-btn
      >
      <v-btn
        icon
        :to="{ name: 'bookings', params: { filterSearch: 'K' + item.id } }"
        ><v-icon>fal fa-shopping-cart</v-icon></v-btn
      >
    </template>
  </Table>
</template>

<script>
import CourseWorkloadText from "@/components/course/CourseWorkloadText";
import Table from "@/components/utils/Table";
import moment from "moment/src/moment";
import ExportDialog from "@/components/ExportDialog.vue";

export default {
  name: "CourseTable",
  components: {
    ExportDialog,
    CourseWorkloadText,
    Table
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Titel", value: "title" },
        { text: "Typ", value: "type" },
        { text: "Bad", value: "bathTitle" },
        { text: "Trainer", value: "instructorNamesSearchable" },
        { text: "Startdatum", value: "startDate" },
        { text: "Preis", value: "price", align: "right", filterable: false },
        {
          text: "Auslastung",
          value: "workload",
          align: "right",
          filterable: false
        },
        { text: "Aktion", value: "action", filterable: false }
      ],
      filters: { category: [], type: [], bathLane: [], instructor: [] },
      filterBath: null,
      search: {},
      courses: [],
      exportDialog: false,
      exportFilter: {
        startDate: null,
        endDate: null,
        template: null,
        bath: null
      }
    };
  },
  computed: {
    coursesState: function() {
      return this.$store.getters["courses/getAll"];
    },
    filteredCourses: function() {
      let courses = this.coursesState;
      const vue = this;
      if (this.filterBath !== null) {
        const lanes = [];
        this.bathLanes.forEach(lane => {
          if (lane.bath === vue.filterBath) lanes.push(lane.id);
        });

        if (lanes !== [])
          courses = courses.filter(course => lanes.includes(course.bathLane));
      }

      courses = courses.filter(course => course.isArchived === false);

      return courses;
    },
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAllWithDeleted"];
    },
    courseDefinitionCategories: function() {
      return this.$store.state.courseDefinitionCategories.all;
    },
    bathLanes: function() {
      return this.$store.getters["bathLanes/getAll"];
    },
    baths: function() {
      return this.$store.getters["baths/getAll"];
    },
    events: function() {
      return this.$store.state.events.all;
    },
    instructors: function() {
      return this.$store.state.instructors.all;
    },
    participants: function() {
      return this.$store.state.participants.all;
    },
    customers: function() {
      return this.$store.state.customers.all;
    },
    bookings: function() {
      return this.$store.state.bookings.all;
    },
    waitingList: function() {
      return this.$store.state.waitingList.all;
    },
    searchTerm: {
      set: function(value) {
        this.search.term = value;
        this.$store.dispatch("search/set", {
          type: "course",
          search: this.search
        });
      },
      get: function() {
        return this.$store.state.search.course.term;
      }
    }
  },
  methods: {
    getCategory(id) {
      const category = this.courseDefinitionCategories.find(
        item => item.id === id
      );
      return category ?? { title: "Keine Kategorie vorhanden" };
    },
    getBath(bathLaneId) {
      const bathLane = this.getBathLane(bathLaneId);
      return this.$store.getters["baths/getById"](bathLane.bath);
    },
    getBathLane(bathLaneId) {
      return this.$store.getters["bathLanes/getById"](bathLaneId);
    },
    getEvents(courseId) {
      return this.$store.getters["events/getByCourseId"](courseId);
    },
    getInstructor(id) {
      const instructor = this.$store.getters["instructors/getById"](id);
      if (instructor !== undefined) return instructor;
      return {};
    },
    formatDate(dateTime) {
      return moment(new Date(dateTime)).format("DD.MM.YYYY HH:mm");
    },
    getMailString(item) {
      const vue = this;
      const bookings = this.bookings.filter(({ course }) => course === item.id);
      const events = this.events.filter(
        ({ course, startDate }) =>
          course === item.id && new Date(startDate) > new Date()
      );

      let mailto = `?subject=Kurs #${item.id} - ${item.title} - ${
        item.startDate
      }${item.endDate ? " - " + item.endDate : ""}`;

      if (events.length > 0) {
        const dates = events.map(({ startDate }) => this.formatDate(startDate));
        const formattedBody = `Kommende Termine:\n${dates.join("\n")}\n\nBad: ${
          item.bathTitle
        }`;
        mailto += `&body=${encodeURIComponent(formattedBody)}`;
      }

      if (!bookings) return mailto;

      const emails = bookings.reduce((result, booking) => {
        if (
          item.type === "club" &&
          (booking.clubEndDate !== null || booking.event !== null) &&
          new Date(booking.clubEndDate) < new Date()
        ) {
          return result;
        }

        const participant = vue.$store.getters["participants/getParticipant"](
          booking.participant
        );

        if (participant) {
          const email = vue.$store.getters["customers/getCustomer"](
            participant.customer
          )?.email;

          if (email) {
            result.push(email);
          }
        }
        return result;
      }, []);

      item.instructor.map(instructorId => {
        const instructor = this.getInstructor(instructorId);
        emails.push(instructor.email);
      });

      mailto += emails.length > 0 ? "&bcc=" + emails.join(",") : "";

      location.href = "mailto:" + mailto;
    },
    initCourseList: function() {
      this.courses = this.$store.state.courses.all.reduce((result, value) => {
        const instructorNames = [];
        value.instructors = [];
        value.instructors = value.instructor.map(id => {
          const { firstname, lastname, shortcut } = this.getInstructor(id);
          const name = `${firstname} ${lastname}`;
          instructorNames.push(name);
          return { name: name, shortcut };
        });
        value.instructorNamesSearchable = instructorNames.join(" ");
        const bathLane = this.getBathLane(value.bathLane);
        const bath = this.getBath(value.bathLane);
        value.bathTitle = bath.title;
        value.bathShortcut = bath.shortcut;
        value.bathLaneTitle = bathLane.title;
        if (!value.isArchived) result.push(value);

        value.onWaitingList = this.waitingList.filter(
          item => item.recommendationCourse === value.id
        ).length;
        return result;
      }, []);
    },
    exportCourseData() {
      this.$store
        .dispatch("course/export", { filter: this.exportFilter })
        .then(response => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("href", url);
          a.setAttribute("download", "Buchungen.csv");
          a.click();
        });
    }
  },
  created() {
    this.initCourseList();
    this.search = Object.assign({}, this.$store.state.search.course);
    if (this.$route.params.filterSearch !== undefined)
      this.search.term = this.$route.params.filterSearch + "";
  },
  watch: {
    coursesState() {
      this.initCourseList();
    }
  }
};
</script>

<style lang="scss" scoped>
.eventList {
  list-style-type: none;
  margin: 0;
  padding-left: 0;

  li {
    padding-left: 0;
  }
}
</style>
