import ApiService from "../../common/api.service";

const state = {
  all: []
};

// getters
const getters = {
  getById: state => id => {
    return state.all.find(item => item.id === id);
  }
};

// actions
const actions = {
  add(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/course/definition/category", item)
        .then(response => {
          context.commit("ADD", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.put("/course/definition/category/" + item.id, item)
        .then(response => {
          context.commit("ADD", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAll(context) {
    ApiService.get("/course/definition/category").then(response => {
      context.commit("SET", response.data);
    });
  }
};

// mutations
const mutations = {
  ADD(state, definition) {
    state.all.push(definition);
  },
  SET(state, definitions) {
    state.all = definitions;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
