<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <v-card-title>
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>fal fa-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-select
            clearable
            label="Schwimmbad"
            :items="baths"
            item-text="title"
            item-value="id"
            v-model="filterBath"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>fal fa-chevron-right</v-icon>
          </v-btn>
        </v-card-title>
        <v-calendar
          ref="calendar"
          type="month"
          first-interval="6"
          interval-count="18"
          event-overlap-mode="stack"
          :weekdays="weekdays"
          :events="filteredEvents"
          v-model="calendar"
        ></v-calendar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PlanMonth",
  data() {
    return {
      participantsOnly: false,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      calendar: "",
      currentEvent: null,
      events: [],
      filter: {
        bath: null
      }
    };
  },
  computed: {
    filterBath: {
      set: function(value) {
        this.filter.bath = value;
        this.$store.dispatch("search/set", {
          type: "planMonth",
          search: this.filter
        });
      },
      get: function() {
        return this.filter.bath;
      }
    },
    filteredEvents: function() {
      let events = this.events;
      if (this.filterBath !== null) {
        events = events.filter(event => event.bath === this.filterBath);
      }

      return events;
    },
    bathLanes: function() {
      return this.$store.getters["bathLanes/getAll"];
    },
    baths: function() {
      return this.$store.getters["baths/getAll"];
    }
  },
  methods: {
    getCourseTitle: function(courseId) {
      return this.$store.getters["courses/getById"](courseId)?.title;
    },
    getBathIdByLaneId: function(laneId) {
      return this.$store.getters["bathLanes/getById"](laneId).bath;
    },
    initEvents: function() {
      let events = [];
      this.$store.state.events.all.forEach(event => {
        events.push({
          id: event.id,
          course: event.course,
          name: "[" + event.course + "] " + this.getCourseTitle(event.course),
          start: Date.parse(event.startDate),
          end: Date.parse(event.endDate),
          timed: true,
          bath: this.getBathIdByLaneId(event.bathLane)
        });
      });

      const clubs = this.$store.state.courses.all.filter(
        course => course.type === "club"
      );
      clubs.forEach(club => {
        const clubEndDate =
          club.endDate === null ? null : new Date(club.endDate);
        if (club.isArchived === false && club.times.length > 0) {
          const clubEvents = this.$store.state.events.all.filter(
            event => event.course === club.id
          );
          const lastEvent = clubEvents.pop();
          for (let i = 1; i < 24; i++) {
            let startDate = new Date(lastEvent.startDate);
            startDate.setDate(startDate.getDate() + i * 7);
            let endDate = new Date(lastEvent.endDate);
            endDate.setDate(endDate.getDate() + i * 7);
            if (clubEndDate === null || clubEndDate < startDate) {
              events.push({
                course: club.id,
                name: "[" + club.id + "] " + this.getCourseTitle(club.id),
                start: Date.parse(startDate),
                end: Date.parse(endDate),
                timed: true,
                bath: this.getBathIdByLaneId(club.bathLane)
              });
            }
          }
        }
      });

      this.events = events;
    }
  },
  created() {
    this.filter = Object.assign({}, this.$store.state.search.planMonth);
    this.initEvents();
  }
};
</script>

<style scoped></style>
