import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Bookings from "../views/Bookings";
import BookingsSingle from "../views/BookingsSingle";
import BookingEdit from "../views/BookingEdit";
import Customers from "../views/Customers";
import Participants from "../views/Participants";
import CourseDefinitions from "@/views/CourseDefinitions";
import Courses from "@/views/Courses";
import CourseGenerator from "@/views/CourseGenerator";
import Payments from "@/views/Payments";
import PaymentsAssign from "@/views/PaymentsAssign";
import Coupons from "@/views/Coupons";
import Baths from "@/views/Baths";
import OpenInvoices from "@/views/OpenInvoices";
import Events from "@/views/Events";
import Instructors from "@/views/Instructors";
import InstructorCalendar from "@/views/InstructorCalendar";
import Location from "@/views/Location";
import WaitingList from "@/views/WaitingList";
import PlanMonth from "@/views/PlanMonth";
import ErrorList from "@/views/ErrorList";
import BookingCalendar from "@/views/BookingCalendar";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Übersicht"
    }
  },
  {
    path: "/bookingCalendar",
    name: "bookingCalendar",
    component: BookingCalendar,
    props: true,
    meta: {
      title: "Kalender"
    }
  },
  {
    path: "/bookings",
    name: "bookings",
    component: Bookings,
    props: true,
    meta: {
      title: "Buchungen"
    }
  },
  {
    path: "/bookingsSingle",
    name: "bookingsSingle",
    component: BookingsSingle,
    props: true,
    meta: {
      title: "Einzelbuchungen"
    }
  },
  {
    path: "/bookings/:id/:course?:participant?",
    name: "bookingEdit",
    component: BookingEdit,
    props: true,
    meta: {
      title: "Buchung bearbeiten"
    }
  },
  {
    path: "/bookingsSingle/:id/:course?:participant?",
    name: "bookingSingleEdit",
    component: BookingEdit,
    props: true,
    meta: {
      title: "Einzelbuchung bearbeiten"
    }
  },
  {
    path: "/waitingList",
    name: "waitingList",
    component: WaitingList,
    props: true,
    meta: {
      title: "Warteliste"
    }
  },
  {
    path: "/customers/:searchCustomer?",
    name: "customers",
    props: true,
    component: Customers,
    meta: {
      title: "Kunden"
    }
  },
  {
    path: "/participants/:searchParticipant?",
    name: "participants",
    props: true,
    component: Participants,
    meta: {
      title: "Teilnehmer"
    }
  },
  {
    path: "/course",
    name: "courses",
    component: Courses,
    meta: {
      title: "Kurse"
    }
  },
  {
    path: "/course/:id",
    name: "courseGenerator",
    component: CourseGenerator,
    props: true,
    meta: {
      title: "Kurs anlegen/bearbeiten"
    }
  },
  {
    path: "/plan/month",
    name: "planMonth",
    component: PlanMonth,
    meta: {
      title: "Monatsplan"
    }
  },
  {
    path: "/courseDefintions",
    name: "courseDefinitions",
    component: CourseDefinitions,
    meta: {
      title: "Kursvorlagen"
    }
  },
  {
    path: "/payments",
    name: "payments",
    component: Payments,
    meta: {
      title: "Zahlungen"
    }
  },
  {
    path: "/payments/assign/:id",
    name: "payments-assign",
    component: PaymentsAssign,
    props: true,
    meta: {
      title: "Zahlungen zuweisen"
    }
  },
  {
    path: "/coupons",
    name: "coupons",
    component: Coupons,
    meta: {
      title: "Gutscheine"
    }
  },
  {
    path: "/baths",
    name: "baths",
    component: Baths,
    meta: {
      title: "Schwimmbäder"
    }
  },
  {
    path: "/open-invoices",
    name: "openInvoices",
    component: OpenInvoices,
    meta: {
      title: "Offene Buchungen"
    }
  },
  {
    path: "/events/:eventId?/:parentType?",
    name: "events",
    component: Events,
    props: true,
    meta: {
      title: "Termine"
    }
  },
  {
    path: "/instructors",
    name: "instructors",
    component: Instructors,
    meta: {
      title: "Benutzer"
    }
  },
  {
    path: "/instructorCalendar",
    name: "instructorCalendar",
    component: InstructorCalendar,
    meta: {
      title: "Trainerkalender"
    }
  },
  {
    path: "/location",
    name: "location",
    component: Location,
    meta: {
      title: "Standorteinstellungen"
    }
  },
  {
    path: "/changelog",
    name: "changelog",
    component: Location,
    meta: {
      title: "Changelog"
    }
  },
  {
    path: "/error-list",
    name: "errorList",
    component: ErrorList,
    meta: {
      title: "Fehlerliste"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
