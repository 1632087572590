<template>
  <Grid>
    <v-col v-for="item in items" :key="item.id" sm="6" md="4" lg="3" cols="12">
      <UserCard :item="item" />
    </v-col>
  </Grid>
</template>

<script>
import UserCard from "@/components/utils/UserCard";
import Grid from "@/components/utils/Grid";
export default {
  name: "InstructorTable",
  components: {
    UserCard,
    Grid
  },
  computed: {
    items: function() {
      return this.$store.state.instructors.all.filter(
        item => item.firstname && item.lastname
      );
    }
  },
  created() {
    this.$store.dispatch("instructors/getAll");
  }
};
</script>

<style scoped></style>
