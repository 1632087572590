<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <CouponsTable
        @item-edit="onCouponEdit"
        @item-delete="onCouponDelete"
        @new-coupon="onCouponNew"
        @new-import="dialogImport = true"
      />
      <v-dialog v-model="dialogNewItem" max-width="800">
        <v-form ref="coupon" @submit.prevent="saveItem()">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Gutschein anlegen</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogNewItem = false"
                ><v-icon>fal fa-times</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Kunde"
                      v-model="coupon.customer"
                      :items="customers"
                      :item-text="item => item.firstname + ' ' + item.lastname"
                      item-value="id"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    ><v-text-field
                      label="Wert"
                      v-model="coupon.value"
                      :rules="[
                        rules.required,
                        rules.currency,
                        rules.greaterNull
                      ]"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-textarea
                      label="Information"
                      v-model="coupon.comment"
                    ></v-textarea
                  ></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text type="submit">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog max-width="1000" v-model="dialogImport">
        <v-card flat>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>CSV Import</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogImport = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-file-input
              accept="text/csv"
              show-size
              label="CSV-Datei auswählen"
              @change="parseFile"
            ></v-file-input>
            <v-row>
              <v-col>Zeilen zugeordnet: {{ linesOk.length }}</v-col>
              <v-col>Zeilen Fehler: {{ linesError.length }}</v-col>
              <v-col>Zeilen 0 €: {{ linesZero.length }}</v-col>
            </v-row>
            <h2 class="mt-4">Kunden können nicht zugeordnet werden</h2>
            <v-data-table :items="linesError" :headers="csvHeader">
              <template v-slot:item.date="{ item }">
                {{ item.date | formatDate }}
              </template>
              <template v-slot:item.value="{ item }">
                {{ item.value | formatCurrency }}
              </template>
              <template v-slot:item.action="{ item }"> </template>
            </v-data-table>
            <h2 class="mt-4">Gutscheine zum importieren</h2>
            <v-data-table :items="csvImport" :headers="csvHeader">
              <template v-slot:item.date="{ item }">
                {{ item.date | formatDate }}
              </template>
              <template v-slot:item.value="{ item }">
                {{ item.value | formatCurrency }}
              </template>
              <template v-slot:item.customer="{ item }">
                {{ displayCustomerName(item.customer) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon v-on:click="deleteImportItem(item)"
                  ><v-icon>fal fa-trash</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="importData">Importieren</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CouponsTable from "@/components/coupon/CouponsTable";
import Papa from "papaparse";

export default {
  name: "Coupons",
  components: {
    CouponsTable
  },
  computed: {
    customers: function() {
      return this.$store.getters["customers/getAll"];
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.coupon.validate()) {
        if (this.coupon.id === undefined) {
          this.$store.dispatch("coupons/create", this.coupon).then(() => {
            this.dialogNewItem = false;
            this.coupon = {};
            this.$refs.coupon.reset();
          });
          return;
        }

        if (this.coupon.id > 0) {
          this.$store.dispatch("coupons/update", this.coupon).then(() => {
            this.dialogNewItem = false;
            this.coupon = {};
            this.$refs.coupon.reset();
          });
          return;
        }
      }
    },
    onCouponNew() {
      this.coupon = Object.assign({ date: new Date() });
      this.dialogNewItem = true;
    },
    onCouponEdit(couponId) {
      this.coupon = Object.assign(
        {},
        this.$store.getters["coupons/getById"](couponId)
      );
      this.dialogNewItem = true;
    },
    onCouponDelete(coupon) {
      if (
        coupon.balance !== coupon.value &&
        confirm(
          "Soll der Gutschein #" + coupon.id + " wirklich gelöscht werden?"
        )
      ) {
        this.$store.dispatch("coupons/delete", coupon);
      } else {
        if (coupon.balance < 0) return;
        this.$store
          .dispatch("coupons/payOff", {
            coupon: coupon.id,
            value: coupon.balance
          })
          .then(() => {
            this.$store.dispatch("messages/add", {
              message: "Gutschein wurde ausbezahlt",
              color: "success"
            });
          });
      }
    },
    importData: function() {
      const vue = this;
      vue.$store
        .dispatch("coupons/import", this.csvImport)
        .then(() => {
          vue.$store.dispatch("messages/add", {
            message: "Coupons wurden importiert",
            color: "success"
          });
        })
        .catch(() => {
          vue.$store.dispatch("messages/add", {
            message: "Coupons konnten nicht importiert werden",
            color: "error"
          });
        });
    },
    parseFile: function(file) {
      const vue = this;
      const reader = new FileReader();
      reader.onload = function(e) {
        vue.csvFile = e.target.result;
        vue.parseData();
      };
      reader.readAsText(file);
    },
    parseData: function() {
      const vue = this;
      Papa.parse(this.csvFile, {
        config: {
          delimiter: ";"
        },
        complete: function(results) {
          let data = [];
          results.data.forEach(item => {
            const customer = vue.customers.find(
              customer => customer.email === item[1]
            );

            let date = new Date();
            if (item[0] !== "") {
              date = item[0];
            }

            let value = 0;
            if (item[2] !== undefined) {
              value = parseFloat(item[2].replace(",", "."));
            }

            if (customer !== undefined) {
              if (value > 0) {
                data.push({
                  date: date,
                  customer: customer.id,
                  value: value
                });
                vue.linesOk.push({});
              }
              if (value === 0) {
                vue.linesZero.push({});
              }
            }

            if (customer === undefined) {
              vue.linesError.push({
                date: date,
                customer: item[1],
                value: value
              });
            }
          });
          vue.csvImport = data;
        }
      });
    },
    displayCustomerName: function(id) {
      const customer = this.$store.getters["customers/getCustomer"](id);
      if (customer !== undefined) {
        return customer.firstname + " " + customer.lastname;
      }
      return "";
    }
  },
  data() {
    return {
      linesOk: [],
      linesError: [],
      linesZero: [],
      dialogImport: false,
      dialogNewItem: false,
      coupon: {
        date: new Date(),
        value: 0,
        customer: null,
        comment: ""
      },
      csvHeader: [
        { text: "Datum", value: "date" },
        { text: "Kunde", value: "customer" },
        { text: "Betrag", value: "value" },
        { text: "Aktion", value: "action" }
      ],
      csvImport: [],
      csvFile: null,
      dataParsed: null,
      rules: {
        required: v => !!v || "Pflichtfeld",
        currency: v => /^\d+,?\d+$/.test(v) || "Wert ist nicht valide",
        greaterNull: v =>
          (v && parseFloat(v.replace(/,/g, ".")) > 0) ||
          "Wert muss 0 € übersteigen"
      }
    };
  },
  created() {
    this.$store.dispatch("coupons/loadAll");
  },
  mounted() {
    this.$store.dispatch("coupons/loadAll");
  }
};
</script>
