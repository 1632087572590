<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <BookingsSingleTable />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BookingsSingleTable from "@/components/booking/BookingsSingleTable";

export default {
  name: "BookingsSingle",
  components: {
    BookingsSingleTable
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {}
};
</script>
