<template>
  <v-card flat class="pb-4">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Termine bearbeiten</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"><v-icon>fal fa-times</v-icon></v-btn>
    </v-toolbar>
    <v-card>
      <v-card-title
        >Ausgewählte Termine:
        <v-chip
          class="mx-2"
          v-for="id in events.map(({ id }) => +id).sort((a, b) => a - b)"
          :key="id"
          outlined
          label
          >#{{ id }}</v-chip
        >
      </v-card-title>
    </v-card>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-alert type="warning"
            >Bitte nur Daten auswählen, welche überschrieben werden
            sollen.</v-alert
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="flex">
          <v-col>
            <v-card-title class="pl-0">Bad und Bahn</v-card-title>
            <v-autocomplete
              :items="bathLanes"
              v-model="bathLane"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-card-title class="pl-0">Trainer:</v-card-title>
            <InstructorCheckboxes
              :rules="[true]"
              :instructor-ids.sync="trainer"
            />
          </v-col>
        </v-col>
        <v-col>
          <v-col>
            <v-card-title class="pl-0">Uhrzeit:</v-card-title>
            <v-time-picker
              v-model="time"
              format="24hr"
              full-width
            ></v-time-picker>
          </v-col>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions class="justify-center mt-4">
      <v-btn @click.prevent="saveData" color="primary">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import InstructorCheckboxes from "@/components/instructor/InstructorCheckboxes";
export default {
  components: { InstructorCheckboxes },
  props: ["selectedEvents", "bathLanes"],
  data: function() {
    return {
      time: null,
      trainer: [],
      bathLane: null
    };
  },
  computed: {
    events: function() {
      return this.$store.state.events.all.filter(({ id }) =>
        this.selectedEvents.includes(id.toString())
      );
    }
  },
  methods: {
    async saveData() {
      if (!this.time && !this.bathLane && this.trainer.length < 1) {
        return;
      }

      await Promise.all(
        this.events.map(async data => {
          const event = { ...data };
          const currentDate = new Date(event.startDate);

          if (this.time) {
            const [hours, minutes] = this.time.split(":");
            currentDate.setHours(+hours);
            currentDate.setMinutes(+minutes);
          }
          event.startDate = this.$options.filters.formatDateEn(currentDate);
          event.startTime = this.$options.filters.formatTime(currentDate);

          if (this.bathLane) {
            event.bathLane = this.bathLane;
          }

          if (this.trainer.length > 0) {
            event.instructor = this.trainer;
          }
          try {
            await this.$store.dispatch(`events/update`, event);
          } catch (error) {
            this.$store.dispatch("messages/add", {
              message: "Es ist ein Fehler aufgetreten",
              color: "error"
            });
          }
        })
      );
      this.time = null;
      this.trainer = [];
      this.bathLane = null;
      this.$store.dispatch("messages/add", {
        message: "Termine wurden gespeichert",
        color: "success"
      });
      this.$emit("saved");
    }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: column;
}
</style>
