<template>
  <div>
    <Table :items="waitinglistTable" :headers="headers" :itemsPerPage="20">
      <template v-slot:item.participantDateOfBirth="{ item }">
        {{ item.participantDateOfBirth | formatDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon v-on:click="$emit('item-delete', item)"
          ><v-icon>fal fa-trash</v-icon></v-btn
        >
      </template>
    </Table>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import Table from "@/components/utils/Table";
export default {
  name: "WaitinglistTable",
  components: { Table },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Kunde", value: "customerName" },
        { text: "Teilnehmer", value: "participantName" },
        { text: "Geburtsdatum", value: "participantDateOfBirth" },
        { text: "Bad", value: "bathName" },
        { text: "Kategorie", value: "courseDefinitionCategoryName" },
        { text: "Aktion", value: "action" }
      ],
      waitinglistTable: []
    };
  },
  computed: {
    participants: function() {
      return this.$store.state.participants.all;
    },
    customers: function() {
      return this.$store.state.customers.all;
    },
    waitinglist() {
      return this.$store.state.waitingList.all;
    }
  },
  methods: {
    formatDate(dateTime) {
      return moment(new Date(dateTime)).format("DD.MM.YYYY");
    },
    getCustomerById(id) {
      return this.$store.getters["customers/getCustomer"](id);
    },
    getParticipantById(id) {
      return this.$store.getters["participants/getParticipant"](id);
    },
    getBathById(id) {
      return this.$store.getters["baths/getById"](id);
    },
    getCourseDefinitionCategoryById(id) {
      return this.$store.getters["courseDefinitionCategories/getById"](id);
    },
    initiate: function() {
      this.waitinglistTable = this.waitinglist.reduce((result, value) => {
        if (value.recommendationCourse === null) {
          let participant = this.getParticipantById(value.participant);
          if (participant === undefined) {
            participant = {
              firstname: "",
              lastname: "",
              customer: null,
              dateOfBirth: ""
            };
          }

          let customer = this.getCustomerById(participant.customer);
          if (customer === undefined) {
            customer = {
              firstname: "",
              lastname: ""
            };
          }

          let bathName = [];
          value.bath.forEach(item => {
            bathName.push(this.getBathById(item)?.title);
          });
          value.bathName = bathName.join(", ");
          let courseDefinitionCategoryName = [];
          value.courseDefinitionCategory.forEach(item => {
            courseDefinitionCategoryName.push(
              this.getCourseDefinitionCategoryById(item).title
            );
          });
          value.courseDefinitionCategoryName = courseDefinitionCategoryName.join(
            ", "
          );
          value.customerName = customer.firstname + " " + customer.lastname;
          value.participantName =
            participant.firstname + " " + participant.lastname;
          value.participantDateOfBirth = participant.dateOfBirth;
          result.push(value);
        }
        return result;
      }, []);
    }
  },
  created() {
    this.initiate();
  },
  watch: {
    waitinglist() {
      this.initiate();
    }
  }
};
</script>
