<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <BookingsTable :show-open="true"></BookingsTable>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BookingsTable from "@/components/booking/BookingsTable";
export default {
  name: "OpenInvoices",
  components: { BookingsTable }
};
</script>

<style scoped></style>
