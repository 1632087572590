import ApiService from "../../common/api.service";

const getters = {
  getByBookingId(id) {
    return ApiService.get("/log/booking/" + id).then(response => response.data);
  }
};

const actions = {
  getByBookingId(context, id) {
    return new Promise(resolve => {
      ApiService.get("/log/booking/" + id).then(response =>
        resolve(response.data)
      );
    });
  }
};

export default {
  namespaced: true,
  actions,
  getters
};
