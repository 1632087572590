<template>
  <v-card flat v-if="coupons.length > 0">
    <v-card-title
      >Gutscheine von #{{ this.customer.id }} {{ customer.firstname }}
      {{ customer.lastname }}</v-card-title
    >
    <v-card-text>
      <v-list>
        <v-list-item v-for="coupon in coupons" :key="coupon.id">
          <v-list-item-title
            >{{ coupon.token }} - Wert:
            {{ coupon.balance | formatCurrency }} von
            {{ coupon.value | formatCurrency }}</v-list-item-title
          >
          <v-list-item-content>{{ coupon.information }}</v-list-item-content>
          <v-list-item-action
            ><v-btn @click="$emit('pay-by-coupon', coupon)"
              >Bezahlen</v-btn
            ></v-list-item-action
          >
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CouponOverview",
  props: ["customerId"],
  computed: {
    customer: function() {
      return this.$store.getters["customers/getCustomer"](this.customerId);
    },
    coupons: function() {
      return this.$store.getters["coupons/getByCustomerId"](
        this.customerId
      ).filter(coupon => coupon.balance > 0);
    }
  },
  methods: {}
};
</script>

<style scoped></style>
