<template>
  <div>
    <v-dialog max-width="600" v-model="showModal">
      <v-container class="grey lighten-5">
        <v-row no-gutters>
          <v-col @click="editEvent(eventId)" class="pa-2 pointer col-12">
            <v-card class="pa-2 text-center" outlined tile>
              <v-icon class="mr-2">fal fa-calendar-day</v-icon>
              Event anpassen
            </v-card>
          </v-col>
          <v-col @click="editUserList(eventId)" class="pa-2 pointer col-12">
            <v-card class="pa-2 text-center" outlined tile>
              <v-icon class="mr-2">fal fa-users</v-icon>
              Teilnehmer bearbeiten
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog max-width="1000" v-model="showParticipantsModal">
      <EventParticipantsTable
        v-if="showParticipantsModal"
        @close="closeParicipantsDialog"
        :parentEvent="event"
      />
    </v-dialog>
  </div>
</template>

<script>
import EventParticipantsTable from "@/components/event/EventParticipantsTable";
export default {
  name: "InstructorChoiceModal",
  props: ["eventId", "showChoiceModal", "participantsOnly"],
  components: {
    EventParticipantsTable,
  },
  data() {
    return {
      event: null,
      showModal: false,
      showParticipantsModal: false,
    };
  },
  watch: {
    showChoiceModal(value) {
      this.showModal = value;
    },
    showModal(value) {
      if (!value) {
        this.$emit("closeModal");
      }else{
        if(this.participantsOnly){
          this.editUserList(this.eventId)
        }
      }
    },
  },
  methods: {
    editEvent: function(id) {
      this.$router.push({
        name: "events",
        params: { eventId: id, parentType: "edit" },
      });
    },
    editUserList: function(id) {
      this.event = this.$store.state.events.all.find((item) => item.id === id);
      this.showModal = false;
      this.showParticipantsModal = true;
    },
    closeParicipantsDialog: function() {
      this.showParticipantsModal = false;
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
