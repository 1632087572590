<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Export</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"
          ><v-icon>fal fa-times</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <DatePicker
                :date.sync="filter.startDate"
                label="Startdatum"
              ></DatePicker>
            </v-col>
            <v-col>
              <DatePicker
                :date.sync="filter.endDate"
                label="Enddatum"
              ></DatePicker>
            </v-col>
          </v-row>
          <v-row v-if="showCourseDefinition">
            <v-col>
              <v-select
                v-model="filter.template"
                label="Club-/Kursvorlagen"
                clearable
                :items="courseDefinitions"
                item-value="id"
                item-text="title"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="showBath">
            <v-col>
              <v-select
                v-model="filter.bath"
                label="Bad"
                clearable
                :items="baths"
                item-value="id"
                item-text="title"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false">Schließen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="exportData()">Exportieren</v-btn>
      </v-card-actions>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :class="cssClass"
        ><v-icon>fal fa-download</v-icon>Export</v-btn
      >
    </template>
  </v-dialog>
</template>

<script>
import DatePicker from "@/components/picker/DatePicker";

export default {
  name: "ExportDialog",
  components: {
    DatePicker
  },
  props: ["type", "cssClass", "showBath", "showCourseDefinition"],
  data() {
    return {
      dialog: false,
      filter: {
        startDate: null,
        endDate: null,
        template: null,
        bath: null
      },
      exportNames: {
        course: "Kurse",
        booking: "Buchungen",
        openBooking: "Offene Buchungen"
      }
    };
  },
  computed: {
    baths: function() {
      return this.$store.getters["baths/getAll"];
    },
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAllWithDeleted"];
    },
    settings: function() {
      return this.$store.getters["settings/get"];
    }
  },
  methods: {
    exportData() {
      const payload = {
        filter: this.filter,
        type: this.type
      };
      const name =
        "Datenexport " +
        this.settings.company +
        " - " +
        this.exportNames[this.type] +
        ".csv";
      this.$store.dispatch("exporter/export", payload).then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", name);
        a.click();
      });
    }
  }
};
</script>
