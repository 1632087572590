<template>
  <div v-if="bookings.length > 1">
    <v-list two-line>
      <v-list-item v-for="booking in bookings" :key="booking.id">
        <v-list-item-avatar width="80">
          {{ booking.date | formatDate }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            >#{{ getCourse(booking.course).id }}
            {{ getCourse(booking.course).title }}</v-list-item-title
          >
          <v-list-item-subtitle>{{
            booking.price | formatCurrency
          }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <router-link
            :to="{ name: 'bookingEdit', params: { id: booking.id } }"
          >
            <v-icon>
              fal fa-shopping-cart
            </v-icon>
          </router-link>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="bookingCount > 3">
        <v-list-item-content @click="limitBookings = !limitBookings">
          <v-btn text v-if="limitBookings">Weitere anzeigen</v-btn>
          <v-btn text v-else>Weniger anzeigen</v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
  <v-card-text v-else>Keine weiteren Buchungen</v-card-text>
</template>

<script>
export default {
  name: "BookingListParticipant",
  props: ["participant"],
  data() {
    return {
      limitBookings: true
    };
  },
  computed: {
    bookingCount() {
      return this.$store.state.bookings.all.filter(
        item => item.participant === this.participant
      ).length;
    },
    bookings() {
      const bookings = this.$store.state.bookings.all.filter(
        item => item.participant === this.participant
      );
      return this.limitBookings ? bookings.slice(0, 3) : bookings;
    }
  },
  methods: {
    getCourse(courseId) {
      return this.$store.state.courses.all.find(item => item.id === courseId);
    }
  }
};
</script>

<style scoped></style>
