<template>
  <div>
    <Table :items="baths" :headers="headers" :itemsPerPage="20">
      <template v-slot:button>
        <v-btn @click="$emit('new-bath')">
          <v-icon left>fal fa-plus</v-icon>
          Neues Schwimmbad
        </v-btn>
      </template>
      <template v-slot:item.lanes="{ item }">
        <v-chip
          outlined
          label
          class="mr-2"
          v-for="lane in item.lanes"
          :key="lane"
          @click="editLane(lane, item.id)"
          >{{ getBathLaneById(lane).title }}</v-chip
        >
        <v-chip outlined label class="mr-2" @click="editLane(0, item.id)"
          ><v-icon>fal fa-plus</v-icon></v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon @click="$emit('item-edit', item)"
          ><v-icon>fal fa-edit</v-icon></v-btn
        >
        <v-btn icon :href="getMailString(item)"
          ><v-icon>fal fa-envelope</v-icon></v-btn
        >
        <v-btn icon @click="deleteBath(item)"
          ><v-icon>fal fa-trash</v-icon></v-btn
        >
      </template>
      <template v-slot:item.showOnHomepage="{ item }">
        <v-btn
          fab
          x-small
          :color="item.showOnHomepage ? 'green' : 'red'"
        ></v-btn>
      </template>
    </Table>
    <v-dialog v-model="dialogEdit" max-width="800">
      <v-card>
        <v-card-title v-if="lane.id !== undefined"
          >Bahn {{ lane.title }} bearbeiten</v-card-title
        >
        <v-card-title v-else>Bahn erstellen</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="lane.title" label="Name"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="lane.participantsMax"
                  label="Maximale Teilnehmer"
                  type="number"
                  min="1"
                  step="1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="dialogEdit = false">Abbrechen</v-btn>
          <v-btn color="success" @click="saveLane()">Speichern</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="deleteLane()">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Table from "@/components/utils/Table";
export default {
  name: "BathTable",
  components: { Table },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Öffentlich", value: "showOnHomepage" },
        { text: "Kürzel", value: "shortcut" },
        { text: "Name", value: "title" },
        { text: "Bahnen", value: "lanes" },
        { text: "Straße", value: "street" },
        { text: "Ort", value: "city" },
        { text: "Aktion", value: "action" }
      ],
      lane: {},
      dialogEdit: false
    };
  },
  computed: {
    baths() {
      return this.$store.getters["baths/getAll"];
    },
    bathLanes() {
      return this.$store.getters["bathLanes/getAll"];
    }
  },
  methods: {
    editLane: function(id, bath) {
      if (id > 0) {
        this.lane = Object.assign({}, this.getBathLaneById(id));
      } else {
        this.lane = {
          bath: bath,
          title: "",
          participantsMax: 0
        };
      }

      this.dialogEdit = true;
    },
    saveLane: function() {
      const vue = this;
      if (this.lane.participantsMax < 0) {
        this.lane.participantsMax = 0;
      }

      if (this.lane.id === undefined) {
        this.$store.dispatch("bathLanes/add", this.lane).then(function() {
          vue.dialogEdit = false;
          vue.$store.dispatch("bathLanes/getAll");
          vue.$store.dispatch("baths/getAll");
        });
      } else {
        this.$store.dispatch("bathLanes/update", this.lane).then(function() {
          vue.dialogEdit = false;
          vue.$store.dispatch("bathLanes/getAll");
        });
      }
    },
    deleteLane: function() {
      const vue = this;
      this.$store.dispatch("bathLanes/delete", this.lane).then(function() {
        vue.dialogEdit = false;
        vue.$store.dispatch("baths/getAll");
        vue.$store.dispatch("bathLanes/getAll");
      });
    },
    getBathLaneById: function(id) {
      return this.bathLanes.find(item => item.id === id);
    },
    deleteBath: function(bath) {
      if (
        confirm("Soll das Bath " + bath.title + " wirklich gelöscht werden?")
      ) {
        this.$store.dispatch("baths/delete", { id: bath.id });
      }
    },
    getMailString(item) {
      const today = new Date();
      const twoWeeksFromNow = new Date(+new Date() + 12096e5);
      const events = this.$store.state.events.all.reduce(
        (result, { bathLane, startDate, course }) => {
          startDate = new Date(startDate);
          if (
            item.lanes.includes(bathLane) &&
            startDate > today &&
            startDate < twoWeeksFromNow
          )
            result.push(course);
          return result;
        },
        []
      );
      const subject = item.title ? `?subject=${item.title}` : "";

      if (events && events.length) {
        const emails = this.$store.state.bookings.all.reduce(
          (result, { participant, course }) => {
            if (events.includes(course)) {
              const { customer } = this.$store.getters[
                "participants/getParticipant"
              ](participant);
              if (customer) {
                const email = this.$store.getters["customers/getCustomer"](
                  customer
                )?.["email"];

                if (email) result.push(email);
              }
            }
            return result;
          },
          []
        );

        const bbc = emails.length > 0 ? `&bcc=${emails.join(",")}` : "";

        return `mailto:${subject}${bbc}`;
      } else {
        return `mailto:${subject}`;
      }
    }
  }
};
</script>

<style scoped></style>
