<template>
  <Table :items="coupons" :headers="headers" :itemsPerPage="20">
    <template v-slot:actions>
      <v-checkbox
        @change="initiate()"
        v-model="showCouponsWithBalance"
        label="Zeige Gutscheine ohne Wert an"
        :value="!showCouponsWithBalance"
      ></v-checkbox>
    </template>
    <template v-slot:button>
      <v-btn class="mr-2" @click="$emit('new-import')"
        ><v-icon left>fal fa-upload</v-icon>CSV Import</v-btn
      >
      <v-btn @click="$emit('new-coupon')"
        ><v-icon left>fal fa-plus</v-icon> Neuer Gutschein</v-btn
      >
    </template>
    <template v-slot:item.date="{ item }">
      {{ item.date | formatDate }}
    </template>
    <template v-slot:item.value="{ item }">
      {{ item.value | formatCurrency }}
    </template>
    <template v-slot:item.balance="{ item }">
      {{ item.balance | formatCurrency }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn
        v-if="item.balance > 0"
        title="Auszahlen"
        icon
        @click="$emit('item-delete', item)"
        ><v-icon>fal fa-exchange</v-icon></v-btn
      >
      <v-btn
        v-else-if="item.payments.length === 0"
        icon
        @click="$emit('item-delete', item)"
        ><v-icon>fal fa-trash</v-icon></v-btn
      >
      <v-btn icon @click="getMailString(item)" title="E-Mail an Kunden senden"
        ><v-icon>fal fa-envelope</v-icon></v-btn
      >
    </template>
    <template v-slot:footer>
      <div>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="2" class="text-right caption"
              >Gutscheinwert offen:
              {{ couponOpenValue | formatCurrency }}</v-col
            >
            <v-col cols="2" class="text-right caption"
              >Gutscheinwert gesamt:
              {{ couponAllValue | formatCurrency }}</v-col
            >
          </v-row>
        </v-container>
      </div>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/utils/Table";
export default {
  name: "CouponsTable",
  components: { Table },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Token", value: "token" },
        { text: "Datum", value: "date" },
        { text: "Kunde", value: "customerName" },
        { text: "Restwert", value: "balance" },
        { text: "Betrag", value: "value" },
        { text: "Kommentar", value: "comment" },
        { text: "Aktion", value: "action" },
        { text: "Email", value: "email", align: " d-none" }
      ],
      coupons: [],
      couponOpenValue: 0,
      couponAllValue: 0,
      showCouponsWithBalance: false
    };
  },
  computed: {
    customers: function() {
      return this.$store.getters["customers/getAll"];
    },
    couponsStore: function() {
      return this.$store.getters["coupons/getAll"];
    }
  },
  methods: {
    getMailString: function(item) {
      let mailto = `mailto:${item.email}?subject=Gutschein ${item.token}`;

      const formattedBody = `${item.token}\n${item.customerName}`;
      mailto += `&body=${encodeURIComponent(formattedBody)}`;

      location.href = mailto;
    },
    initiate: function() {
      const coupons =
        this.showCouponsWithBalance === false
          ? this.$store.getters["coupons/getAllWithBalance"]
          : this.$store.getters["coupons/getAll"];

      this.coupons = coupons.map(item => {
        const customer = this.$store.getters["customers/getCustomer"](
          item.customer
        );
        if (customer.firstname && customer.lastname && customer.email) {
          item.customerName = `${customer.firstname} ${customer.lastname} (${customer.email})`;
          item.email = customer.email;
        }
        return item;
      });

      this.couponOpenValue = this.coupons.reduce((sum, item) => {
        return sum + item.balance;
      }, 0);
      this.couponAllValue = this.coupons.reduce((sum, item) => {
        return sum + item.value;
      }, 0);
    }
  },
  watch: {
    couponsStore: function() {
      this.initiate();
    }
  }
};
</script>
