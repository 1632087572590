<template v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
  <v-row>
    <v-col>
      <v-autocomplete
        label="Kursvorlage"
        :items="courseDefinitions"
        :rules="rules.required"
        v-model="value.definition"
      ></v-autocomplete>
    </v-col>
    <v-col>
      <v-autocomplete
        label="Bad und Bahn"
        :items="bathLanes"
        :rules="rules.required"
        v-model="value.bathLane"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BookingGeneratorStep1",
  props: ["courseDefinitions", "rules", "bathLanes", "value"]
};
</script>

<style scoped></style>
