<template>
  <v-list multiple>
    <v-list-item v-for="item in bookings" :key="item.id">
      <v-list-item-avatar :size="50">
        <CourseWorkloadCircle
          :size="50"
          :capacity-used="getCourse(item.course).capacityUsed"
          :capacity-min="getCourse(item.course).capacityMin"
          :capacity="getCourse(item.course).capacity"
        ></CourseWorkloadCircle>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          ><CourseTitle :course="getCourse(item.course)"></CourseTitle
        ></v-list-item-title>
        <v-list-item-subtitle
          >{{ getParticipant(item.participant).firstname }}
          {{ getParticipant(item.participant).lastname }}</v-list-item-subtitle
        >
        <v-list-item-subtitle>{{
          item.date | formatDate
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn :to="{ name: 'bookingEdit', params: { id: item.id } }">
          Details
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import CourseWorkloadCircle from "@/components/course/CourseWorkloadCircle";
import CourseTitle from "@/components/course/CourseTitle";
export default {
  name: "BookingOverview",
  components: {
    CourseTitle,
    CourseWorkloadCircle
  },
  computed: {
    bookings: function() {
      return this.$store.state.bookings.all.slice(0, 5);
    },
    courses: function() {
      return this.$store.state.courses.all;
    },
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAllWithDeleted"];
    },
    participants: function() {
      return this.$store.state.participants.all;
    }
  },
  methods: {
    getCourse: function(id) {
      const course = this.courses.find(course => course.id === id);
      if (course !== undefined) {
        return course;
      }

      return {};
    },
    getParticipant: function(id) {
      const participant = this.participants.find(
        participant => participant.id === id
      );
      if (participant !== undefined) return participant;
      return {};
    }
  }
};
</script>
