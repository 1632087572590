<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card>
        <v-card-title v-if="course.id > 0"
          >Kurs #{{ course.id }} {{ course.title }} bearbeiten</v-card-title
        >
        <v-card-title v-else>Neuen Kurs anlegen</v-card-title>
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-header class="elevation-0">
            <v-stepper-step step="1" :complete="step > 1"
              >Kursvorlage und Bad</v-stepper-step
            >
            <v-stepper-step step="2" :complete="step > 2"
              >Schwimmlehrer</v-stepper-step
            >
            <v-stepper-step step="3" :complete="step > 3"
              >Zeiten</v-stepper-step
            >
            <v-stepper-step step="4" :complete="step > 4"
              >Termine</v-stepper-step
            >
            <v-stepper-step step="5" :complete="step > 5"
              >Kurs angelegt</v-stepper-step
            >
          </v-stepper-header>
          <v-stepper-content step="1">
            <v-form ref="generator-step1">
              <CourseGeneratorStep1
                v-model="course"
                :bath-lanes="getBathLanes"
                :course-definitions="courseDefinitions"
                :rules="rules"
              ></CourseGeneratorStep1>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form ref="generator-step2">
              <v-row>
                <v-col
                  v-for="instructor in instructors"
                  :key="instructor.id"
                  cols="3"
                >
                  <v-checkbox
                    :rules="rules.minAmount"
                    v-model="course.instructors"
                    :value="instructor.id"
                    :label="instructor.firstname + ' ' + instructor.lastname"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form ref="generator-step3">
              <h2>Kurszeiten</h2>
              <v-row>
                <v-col
                  cols="6"
                  v-for="(time, index) in course.times"
                  :key="index"
                >
                  <v-radio-group
                    v-model="time.weekday"
                    row
                    :rules="rules.required"
                  >
                    <v-radio
                      v-for="weekday in weekdays"
                      :key="weekday.value"
                      :label="weekday.text"
                      :value="weekday.value"
                    ></v-radio>
                  </v-radio-group>
                  <v-time-picker
                    class="mt-4"
                    format="24hr"
                    landscape
                    v-model="time.time"
                    :ref="'timepicker-' + index"
                    :allowed-minutes="getFiveMinuteSteps"
                  ></v-time-picker>
                  <v-text-field
                    v-model="time.time"
                    readonly
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <h2 class="ma-8">Startdatum</h2>
                  <v-date-picker
                    v-model="course.startDate"
                    :allowed-dates="allowedDates"
                    locale="de-de"
                    full-width
                    landscape
                    :first-day-of-week="1"
                    @click:date="setInitialWeekday()"
                  ></v-date-picker>

                  <v-text-field
                    v-model="course.startDate"
                    readonly
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Wochentag</th>
                  <th>Startdatum</th>
                  <th>Enddatum</th>
                  <th>Bad</th>
                  <th>Trainer</th>
                  <th>Fehler</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in course.events" :key="item.event">
                  <td>{{ item.startDate | formatDateWeekday }}</td>
                  <td>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="item.menu.startDate"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span class="text-blue" v-bind="attrs" v-on="on">
                          {{ new Date(item.startDate) | formatDate }}</span
                        >
                      </template>
                      <v-date-picker
                        @input="changeEndTime(item)"
                        v-model="item.startDate"
                      />
                    </v-menu>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="item.menu.startTime"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span class="text-blue" v-bind="attrs" v-on="on">
                          {{ item.startTime }}</span
                        >
                      </template>
                      <v-time-picker
                        @input="changeEndTime(item)"
                        format="24hr"
                        v-model="item.startTime"
                      />
                    </v-menu>
                  </td>
                  <td>
                    <span>{{ new Date(item.endDate) | formatDate }} </span>
                    <span>{{ item.endTime }}</span>
                  </td>
                  <td>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="item.menu.bathLane"
                    >
                      <!-- TODO change Endpoint and enable next block -->
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <span class="text-blue" v-bind="attrs" v-on="on">
                          {{ getBathAndLaneTitle(item.bathLane) }}</span
                        >
                      </template> -->
                      <template v-slot:activator>
                        {{ getBathAndLaneTitle(item.bathLane) }}
                      </template>
                      <div class="selectbox">
                        <v-autocomplete
                          label="Bad und Bahn"
                          :items="getBathLanes"
                          v-model="item.bathLane"
                        />
                      </div>
                    </v-menu>
                  </td>
                  <td>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="item.menu.instructor"
                    >
                      <!-- TODO change Endpoint and enable next block -->
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <span class="text-blue" v-bind="attrs" v-on="on">
                          {{ getInstructorShortcuts(item.instructors) }}</span
                        >
                      </template> -->
                      <template v-slot:activator>
                        {{ getInstructorShortcuts(item.instructors) }}
                      </template>
                      <div class="selectbox selectbox--instructors">
                        <v-select
                          v-model="item.instructors"
                          :items="instructors"
                          item-value="id"
                          :item-text="
                            item => item.firstname + ' ' + item.lastname
                          "
                          label="Trainer"
                          attach
                          chips
                          multiple
                        />
                      </div>
                    </v-menu>
                  </td>
                  <td>
                    <template v-if="item.error.length > 0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" color="error"
                            >{{ item.error.length }} Fehler</v-btn
                          >
                        </template>
                        <ul class="errorList">
                          <li v-for="(error, index) in item.error" :key="index">
                            {{ error }}
                          </li>
                        </ul>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-btn color="success">0 Fehler</v-btn>
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-form ref="generator-step5">
              <h2>Kurs wurde mit ID #{{ courseId }} angelegt.</h2>
              <v-btn @click="addNewCourse">Neuen Kurs anlegen</v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper>

        <v-card-actions>
          <v-btn @click="lastStep" :disabled="step <= 1">Zurück</v-btn>
          <v-btn @click="nextStep" :disabled="step >= 4">Weiter</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveCourse()" :disabled="step < 4" color="primary"
            >Speichern</v-btn
          >
          <v-btn @click="clearSteps" color="error">Leeren</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CourseGeneratorStep1 from "../components/course/CourseGeneratorStep1";
import moment from "moment";
export default {
  name: "CourseGenerator",
  components: {
    CourseGeneratorStep1
  },
  computed: {
    courseDefinitions: function() {
      let courseDefinitions = [];
      this.$store.getters["courseDefinitions/getAll"].forEach(item => {
        courseDefinitions.push({
          text: item.title,
          value: item.id
        });
      });
      return courseDefinitions;
    },
    courseDefinitionCategories: function() {
      return this.$store.state.courseDefinitionCategories.all;
    },
    baths: function() {
      return this.$store.getters["baths/getAll"];
    },
    bathLanes: function() {
      return this.$store.getters["bathLanes/getAll"];
    },
    getBathLanes() {
      let bathLanes = [];
      this.bathLanes.forEach(bathLane => {
        const bathTitle = this.baths.find(bath => bath.id === bathLane.bath)
          .title;
        bathLanes.push({
          text: bathTitle + " - " + bathLane.title,
          value: bathLane.id
        });
      });
      return bathLanes;
    },
    instructors: function() {
      return this.$store.state.instructors.all;
    },
    howManyDaysPerWeek() {
      if (this.course.definition === 0) return 0;
      return this.$store.getters["courseDefinitions/getAll"].find(
        definition => definition.id === this.course.definition
      ).lessonPerWeek;
    }
  },
  methods: {
    getLessonsDuration: function() {
      return this.$store.getters["courseDefinitions/getAll"].find(
        ({ id }) => id === this.course.definition
      )?.lessonDuration;
    },
    changeEndTime: function(item) {
      const duration = this.getLessonsDuration();
      if (!duration) return;
      const newEndDateTime = moment(`${item.startDate} ${item.startTime}`).add(
        duration,
        "m"
      );
      item.endDate = newEndDateTime.format("YYYY-MM-DD");
      item.endTime = newEndDateTime.format("HH:mm");
    },
    allowedDates(val) {
      let possibleDows = [];
      this.course.times.forEach(time => {
        possibleDows.push(time.weekday);
      });
      const date = new Date(val);
      if (possibleDows.includes(date.getDay().toString())) {
        return val;
      }
    },
    nextStep() {
      if (!this.$refs["generator-step" + this.step].validate()) return;
      if (this.step === 3) this.checkEvents();
      if (this.step <= this.maxStep) this.step++;
    },
    lastStep() {
      if (this.step >= 1) this.step--;
    },
    clearSteps() {
      this.course = Object.assign({}, this.emptyCourse);
      this.step = 1;
    },
    saveCourse: async function() {
      const course = { ...this.course };
      course.events = course.events.map(item => {
        const event = { ...item };
        event.startDate = `${event.startDate} ${event.startTime}`;
        event.endDate = `${event.endDate} ${event.endTime}`;
        delete event.menu;
        delete event.startTime;
        delete event.endTime;
        return event;
      });

      const response = await this.$store.dispatch("courses/add", course);
      this.courseId = response.data.id;
      await this.$store.dispatch("events/getAll");
      this.step = 5;
    },
    addNewCourse() {
      this.clearSteps();
    },
    setInitialWeekday() {
      const date = new Date(this.course.startDate);
      this.course.weekdays = [date.getDay().toString()];
    },
    checkEvents() {
      this.course.events = [];
      this.$store.dispatch("courses/prepareEvents", this.course).then(data => {
        data = data.map(item => {
          const startDateTime = moment(item.startDate);
          const endDateTime = moment(item.endDate);

          item.startDate = startDateTime.format("YYYY-MM-DD");
          item.startTime = startDateTime.format("HH:mm");
          item.endDate = endDateTime.format("YYYY-MM-DD");
          item.endTime = endDateTime.format("HH:mm");
          item.menu = {};
          item.bathLane = this.course.bathLane;
          item.instructors = this.course.instructors;
          return item;
        });
        this.course.events = data;
      });
    },
    getBath(id) {
      return this.baths.find(bath => bath.id === id);
    },
    getBathLane(id) {
      return this.bathLanes.find(bathLane => bathLane.id === id);
    },
    getBathAndLaneTitle(bathLaneId) {
      const bathLane = this.getBathLane(bathLaneId);
      const bath = this.getBath(bathLane.bath);

      return bath.title + " " + bathLane.title;
    },
    getFiveMinuteSteps: minutes => minutes % 5 === 0,
    getInstructorShortcuts(currentInstructors) {
      let instructors = [];
      for (let i = 0; i < currentInstructors.length; i++) {
        const instructor = this.instructors.find(
          instructor => instructor.id === currentInstructors[i]
        );
        instructors.push(instructor.shortcut);
      }
      return instructors.join(", ");
    }
  },
  data() {
    return {
      courseId: 0,
      course: {
        definition: 0,
        bathLane: 0,
        instructors: [],
        startDate: null,
        weekdays: [],
        startTime: [],
        events: [],
        times: []
      },
      emptyCourse: {
        definition: 0,
        bathLane: 0,
        instructors: [],
        startDate: null,
        weekdays: [],
        startTime: [],
        events: [],
        times: []
      },
      step: 1,
      maxStep: 5,
      rules: {
        required: [v => !!v || "Bitte eine Option auswählen."],
        minAmount: [
          v => v.length > 0 || "Bitte mindestens ein Element auswählen."
        ],
        checkMultiple: [
          v =>
            v.length === this.howManyDaysPerWeek ||
            "Bitte " + this.howManyDaysPerWeek + " Tag(e) auswählen."
        ]
      },
      weekdays: [
        { text: "Montag", value: "1" },
        { text: "Dienstag", value: "2" },
        { text: "Mittwoch", value: "3" },
        { text: "Donnerstag", value: "4" },
        { text: "Freitag", value: "5" },
        { text: "Samstag", value: "6" },
        { text: "Sonntag", value: "0" }
      ]
    };
  },
  watch: {
    "course.definition": function(value) {
      const definition = this.$store.getters["courseDefinitions/getAll"].find(
        item => item.id === value
      );
      if (definition !== undefined) {
        this.course.times = [];
        for (let i = 0; i < definition.lessonPerWeek; i++) {
          this.course.times.push({
            weekday: null,
            time: null
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.errorList {
  list-style-type: none;
  margin: 0;
  padding-left: 0;

  li {
    padding-left: 0;
  }
}
.text-blue {
  color: #1976d2;
  &:hover {
    opacity: 0.5;
  }
}
.selectbox {
  background: white;
  padding: 20px 10px 10px 10px;
  width: 500px;
  &--instructors {
    height: 250px;
  }
}
</style>
