const state = {
  course: { term: "" },
  instructorCalendar: {
    instructor: null,
    bath: null,
    area: null
  },
  bookingCalendar: {
    instructor: null,
    bath: null,
    area: null
  },
  planMonth: {
    bath: null
  },
  booking: { term: "" },
  bookingSingle: { term: "" },
  customer: { term: "" },
  participant: { term: "" },
  coupon: { term: "" },
  payment: { term: "", filter: "" }
};

// getters
const getters = {};

// actions
const actions = {
  set(context, item) {
    context.commit("SET", item);
  }
};

// mutations
const mutations = {
  SET(state, item) {
    state[item.type] = Object.assign({}, item.search);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
