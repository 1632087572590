<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <BathTable @item-edit="editItem" @new-bath="addBath" />
      <v-dialog v-model="dialogItemEdit" max-width="1000">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Schwimmbad bearbeiten</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogItemEdit = false">
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form ref="bath">
              <BathEditForm :bath.sync="bath"></BathEditForm>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="saveBath">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import BathTable from "@/components/bath/BathTable";
import BathEditForm from "@/components/bath/BathEditForm";

export default {
  name: "Baths",
  components: { BathEditForm, BathTable },
  data() {
    return {
      dialogItemEdit: false,
      search: "",
      filter: "",
      bath: null
    };
  },
  methods: {
    addBath: function() {
      this.bath = Object.assign({ id: 0 });
      this.dialogItemEdit = true;
    },
    editItem: function(item) {
      this.bath = Object.assign({}, item);
      this.dialogItemEdit = true;
    },
    saveBath: function() {
      const vue = this;
      if (this.bath.id > 0) {
        this.$store.dispatch("baths/update", this.bath).then(() => {
          vue.$store.dispatch("messages/add", {
            message: "Bad gespeichert",
            color: "success"
          });
          vue.bath = Object.assign({});
          vue.dialogItemEdit = false;
        });
      } else if (this.bath.id === 0) {
        if (this.$refs["bath"].validate()) {
          this.$store.dispatch("baths/add", this.bath).then(() => {
            vue.$store.dispatch("messages/add", {
              message: "Bad neu angelegt",
              color: "success"
            });
            vue.bath = Object.assign({});
            vue.dialogItemEdit = false;
          });
        } else {
          vue.$store.dispatch("messages/add", {
            message: "Bitte überprüfen Sie die Pflichtfelder",
            color: "alert"
          });
        }
      }
    }
  }
};
</script>

<style scoped></style>
