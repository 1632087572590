import ApiService from "../../common/api.service";

const getters = {
  getAll() {
    return ApiService.get("/public/locations").then(response => response.data);
  }
};

const actions = {
  getLocationData(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/locations/${id}/settings`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setLocationData(context, data) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/locations/${data.id}/settings`, data)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  actions,
  getters
};
