<template>
  <v-card class="pb-4" flat>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Kunden zusammenführen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"><v-icon>fal fa-times</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <h2 class="mt-4">Von Kunde</h2>
            <v-autocomplete
              v-model="secondaryCustomer"
              :items="customers"
              item-value="id"
              :item-text="({ id, name, email }) => `#${id}: ${name} (${email})`"
            />
          </v-col>
          <v-col>
            <h2 class="mt-4">Auf Kunde</h2>
            <v-autocomplete
              v-model="primaryCustomer"
              :items="customers"
              item-value="id"
              :item-text="({ id, name, email }) => `#${id}: ${name} (${email})`"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn @click.prevent="mergeData" color="primary">
        Zusammenführen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CustomersMerge",
  components: {},
  props: ["customers"],
  data: function() {
    return {
      primaryCustomer: null,
      secondaryCustomer: null,
    };
  },
  computed: {},
  methods: {
    mergeData: async function() {
      this.$store.dispatch("messages/del");
      if (!this.primaryCustomer || !this.secondaryCustomer) {
        return this.$store.dispatch("messages/add", {
          message: "Bitte Kunden auswählen",
          color: "warning",
        });
      } else if (this.primaryCustomer === this.secondaryCustomer) {
        return this.$store.dispatch("messages/add", {
          message: "Kunden müssen sich unterscheiden",
          color: "warning",
        });
      }
      if (
        !confirm(
          `Wollen sie wirkich alle Daten von #${this.secondaryCustomer} auf #${this.primaryCustomer} übertragen?\nHierbei wird #${this.secondaryCustomer} gelöscht.`
        )
      ) {
        return this.$emit("close");
      }
      const coupons = this.$store.getters["coupons/getByCustomerId"](
        this.secondaryCustomer
      );
      const participants = this.$store.getters["participants/getAll"]?.filter(
        ({ customer }) => customer === this.secondaryCustomer
      );

      await Promise.all([
        ...coupons.map(async (coupon) => {
          coupon = { ...coupon };
          coupon.customer = this.primaryCustomer;
          try {
            await this.$store.dispatch(`coupons/update`, coupon);
          } catch (error) {
            this.$store.dispatch("messages/add", {
              message: "Es ist ein Fehler aufgetreten",
              color: "error",
            });
          }
        }),
        ...participants.map(async (participant) => {
          participant = { ...participant };
          participant.customer = this.primaryCustomer;
          try {
            await this.$store.dispatch(
              `participants/updateParticipant`,
              participant
            );
          } catch (error) {
            this.$store.dispatch("messages/add", {
              message: "Es ist ein Fehler aufgetreten",
              color: "error",
            });
          }
        }),
      ]);
      const customer = this.$store.getters["customers/getCustomer"](
        this.secondaryCustomer
      );
      await this.$store.dispatch(`customers/delete`, customer);
      this.$emit("close");
      this.$store.dispatch("messages/add", {
        message: "Kunden wurden erfolgreich zusammengeführt",
        color: "success",
      });
    },
  },
};
</script>
