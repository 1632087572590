<template>
  <div>
    <v-dialog max-width="400" v-model="split.showDialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Zahlung aufteilen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="split.showDialog = false"
            ><v-icon>fal fa-times</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-form ref="split">
            <p v-if="split.item !== null">
              ID: {{ split.item.id }}<br />
              Datum: {{ split.item.date | formatDate }}<br />
              Betrag: {{ split.item.value | formatCurrency }}<br />
              Betreff: {{ split.item.information }}
            </p>
            <v-text-field
              label="Betrag"
              v-model="split.value"
              :rules="[rules.required, rules.currency, checkValue]"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="splitValue">Aufteilen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Table
      :search.sync="searchTerm"
      :items="payments"
      :headers="headers"
      :itemsPerPage="20"
    >
      <template v-slot:actions>
        <v-select
          class="ml-8"
          :items="filterItems"
          label="Filtern"
          v-model="searchFilter"
        />
      </template>
      <template v-slot:button>
        <v-btn text disabled class="mr-4"
          >Letzter Import: {{ lastBankPayment | formatDate }}</v-btn
        >
        <v-btn class="mr-4" @click="$emit('check-open-payments')"
          ><v-icon left>far fa-file-check</v-icon>Zahlungen zuordnen</v-btn
        >
        <v-btn class="mr-4" @click="$emit('open-dialog')"
          ><v-icon left>fal fa-upload</v-icon>CSV Import</v-btn
        >
        <v-btn @click="$emit('open-export-dialog')"
          ><v-icon left>fal fa-download</v-icon>CSV Export</v-btn
        >
      </template>
      <template v-slot:item.bookingTitle="{ item }">
        <v-container>
          <v-row v-if="item.booking !== null">
            <v-col cols="3" class="align-self-center"
              >#{{ item.booking }}</v-col
            >
            <v-col class="caption">
              {{ item.course.title }}<br />
              {{ item.participant.firstname }} {{ item.participant.lastname }}
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col
              ><v-btn
                text
                color="primary"
                :to="{ name: 'payments-assign', params: { id: item.id } }"
                >Buchung zuweisen</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.source="{ item }">
        <v-icon v-if="item.source === 'paypal'">fab fa-paypal</v-icon>
        <v-icon v-if="item.source === 'bank'">fal fa-landmark</v-icon>
        <v-icon v-if="item.source === 'bank-import'">fal fa-landmark</v-icon>
        <v-icon v-if="item.source === 'coupon'">fal fa-money-check</v-icon>
        <v-icon v-if="item.source === 'internal'">fal fa-exchange</v-icon>
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date | formatDate }}
      </template>
      <template v-slot:item.value="{ item }">
        {{ item.value | formatCurrency }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="item.booking === null"
          icon
          @click="toggleSplitDialog(item)"
          ><v-icon>fal fa-page-break</v-icon></v-btn
        >
        <v-btn
          v-if="item.source !== 'coupon' && item.booking !== null"
          icon
          @click="unassignPayment(item)"
          ><v-icon>fal fa-unlink</v-icon></v-btn
        >
        <v-btn
          v-if="item.source === 'coupon' || item.booking === null"
          icon
          @click="deletePayment(item)"
          ><v-icon>fal fa-trash</v-icon></v-btn
        >
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/utils/Table";
export default {
  name: "PaymentsTable",
  components: { Table },
  props: ["filterItems"],
  data() {
    return {
      search: { term: "", filter: "" },
      split: {
        item: null,
        showDialog: false,
        value: "",
        date: null
      },
      rules: {
        required: v => !!v || "Pflichtfeld",
        currency: v => /^\d+,?\d*$/.test(v) || "Wert ist nicht valide"
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Quelle", value: "source" },
        { text: "Buchung", value: "bookingTitle" },
        { text: "Datum", value: "date" },
        { text: "Information", value: "information" },
        { text: "Betrag", value: "value" },
        { text: "Aktion", value: "action" }
      ],
      items: [],
      lastBankPayment: "01.01.1970"
    };
  },
  computed: {
    customers: function() {
      return this.$store.state.customers.all;
    },
    payments: function() {
      if (this.searchFilter === "open")
        return this.items.filter(item => item.booking === null);
      return this.items;
    },
    searchTerm: {
      set: function(value) {
        this.search.term = value;
        this.$store.dispatch("search/set", {
          type: "payment",
          search: this.search
        });
      },
      get: function() {
        return this.search.term;
      }
    },
    searchFilter: {
      set: function(value) {
        this.search.filter = value;
        this.$store.dispatch("search/set", {
          type: "payment",
          search: this.search
        });
      },
      get: function() {
        return this.search.filter;
      }
    },
    paymentsStore() {
      return this.$store.state.payments.all;
    }
  },
  methods: {
    getLastBankPayment() {
      const payment = this.payments
        .filter(payment => payment.source === "bank-import")
        .sort((a, b) => a.date > b.date)
        .splice(-1);
      if (payment[0] !== undefined) this.lastBankPayment = payment[0].date;
      else this.lastBankPayment = "1970-01-01";
    },
    checkValue: function(value) {
      return (
        (value && +value < +this.split.item.value) ||
        "Betrag ist größer als die eigentliche Zahlung"
      );
    },
    toggleSplitDialog: function(item) {
      this.split.item = item;
      this.split.showDialog = true;
    },
    splitValue: async function() {
      if (!this.$refs.split.validate()) return;

      const payment = { ...this.split.item };
      const newPayment = { ...this.split.item };

      payment.value = +this.split.value;

      newPayment.value -= this.split.value;
      newPayment.booking = null;

      delete newPayment.id;
      delete newPayment.bookingTitle;
      delete newPayment.bookingObject;

      await Promise.all([
        this.$store.dispatch("payments/update", payment),
        this.$store.dispatch("payments/add", newPayment)
      ]);

      this.filter = "open";
      this.searchTerm = payment.information; //TODO SEARCH FOR ID INSTEAD OF INFORMATION
      this.split.item = null;
      this.split.value = "";
      this.split.showDialog = false;
    },
    unassignPayment: function(item) {
      if (item.source === "internal" && item.coupon !== null) {
        if (
          !confirm(
            "Soll die Zahlung aufgehoben und der hinterlegte Gutschein gelötscht werden?"
          )
        )
          return;

        const coupon = this.$store.getters["coupons/getById"](item.coupon);
        this.$store.dispatch("coupons/delete", coupon);
      }
      const payment = Object.assign({}, item);
      payment.booking = null;
      this.$store.dispatch("payments/update", payment).then(() => {
        this.loadPayments();
        this.$store.dispatch("bookings/getAll");
      });
    },
    deletePayment: function(item) {
      if (
        confirm("Soll die Zahlung #" + item.id + " wirklich gelöscht werden?")
      ) {
        this.$store.dispatch("payments/delete", item).then(() => {
          this.loadPayments();
          this.$store.dispatch("bookings/getAll");
        });
      }
    },
    loadPayments: function() {
      const vue = this;
      vue.items = vue.$store.state.payments.all.reduce((result, payment) => {
        if (!payment) return result;

        payment.course = { title: "" };
        payment.participant = { firstname: "", lastname: "" };
        payment.bookingObject = {};
        if (payment.booking !== null) {
          payment.course = vue.getCourseByBooking(payment.booking);
          payment.participant = vue.getParticipantByBooking(payment.booking);
          payment.bookingObject = vue.getBookingById(payment.booking);
        }
        payment.bookingTitle =
          payment.course.title +
          " " +
          payment.participant.firstname +
          " " +
          payment.participant.lastname;

        result.push(payment);
        return result;
      }, []);

      vue.getLastBankPayment();
    },
    getBookingById: function(id) {
      return this.$store.getters["bookings/getById"](id);
    },
    getCourseByBooking: function(id) {
      const booking = this.getBookingById(id);
      if (booking) {
        return this.$store.getters["courses/getById"](booking.course);
      }
      return {};
    },
    getParticipantByBooking: function(id) {
      const booking = this.getBookingById(id);

      if (booking) {
        return this.$store.getters["participants/getParticipant"](
          booking.participant
        );
      }

      return {};
    }
  },
  created() {
    this.search = Object.assign({}, this.$store.state.search.payment);
    this.$store.dispatch("payments/getAll");
  },
  watch: {
    paymentsStore(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadPayments();
      }
    }
  }
};
</script>
