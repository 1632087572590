const state = {
  all: []
};

// getters
const getters = {};

// actions
const actions = {
  add(context, message) {
    context.commit("ADD", message);
  },
  del(context) {
    context.commit("DEL");
  }
};

// mutations
const mutations = {
  ADD(state, item) {
    state.all.push(item);
  },
  DEL(state) {
    state.all.splice(0, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
