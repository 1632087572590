<template>
  <v-container>
    <v-row>
      <v-col v-for="(role, index) in roles" :key="index">
        <v-checkbox
          :label="role.label"
          :rules="rules"
          :value="role.role"
          v-model="instructorRolesProxy"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InstructorRoles",
  props: ["instructorRoles"],
  computed: {
    roles: function() {
      return this.$store.state.roles.all;
    },
    instructorRolesProxy: {
      set: function(value) {
        this.instructorRolesValue = value;
        this.$emit("update:instructorRoles", this.instructorRolesValue);
      },
      get: function() {
        return this.instructorRolesValue;
      },
    },
  },
  data() {
    return {
      rules: [
        (v) => (v && v.length > 0) || "Bitte mindestens ein Element auswählen.",
      ],
      instructorRolesValue: [],
    };
  },
  watch: {
    instructorRoles: function(value) {
      this.instructorRolesValue = Object.values(value);
    },
  },
};
</script>

<style scoped></style>
