const state = {
  ajaxCounter: 0
};

// getters
const getters = {
  getAjaxCounter: state => {
    return state.ajaxCounter;
  }
};

// actions
const actions = {
  changeAjaxCounter(context, item) {
    if (item) {
      context.commit("INCREASE_AJAX_COUNTER");
    } else {
      context.commit("DECREASE_AJAX_COUNTER");
    }
  }
};

// mutations
const mutations = {
  INCREASE_AJAX_COUNTER(state) {
    state.ajaxCounter++;
  },
  DECREASE_AJAX_COUNTER(state) {
    state.ajaxCounter--;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
