<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card flat>
        <v-card-title
          ><v-spacer></v-spacer
          ><v-btn @click="newInstructor">Neuer Benutzer</v-btn></v-card-title
        >
        <InstructorTable
          @edit-item="editInstructor"
          @delete-item="deleteInstructor"
          @resend-password="resendPassword"
          @new-instructor="newInstructor"
        />
      </v-card>

      <v-dialog max-width="1000" v-model="dialogInstructor">
        <v-card flat>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Benutzer bearbeiten</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogInstructor = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <v-form ref="instructorsFormRef">
            <v-card-text class="mt-4">
              <v-container>
                <v-row>
                  <v-col cols="6"
                    ><v-text-field
                      label="Benutzername"
                      readonly
                      v-model="instructor.username"
                      v-if="instructor.id > 0"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <h4>Foto</h4>
                    <v-avatar size="128">
                      <img
                        v-if="instructor.imageBinary !== undefined"
                        :src="instructor.imageBinary"
                        width="300"
                      />
                      <img
                        v-else-if="instructor.image !== undefined"
                        :src="apiUrl + instructor.image"
                        width="300"
                      />
                    </v-avatar>
                    <v-file-input
                      v-model="instructor.image"
                      hide-input
                      label="Foto"
                      @change="uploadImage"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"
                    ><v-text-field
                      label="Kürzel"
                      v-model="instructor.shortcut"
                      :rules="[rules.required]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6"
                    ><Gender :gender.sync="instructor.gender"></Gender
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"
                    ><v-text-field
                      label="Vorname"
                      v-model="instructor.firstname"
                      :rules="[rules.required]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6"
                    ><v-text-field
                      label="Nachname"
                      v-model="instructor.lastname"
                      :rules="[rules.required]"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                    ><v-text-field
                      label="Straße"
                      v-model="instructor.street"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="4"
                    ><v-text-field
                      label="Postleitzahl"
                      v-model="instructor.postal"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="8"
                    ><v-text-field
                      label="Ort"
                      v-model="instructor.city"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"
                    ><v-text-field
                      label="E-Mail"
                      v-model="instructor.email"
                      :rules="[rules.required]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6"
                    ><v-text-field
                      label="Telefon"
                      v-model="instructor.phone"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"
                    ><v-text-field
                      label="Sprachen"
                      v-model="instructor.languages"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6"
                    ><v-text-field
                      label="Qualifikation"
                      v-model="instructor.qualification"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                    ><v-textarea
                      label="Beschreibung"
                      v-model="instructor.description"
                    ></v-textarea
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"
                    ><v-switch
                      label="Auf der Homepage anzeigen"
                      v-model="instructor.showOnHomepage"
                    ></v-switch
                  ></v-col>
                </v-row>
                <InstructorRoles
                  :instructor-roles.sync="instructor.roles"
                ></InstructorRoles>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn @click="saveInstructor">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import InstructorTable from "@/components/instructor/InstructorTable";
import Gender from "@/components/Gender";
import InstructorRoles from "@/components/instructor/InstructorRoles";
export default {
  name: "Instructors",
  components: { InstructorRoles, Gender, InstructorTable },
  data() {
    return {
      rules: {
        required: (v) => !!v || "Pflichtfeld",
      },
      dialogInstructor: false,
      instructor: {},
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL + "/";
    },
  },
  methods: {
    newInstructor: function() {
      this.dialogInstructor = true;
      this.instructor = { id: 0 };
    },
    editInstructor: function(item) {
      this.dialogInstructor = true;
      this.instructor = Object.assign({}, item);
    },
    saveInstructor: function() {
      if (!this.$refs.instructorsFormRef.validate()) return;
      const vue = this;
      if (this.instructor.id > 0) {
        this.$store
          .dispatch("instructors/update", this.instructor)
          .then(() => {
            vue.$store.dispatch("messages/add", {
              message: "Benutzer erfolgreich gespeichert",
              color: "success",
            });
          })
          .catch(() => {
            vue.$store.dispatch("messages/add", {
              message: "Benutzer konnte nicht gespeichert werden",
              color: "error",
            });
          });
      } else {
        this.$store
          .dispatch("instructors/add", this.instructor)
          .then(() => {
            vue.$store.dispatch("messages/add", {
              message: "Benutzer erfolgreich angelegt",
              color: "success",
            });
          })
          .catch(() => {
            vue.$store.dispatch("messages/add", {
              message: "Benutzer konnte nicht angelegt werden",
              color: "error",
            });
          });
      }
      this.dialogInstructor = false;
    },
    uploadImage: function(file) {
      if (file === null) return;

      const vue = this;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener(
        "load",
        function() {
          vue.instructor.imageBinary = fileReader.result;
          vue.instructor.image = fileReader.result;
        },
        false
      );
    },
    deleteInstructor: function(instructor) {
      if (
        !confirm(
          "Soll der Schwimmlehrer " +
            instructor.firstname +
            " " +
            instructor.lastname +
            " wirklich gelöscht werden?"
        )
      )
        return;
      const vue = this;
      this.$store
        .dispatch("instructors/delete", instructor)
        .then(() => {
          vue.$store.dispatch("messages/add", {
            message: "Benutzer wurde gelöscht",
            color: "success",
          });
        })
        .catch(() => {
          vue.$store.dispatch("messages/add", {
            message: "Benutzer konnte nicht gelöscht werden",
            color: "error",
          });
        });
    },
    resendPassword(instructor) {
      const vue = this;
      this.$store
        .dispatch("instructors/resendPassword", instructor)
        .then(() => {
          vue.$store.dispatch("messages/add", {
            message:
              "Ein neues Passwort wurde generiert und an den Benutzer gesendet",
            color: "success",
          });
        })
        .catch(() => {
          vue.$store.dispatch("messages/add", {
            message:
              "Es konnte kein neues Passwort generietr und versendet werden",
            color: "error",
          });
        });
    },
  },
};
</script>

<style scoped></style>
