<template v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
  <v-container fluid>
    <!--<v-card flat class="mb-4">
      <v-card-title>Profil</v-card-title>
      <v-container>
        <v-row>
          <v-col></v-col>
          <v-col><v-text-field label="Vorname"></v-text-field></v-col>
          <v-col><v-text-field label="Nachname"></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col><v-text-field label="E-Mail"></v-text-field></v-col>
          <v-col><v-text-field label="Telefon"></v-text-field></v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn>Speichern</v-btn>
      </v-card-actions>
    </v-card>-->

    <v-form @submit.prevent="savePassword" ref="password">
      <v-card flat>
        <v-card-title>Passwort</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              ><v-text-field
                type="password"
                label="Passwort"
                v-model="value.password"
                :rules="passwordRules"
                hint="Mindestens 8 Zeichen"
              ></v-text-field
            ></v-col>
            <v-col
              ><v-text-field
                type="password"
                label="Passwort wiederholen"
                v-model="passwordRepeat"
                :rules="[passwordConfirmationRule]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-alert type="success" :value="passwordChangeSuccessful"
            >Das Password wurde erfolgreich geändert.</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-btn @click="savePassword">Passwort speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "ProfileEdit",
  props: ["value"],
  data: () => ({
    passwordRepeat: "",
    passwordRules: [
      v => !!v || "Bitte geben Sie ein Passwort ein",
      v =>
        (v && v.length >= 8) ||
        "Das Passwort muss aus mindestens 8 Zeichen bestehen"
    ],
    passwordChangeSuccessful: false
  }),
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.value.password === this.passwordRepeat ||
        "Die Passwörter müssen übereinstimmen";
    }
  },
  methods: {
    savePassword() {
      if (this.$refs.password.validate()) {
        this.$store.dispatch("instructors/update", this.value).then(() => {
          this.passwordChangeSuccessful = true;
          this.passwordRepeat = "";
          this.$refs.password.reset();
          setTimeout(() => {
            this.passwordChangeSuccessful = false;
          }, 5000);
        });
      }
    }
  }
};
</script>

<style scoped></style>
