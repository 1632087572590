import Vue from "vue";
import * as Sentry from "@sentry/vue";
import package_json from "../../package.json";
import router from "../router";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn:
      "https://21cb149c054995b2de4390a7d823fc7e@o4506812552314880.ingest.sentry.io/4506852796727296",
    integrations: [
      Sentry.browserTracingIntegration(router),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      }),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "light",
        autoInject: false,
        showBranding: false,
        showName: false,
        showEmail: false,
        formTitle: "Fehler melden",
        messageLabel: "Beschreibung",
        messagePlaceholder: "",
        successMessageText: "Vielen Dank für Ihre Nachricht.",
        cancelButtonLabel: "Abbrechen",
        submitButtonLabel: "Bericht senden"
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    release: "swym-manager@" + package_json.version,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id
        });
      }
      return event;
    }
  });
}
