<template>
  <v-card flat>
    <v-card-text>
      <v-row class="pa-1">
        <v-col>Rechnungsbetrag:</v-col>
        <v-col class="text-right">{{ invoiceAmount | formatCurrency }}</v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-1">
        <v-col>Zahlungsbetrag:</v-col>
        <v-col class="text-right">{{ paymentAmount | formatCurrency }}</v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-1">
        <v-col>Saldo:</v-col>
        <v-col class="text-right"
          ><span :class="getColorByBalance()">{{
            balance | formatCurrency
          }}</span></v-col
        >
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="balance < 0" @click="balanceToCoupon(booking)"
        >Guthaben in Gutschein umwandeln</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PaymentBalance",
  props: ["booking"],
  computed: {
    invoiceAmount: function() {
      return this.booking.invoiceAmount;
    },
    paymentAmount: function() {
      return this.booking.paymentAmount;
    },
    balance: function() {
      return this.invoiceAmount - this.paymentAmount;
    }
  },
  methods: {
    getColorByBalance: function() {
      if (this.balance > 0) return "red--text";
      if (this.balance === 0) return "green--text";
      if (this.balance < 0) return "blue--text";
    },
    getCustomerIdByParticipantId: function(id) {
      return this.$store.state.participants.all.find(item => item.id === id)
        .customer;
    },
    balanceToCoupon: function(booking) {
      const vue = this;
      const coupon = {
        date: new Date(),
        value: this.balance * -1,
        customer: this.getCustomerIdByParticipantId(booking.participant),
        comment: "Umwandlung aus Guthaben"
      };
      vue.$store.dispatch("coupons/create", coupon).then(function(response) {
        const payment = {
          date: new Date(),
          value: vue.balance,
          information: "Gutschein #" + response.data.token,
          booking: booking.id,
          source: "internal",
          coupon: response.data.id
        };

        vue.$store.dispatch("payments/add", payment).then(function() {
          vue.$store.dispatch("bookings/getAll");
        });
      });
    }
  }
};
</script>

<style scoped></style>
