<template>
  <v-list>
    <v-list-item v-for="log in logItems" :key="log.id">
      <v-list-item-avatar
        ><v-icon :color="log.success ? 'green' : 'red'"
          >fal {{ getIcon(log.action) }}</v-icon
        ></v-list-item-avatar
      >
      <v-list-item-content>
        <v-list-item-title>{{ getText(log.action) }}</v-list-item-title>
        <v-list-item-subtitle
          >{{ log.date | formatDateTime }} {{ log.user }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="items.length > 2">
      <v-list-item-content @click="limitItems = !limitItems">
        <v-btn text v-if="limitItems">Weitere anzeigen</v-btn>
        <v-btn text v-else>Weniger anzeigen</v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "LogListItem",
  props: ["items"],
  data() {
    return {
      limitItems: true,
      actions: {
        confirmation: {
          icon: "fa-envelope",
          text: "Bestätigungsmail"
        },
        reminder: {
          icon: "fa-envelope",
          text: "Erinnerungsmail"
        },
        balance: {
          icon: "fa-envelope",
          text: "Kontostandsmail"
        },
        hybridCourse: {
          icon: "fa-envelope",
          text: "Hybridkursmail"
        },
        newBooking: {
          icon: "fa-plus",
          text: "Neue Buchung (manuell)"
        },
        newBookingWebsite: {
          icon: "fa-plus",
          text: "Neue Buchung (Webseite)"
        },
        updateBooking: {
          icon: "fa-shopping-cart",
          text: "Buchung geändert"
        },
        deleteBooking: {
          icon: "fa-trash",
          text: "Buchung gelöscht"
        },
        deleteBookingWithPayment: {
          icon: "fa-trash",
          text: "Buchung gelöscht"
        }
      }
    };
  },
  computed: {
    logItems() {
      const items = [...this.items];
      return this.limitItems ? items.splice(0, 2) : items;
    }
  },
  methods: {
    getIcon(action) {
      return this.actions[action].icon;
    },
    getText(action) {
      return this.actions[action].text;
    }
  }
};
</script>
