<template>
  <v-card flat>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Teilnehmer bearbeiten</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>fal fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card v-if="this.courseData">
      <v-card-title class="pb-0"
        >{{ event.startDate | formatDateTime }} - {{ courseData.title }} #{{
          courseData.id
        }}
      </v-card-title>

      <v-card-title>Verfügbare Termine</v-card-title>

      <v-card-text>
        <v-chip-group
          v-model="selection"
          active-class="primary accent-4 white--text"
          column
        >
          <v-chip
            v-for="(item, i) in availableEvents"
            @click="changeEvent(item)"
            :key="item.id"
            >{{ courseData.type !== "club" ? `${i + 1}:` : "" }}
            {{ item.startDate | formatDate }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="filteredParticipants"
      :items-per-page="-1"
      :hide-default-footer="true"
      class="elevation-1"
    >
      <!--<template v-slot:item.corona="{ item }">
        <v-btn x-small fab color="green" v-if="item.coronaStatus">T</v-btn>
        <v-btn x-small fab color="red" v-else>T</v-btn>
        <v-btn
          x-small
          fab
          color="green"
          v-if="item.escortCovidStatus === true"
          :title="item.escortName"
          >B</v-btn
        >
        <v-btn
          x-small
          fab
          color="red"
          v-else-if="item.escortCovidStatus === false"
          :title="item.escortName"
          >B</v-btn
        >
        <v-btn x-small fab color="grey" v-else :title="item.escortName"
          >B</v-btn
        >
      </template>-->
      <template v-slot:item.status="{ item }">
        <v-btn-toggle v-model="item.status">
          <v-btn
            v-for="({ color, icon }, i) in statusOptions"
            :key="i"
            :color="color"
            :class="
              item.status !== '' && +item.status === i
                ? 'v-item--active v-btn--active'
                : 'opacity-5'
            "
          >
            <v-icon color="white">{{ icon }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:item.contact="{ item }">
        <v-btn icon :href="'mailto:' + item.email">
          <v-icon>fal fa-envelope</v-icon>
        </v-btn>
        <v-btn icon :href="'tel:' + item.phone">
          <v-icon>fal fa-phone</v-icon>
        </v-btn>
        <v-tooltip top v-if="item.clubEndDate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on" icon>
              <v-icon>fal fa-info</v-icon>
            </v-btn>
          </template>
          <p>Kündigungsdatum: {{ item.clubEndDate | formatDate }}</p>
          <p v-if="item.information">Informationen: {{ item.information }}</p>
        </v-tooltip>
      </template>

      <template v-slot:item.comment="{ item }">
        <v-text-field
          placeholder="Kommentar"
          v-model="item.comment"
        ></v-text-field>
      </template>
    </v-data-table>
    <v-card-actions class="justify-center">
      <v-btn @click.prevent="saveData" color="primary">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "EventParticipantsTable",
  props: ["parentEvent"],
  data() {
    return {
      selection: 0,
      courseData: null,
      informations: [
        { text: "ID", value: "id" },
        { text: "Kurstitel", value: "title" },
        { text: "Datum & Uhrzeit", value: "startDate" }
      ],
      statusOptions: [
        { color: "success", icon: "fas fa-check" },
        { color: "warning", icon: "fas fa-business-time" },
        { color: "error", icon: "fas fa-times" }
      ],
      active: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Anwesenheit", sortable: false, value: "status" },
        { text: "Kommentar", sortable: false, value: "comment", width: "50%" },
        { text: "Kontakt", sortable: false, value: "contact" }
      ],
      participants: [],
      type: "",
      availableEvents: [],
      pastDate: null,
      futureDate: null,
      event: this.parentEvent
    };
  },
  computed: {
    filteredParticipants() {
      return this.participants.filter(
        participant =>
          participant.event === null || participant.event === this.event.id
      );
    }
  },
  created() {
    this.courseData = this.$store.state.courses.all.find(
      course => course.id === this.parentEvent.course
    );

    this.getData();
  },
  methods: {
    changeEvent(item) {
      this.event = item;
      this.getData();
    },
    async getData() {
      (this.availableEvents = this.$store.state.events.all.filter(
        ({ archived, course }) => {
          if (
            course === this.parentEvent.course &&
            this.courseData !== undefined &&
            this.courseData.type !== "club"
          )
            return course;
          if (course === this.parentEvent.course && archived === false)
            return course;
        }
      )),
        (this.selection = this.availableEvents.findIndex(
          element => element.startDate === this.event.startDate
        ));
      try {
        const presence = await this.$store.dispatch(
          "events/getPresence",
          this.event.id
        );

        const bookings = this.$store.state.bookings.all.reduce(
          (result, value) => {
            if (value.deleted || value.course !== this.event.course) {
              return result;
            }
            const [eventStartDate, clubStartDate, clubEndDate] = [
              new Date(this.event.startDate),
              new Date(value.clubStartDate),
              value.clubEndDate
                ? new Date(value.clubEndDate).setUTCHours(23, 59, 59)
                : null
            ];
            let showUser = true;
            if (
              clubStartDate >= eventStartDate ||
              (clubEndDate && clubEndDate <= eventStartDate)
            ) {
              showUser = false;
            }

            if (showUser) {
              result.push(value);
            }

            return result;
          },
          []
        );
        this.participants = bookings.map(item => {
          const {
            id,
            firstname,
            lastname,
            customer,
            covidVaccinationStatus,
            covidVaccinationStatusDate,
            escortName,
            escortCovidStatus,
            escortCovidStatusDate
          } = this.$store.getters["participants/getParticipant"](
            item.participant
          );

          const { email, phone } = this.$store.getters["customers/getCustomer"](
            customer
          );

          const currentPresence = presence.find(e => e.booking === item.id);
          let coronaStatus = false;
          let escortStatus = false;

          const escortStatusDate = new Date(escortCovidStatusDate);
          const coronaStatusDate = new Date(covidVaccinationStatusDate);
          const eventDate = new Date(this.event.startDate);

          if (
            covidVaccinationStatus === "school" ||
            covidVaccinationStatus === "vaccin" ||
            covidVaccinationStatus === "recover"
          ) {
            coronaStatus = true;
          } else if (
            (covidVaccinationStatus === "vaccin+test" ||
              covidVaccinationStatus === "attest+test") &&
            coronaStatusDate.getTime() >
              eventDate.getTime() - 48 * 60 * 60 * 1000 &&
            coronaStatusDate.getTime() < eventDate.getTime()
          ) {
            coronaStatus = true;
          }

          if (
            escortCovidStatus === "vaccin" ||
            escortCovidStatus === "recover"
          ) {
            escortStatus = true;
          } else if (
            (escortCovidStatus === "vaccin+test" ||
              escortCovidStatus === "attest+test") &&
            escortStatusDate.getTime() >
              eventDate.getTime() - 48 * 60 * 60 * 1000 &&
            escortStatusDate.getTime() < eventDate.getTime()
          ) {
            escortStatus = true;
          }

          return {
            id,
            name: `${firstname} ${lastname}`,
            status: currentPresence?.status || "",
            comment: currentPresence?.comment || "",
            presenceId: currentPresence?.id || null,
            email,
            phone,
            clubEndDate: item.clubEndDate,
            booking: item.id,
            event: item.event,
            coronaStatus: coronaStatus,
            escortName: escortName,
            escortCovidStatus: escortStatus,
            escortCovidStatusDate: escortCovidStatusDate
          };
        });
      } catch (event) {
        // @TODO use sentry?
      }
    },
    async saveData() {
      await Promise.all(
        this.participants.map(
          async ({ status, comment, booking, presenceId }) => {
            try {
              presenceId
                ? await this.$store.dispatch("events/updatePresence", {
                    id: this.event.id,
                    presenceId,
                    booking,
                    status,
                    comment
                  })
                : await this.$store.dispatch("events/addPresence", {
                    id: this.event.id,
                    booking,
                    status,
                    comment
                  });
            } catch (error) {
              this.$store.dispatch("messages/add", {
                message: "Es ist ein Fehler aufgetreten",
                color: "error"
              });
            }
          }
        )
      );
      this.$store.dispatch("messages/add", {
        message: "Teilnehmer gespeichert",
        color: "success"
      });
    }
  }
};
</script>

<style scoped>
.opacity-5 {
  opacity: 0.5 !important;
}
</style>
