<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <v-card-title>
          {{ payment.date | formatDate }}
          <v-spacer></v-spacer>
          {{ payment.information }}
          <v-spacer></v-spacer>
          {{ payment.value | formatCurrency }}
        </v-card-title>
        <v-card-title></v-card-title>
        <BookingsTable
          :assign-mode="true"
          v-on:item-assign="assignPayment"
        ></BookingsTable>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BookingsTable from "@/components/booking/BookingsTable";

export default {
  name: "PaymentsAssign",
  components: { BookingsTable },
  props: ["id"],
  data: function() {
    return {
      payment: {}
    };
  },
  computed: {},
  methods: {
    assignPayment: function(item) {
      const vue = this;
      this.payment.booking = item.id;
      this.$store.dispatch("payments/update", this.payment).then(function() {
        vue.$router.push({ name: "payments" });
      });
    },
    loadPayment: function() {
      this.payment = Object.assign(
        {},
        this.$store.getters["payments/getById"](this.id)
      );
    }
  },
  created() {
    this.loadPayment();
  },
  mounted() {
    this.loadPayment();
  }
};
</script>

<style scoped></style>
