<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <CourseDefinitionsTable
          v-on:item-delete="onItemDelete"
          v-on:item-edit="onItemEdit"
          v-on:item-new="createNewItem"
        ></CourseDefinitionsTable>
        <v-dialog v-model="dialogEdit" max-width="1000">
          <v-form ref="courseDefinitionEdit">
            <v-card>
              <v-toolbar color="primary" dark>
                <v-toolbar-title v-if="this.courseDefinition.id > 0"
                  >Kursvorlage ändern</v-toolbar-title
                >
                <v-toolbar-title v-else>Kursvorlage anlegen</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogEdit = false"
                  ><v-icon>fal fa-times</v-icon></v-btn
                ></v-toolbar
              >
              <v-card-text>
                <CourseDefinitionEdit
                  v-model="courseDefinition"
                ></CourseDefinitionEdit>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="saveItem()">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CourseDefinitionsTable from "@/components/course/CourseDefinitionsTable";
import CourseDefinitionEdit from "@/components/course/CourseDefinitionEdit";

export default {
  name: "CourseDefinitions",
  components: {
    CourseDefinitionEdit,
    CourseDefinitionsTable
  },
  computed: {},
  methods: {
    createNewItem() {
      this.courseDefinition = {};
      this.dialogEdit = true;
    },
    saveItem() {
      if (!this.$refs.courseDefinitionEdit.validate()) {
        return this.$store.dispatch("messages/add", {
          message: "Bitter überprüfen Sie Ihre Eingabe.",
          color: "error"
        });
      }

      if (this.courseDefinition.id > 0) {
        this.$store
          .dispatch(
            "courseDefinitions/updateCourseDefinition",
            this.courseDefinition
          )
          .then(() => {
            this.dialogEdit = false;
            this.courseDefinition = {};
            this.$store.dispatch("messages/add", {
              message: "Kursvorlage wurde gespeichert",
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("messages/add", {
              message: "Kursvorlage konnte nicht gespeichert werden",
              color: "error"
            });
          });
      } else {
        this.$store
          .dispatch(
            "courseDefinitions/addCourseDefinition",
            this.courseDefinition
          )
          .then(() => {
            this.dialogEdit = false;
            this.courseDefinition = {};
            this.$store.dispatch("messages/add", {
              message: "Kursvorlage wurde gespeichert",
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("messages/add", {
              message: "Kursvorlage konnte nicht gespeichert werden",
              color: "error"
            });
          });
      }
    },
    onItemEdit(participantId) {
      this.loadCourseDefinition(participantId);
      this.dialogEdit = true;
    },
    onItemDelete(courseDefinition) {
      const vue = this;
      if (
        confirm(
          "Soll die Kursvorlage #" +
            courseDefinition.id +
            " " +
            courseDefinition.title +
            " wirklich gelöscht werden?"
        )
      ) {
        this.$store
          .dispatch(
            "courseDefinitions/deleteCourseDefinition",
            courseDefinition
          )
          .then(function() {
            vue.$store.dispatch("courseDefinitions/loadCourseDefinitions");
          });
      }
    },
    loadCourseDefinition(id) {
      this.courseDefinition = Object.assign(
        {},
        this.$store.getters["courseDefinitions/getCourseDefinition"](id)
      );
    }
  },
  data() {
    return {
      dialogEdit: false,
      courseDefinition: {}
    };
  }
};
</script>
