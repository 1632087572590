import ApiService from "../../common/api.service";

const state = {};

// getters
const getters = {};

// actions
const actions = {
  export(context, filter) {
    return new Promise(resolve => {
      ApiService.query("/export", { params: filter }).then(response => {
        resolve(response);
      });
    });
  }
};

// mutations
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
