<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6"
        ><v-text-field
          v-model="bathProxy.title"
          label="Name"
          :rules="[rules.required]"
        ></v-text-field
      ></v-col>
      <v-col cols="3"
        ><v-text-field
          v-model="bathProxy.shortcut"
          label="Kürzel"
          :rules="[rules.required]"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="5"
        ><v-text-field v-model="bathProxy.street" label="Straße"></v-text-field
      ></v-col>
      <v-col cols="2"
        ><v-text-field v-model="bathProxy.postal" label="PLZ"></v-text-field
      ></v-col>
      <v-col cols="5"
        ><v-text-field v-model="bathProxy.city" label="Ort"></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="4"
        ><v-text-field v-model="bathProxy.phone" label="Telefon"></v-text-field
      ></v-col>
      <v-col cols="4"
        ><v-text-field v-model="bathProxy.email" label="E-Mail"></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="4"
        ><v-text-field v-model="bathProxy.lat" label="LAT"></v-text-field
      ></v-col>
      <v-col cols="4"
        ><v-text-field v-model="bathProxy.lng" label="LNG"></v-text-field
      ></v-col>
      <v-col cols="4"
        ><v-switch
          v-model="bathProxy.showOnHomepage"
          label="Auf der Homepage anzeigen"
        ></v-switch
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="6"
        ><v-textarea v-model="bathProxy.ambience" label="Ambiente"></v-textarea
      ></v-col>
      <v-col cols="6"
        ><v-textarea v-model="bathProxy.avenue" label="Zugang"></v-textarea
      ></v-col>
      <v-col cols="12"
        ><tiptap-vuetify
          v-model="bathProxy.bathOrderNotice"
          :extensions="extensions"
        ></tiptap-vuetify
      ></v-col>
      <v-col cols="6"
        ><v-textarea
          v-model="bathProxy.description"
          label="Beschreibung"
        ></v-textarea
      ></v-col>
      <v-col cols="6"
        ><v-textarea
          v-model="bathProxy.information"
          label="Weitere Informationen"
        ></v-textarea
      ></v-col>
      <v-col cols="6"
        ><v-textarea
          v-model="bathProxy.meetingPoint"
          label="Treffpunkt"
        ></v-textarea
      ></v-col>
      <v-col cols="6"
        ><v-textarea
          v-model="bathProxy.parkingSpace"
          label="Parkplatz"
        ></v-textarea
      ></v-col>
      <v-col cols="6"
        ><v-textarea
          v-model="bathProxy.waitingArea"
          label="Wartebereich"
        ></v-textarea
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h4>Foto</h4>
        <v-avatar size="128">
          <img
            v-if="bathProxy.imageBinary !== undefined"
            :src="bathProxy.imageBinary"
            width="300"
          />
          <img
            v-else-if="bathProxy.image !== undefined"
            :src="apiUrl + '/' + bathProxy.image"
            width="300"
          />
        </v-avatar>
        <v-file-input
          v-model="bathProxy.image"
          hide-input
          label="Foto"
          @change="uploadImage"
        ></v-file-input>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="bathProxy.covidRequirements"
          :items="covidRequirements"
          label="Covid Anforderungen"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "BathEditForm",
  components: {
    TiptapVuetify
  },
  props: ["bath"],
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      bathValue: this.bath,
      rules: {
        required: v => !!v || "Pflichtfeld"
      },
      covidRequirements: [
        { text: "2G", value: "2g" },
        { text: "3G", value: "3g" }
      ]
    };
  },
  computed: {
    bathProxy: {
      set: function(value) {
        this.$emit("update:bath", value);
        this.bathValue = value;
      },
      get: function() {
        return this.bathValue;
      }
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    }
  },
  watch: {
    bath: function(bath) {
      this.bathValue = bath;
    }
  },
  methods: {
    uploadImage: function(file) {
      if (file === null) return;

      const vue = this;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener(
        "load",
        function() {
          vue.bathProxy.imageBinary = fileReader.result;
          vue.bathProxy.image = fileReader.result;
        },
        false
      );
    }
  }
};
</script>

<style scoped></style>
