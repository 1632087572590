import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import customers from "./modules/customers";
import participants from "@/store/modules/participants";
import courseDefinitions from "@/store/modules/courseDefinitions";
import courseDefinitionCategories from "@/store/modules/courseDefinitionCategories";
import courses from "@/store/modules/courses";
import baths from "@/store/modules/baths";
import bathLanes from "@/store/modules/bathLanes";
import instructors from "@/store/modules/instructors";
import events from "@/store/modules/events";
import bookings from "@/store/modules/bookings";
import invoices from "@/store/modules/invoices";
import config from "@/store/modules/config";
import payments from "@/store/modules/payments";
import coupons from "@/store/modules/coupons";
import messages from "@/store/modules/messages";
import roles from "@/store/modules/roles";
import location from "@/store/modules/location";
import settings from "@/store/modules/settings";
import search from "@/store/modules/search";
import waitingList from "@/store/modules/waitingList";
import exporter from "@/store/modules/export";
import log from "@/store/modules/log";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    customers,
    participants,
    courseDefinitions,
    courseDefinitionCategories,
    courses,
    baths,
    bathLanes,
    instructors,
    events,
    exporter,
    bookings,
    invoices,
    config,
    payments,
    coupons,
    messages,
    roles,
    location,
    search,
    waitingList,
    settings,
    log
  },
  strict: debug
});
