<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateProxy"
        :label="label"
        prepend-icon="fa-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="dateProxy"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: ["date", "rules", "label"],
  computed: {
    dateProxy: {
      set: function(value) {
        this.$emit("update:date", value);
        this.dateValue = value;
      },
      get: function() {
        return this.dateValue;
      }
    }
  },
  data: function() {
    return {
      menu: false,
      dateValue: this.date
    };
  },
  methods: {
    save(value) {
      this.$refs.menu.save(value);
    }
  },
  mounted() {
    this.dateValue = this.date;
  },
  watch: {
    date: function(value) {
      this.dateValue = value;
    }
  }
};
</script>

<style scoped></style>
