<template>
  <div>
    <v-dialog max-width="1000" v-model="showMergeDialog">
      <ParticipantsMerge
        v-if="showMergeDialog"
        @close="showMergeDialog = false"
        :participants="participantsTable"
      />
    </v-dialog>
    <Table
      :items="participantsTable"
      :search.sync="searchTerm"
      :headers="headers"
      :itemsPerPage="20"
    >
      <template v-slot:button>
        <v-btn class="mr-8" @click="exportParticipantData()"
          ><v-icon>fal fa-download</v-icon>Export</v-btn
        >
        <v-btn class="mr-8" @click="showMergeDialog = true"
          ><v-icon left>fal fa-users</v-icon>Teilnehmer zusammenführen</v-btn
        >
        <v-btn @click="$emit('new-participant')"
          ><v-icon left>fal fa-plus</v-icon> Neuer Teilnehmer</v-btn
        >
      </template>
      <template v-slot:item.dateOfBirth="{ item }">
        {{ formatDate(item.dateOfBirth) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip
          left
          v-if="
            (item.comment !== null && item.comment !== '') ||
              (item.performance !== null && item.performance !== '')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon>fas fa-info</v-icon></v-btn
            >
          </template>
          <p>Leistungsstand: {{ item.performance }}</p>
          <p>Kommentar: {{ item.comment }}</p>
        </v-tooltip>
        <v-btn v-else icon disabled><v-icon>fal fa-info</v-icon></v-btn>
        <v-btn
          icon
          :to="{
            name: 'customers',
            params: { searchCustomer: item.customerName }
          }"
          ><v-icon>fal fa-users</v-icon></v-btn
        >
        <v-btn
          icon
          :to="{
            name: 'bookings',
            params: { filterSearch: item.firstname + ' ' + item.lastname }
          }"
          ><v-icon>fal fa-shopping-cart</v-icon></v-btn
        >
        <v-btn
          icon
          :to="{ name: 'bookingEdit', params: { id: 0, participant: item.id } }"
          ><v-icon>fal fa-cart-plus</v-icon></v-btn
        >
        <v-btn icon v-on:click="$emit('participant-edit', item.id)"
          ><v-icon>fal fa-edit</v-icon></v-btn
        >
      </template>
    </Table>
  </div>
</template>

<script>
import ParticipantsMerge from "@/components/participant/ParticipantsMerge";
import moment from "moment/src/moment";
import Table from "@/components/utils/Table";
export default {
  name: "ParticipantsTable",
  props: ["searchParticipant"],
  components: { Table, ParticipantsMerge },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Geburtstag", value: "dateOfBirth" },
        { text: "Kunde", value: "customerName" },
        { text: "Aktion", value: "action" }
      ],
      participantsTable: [],
      showMergeDialog: false,
      search: { term: "" }
    };
  },
  computed: {
    participants: function() {
      return this.$store.state.participants.all;
    },
    customers: function() {
      return this.$store.state.customers.all;
    },
    searchTerm: {
      set: function(value) {
        this.search.term = value;
        this.$store.dispatch("search/set", {
          type: "participant",
          search: this.search
        });
      },
      get: function() {
        return this.$store.state.search.participant.term;
      }
    }
  },
  methods: {
    formatDate(dateTime) {
      return moment(new Date(dateTime)).format("DD.MM.YYYY");
    },
    getCustomerNameById(id) {
      const customer = this.$store.getters["customers/getCustomer"](id);
      if (customer === undefined) {
        throw new Error("Tried to get data from customer with id " + id);
      }

      return customer.firstname + " " + customer.lastname;
    },
    initiate: function() {
      this.participantsTable = this.participants.reduce((result, value) => {
        value.customerName = this.getCustomerNameById(value.customer);
        value.name = `${value.firstname} ${value.lastname}`;
        result.push(value);
        return result;
      }, []);
    },
    exportParticipantData() {
      this.$store.dispatch("participants/export").then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "Teilnehmer und Kundendaten .csv");
        a.click();
      });
    }
  },
  mounted() {
    this.initiate();
    if (this.searchParticipant !== undefined)
      this.searchTerm = this.searchParticipant;
  },
  created() {
    this.initiate();
    if (this.searchParticipant !== undefined)
      this.searchTerm = this.searchParticipant;
  },
  watch: {
    participants: function() {
      this.initiate();
      if (this.searchParticipant !== undefined)
        this.searchTerm = this.searchParticipant;
    }
  }
};
</script>
