<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <v-card-title>
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>fal fa-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-select
            clearable
            label="Schwimmlehrer"
            :items="instructors"
            :item-text="item => item.firstname + ' ' + item.lastname"
            item-value="id"
            v-model="filterInstructor"
          ></v-select>
          <v-spacer></v-spacer>
          <v-select
            clearable
            label="Schwimmbad"
            :items="baths"
            item-text="title"
            item-value="id"
            v-model="filterBath"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>fal fa-chevron-right</v-icon>
          </v-btn>
        </v-card-title>
        <v-calendar
          ref="calendar"
          :type="!$vuetify.breakpoint.mobile ? 'month' : 'day'"
          first-interval="6"
          interval-count="18"
          event-overlap-mode="stack"
          :weekdays="weekdays"
          :events="filteredEvents"
          v-model="calendar"
          @click:event="openChoiceModal"
        ></v-calendar>
      </v-card>
      <InstructorChoiceModal
        @closeModal="closeChoiceModal"
        :showChoiceModal="showChoiceModal"
        :participantsOnly="participantsOnly"
        :eventId="currentEvent"
      />
    </v-col>
  </v-row>
</template>

<script>
import InstructorChoiceModal from "@/components/instructor/InstructorChoiceModal";

export default {
  name: "InstructorCalendar",
  components: { InstructorChoiceModal },
  data() {
    return {
      participantsOnly: false,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      calendar: "",
      showChoiceModal: false,
      currentEvent: null,
      events: [],
      filter: {
        instructor: null,
        bath: null
      }
    };
  },
  computed: {
    filterInstructor: {
      set: function(value) {
        this.filter.instructor = value;
        this.$store.dispatch("search/set", {
          type: "instructorCalendar",
          search: this.filter
        });
      },
      get: function() {
        return this.filter.instructor;
      }
    },
    filterBath: {
      set: function(value) {
        this.filter.bath = value;
        this.$store.dispatch("search/set", {
          type: "instructorCalendar",
          search: this.filter
        });
      },
      get: function() {
        return this.filter.bath;
      }
    },
    filteredEvents: function() {
      let events = this.events;

      if (this.filterInstructor !== null) {
        events = events.filter(event =>
          event.instructor.includes(this.filterInstructor)
        );
      }

      if (this.filterBath !== null) {
        events = events.filter(event => event.bath === this.filterBath);
      }

      return events;
    },
    instructors: function() {
      return this.$store.state.instructors.all;
    },
    bathLanes: function() {
      return this.$store.getters["bathLanes/getAll"];
    },
    baths: function() {
      return this.$store.getters["baths/getAll"];
    }
  },
  methods: {
    getCourseTitle: function(courseId) {
      return this.$store.getters["courses/getById"](courseId)?.title;
    },
    getBathIdByLaneId: function(laneId) {
      return this.$store.getters["bathLanes/getById"](laneId).bath;
    },
    openChoiceModal: function(event) {
      if (event) {
        this.currentEvent = event?.event?.id;
      }
      const roles = this.$store.getters["auth/getRoles"];

      if (roles.every(role => role === "ROLE_INSTRUCTOR")) {
        this.participantsOnly = true;
      }
      this.showChoiceModal = true;
    },
    closeChoiceModal: function() {
      this.showChoiceModal = false;
    },
    initEvents: function() {
      let events = [];
      this.$store.state.events.all.forEach(event => {
        events.push({
          id: event.id,
          name: this.getCourseTitle(event.course),
          start: Date.parse(event.startDate),
          end: Date.parse(event.endDate),
          color: event.error ? "red" : "primary",
          timed: true,
          bath: this.getBathIdByLaneId(event.bathLane),
          instructor: event.instructor
        });
      });

      this.events = events;
    }
  },
  created() {
    this.filter = Object.assign(
      {},
      this.$store.state.search.instructorCalendar
    );
    if (this.filterInstructor === null)
      this.filterInstructor = this.$store.state.auth.user.id;
    this.initEvents();
  }
};
</script>

<style scoped></style>
