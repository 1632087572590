<template>
  <div>
    <v-dialog max-width="1000" v-model="showMergeDialog">
      <CustomersMerge
        v-if="showMergeDialog"
        @close="showMergeDialog = false"
        :customers="customers"
      />
    </v-dialog>
    <Table
      :items="customers"
      :search.sync="searchTerm"
      :headers="headers"
      :itemsPerPage="20"
    >
      <template v-slot:button>
        <v-btn class="mr-8" @click="exportCustomerData()"
          ><v-icon>fal fa-download</v-icon>Export</v-btn
        >
        <v-btn class="mr-8" @click="showMergeDialog = true"
          ><v-icon left>fal fa-users</v-icon>Kunden zusammenführen</v-btn
        >
        <v-btn @click="$emit('new-customer')"
          ><v-icon left>fal fa-plus</v-icon>Neuer Kunde</v-btn
        >
      </template>
      <template v-slot:item.street="{ item }">
        {{ item.street }} {{ item.streetNumber }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip left v-if="item.comment !== ''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon>fas fa-info</v-icon></v-btn
            >
          </template>
          <p>{{ item.comment }}</p>
        </v-tooltip>
        <v-btn v-else icon><v-icon disabled>fal fa-info</v-icon></v-btn>
        <v-btn icon :href="'mailto:' + item.email"
          ><v-icon>fal fa-envelope</v-icon></v-btn
        >
        <v-btn icon :href="'tel:' + item.phone"
          ><v-icon>fal fa-phone</v-icon></v-btn
        >
        <v-btn
          icon
          :to="{
            name: 'participants',
            params: { searchParticipant: item.firstname + ' ' + item.lastname }
          }"
          ><v-icon>fal fa-swimmer</v-icon></v-btn
        >
        <v-btn
          icon
          :to="{
            name: 'bookings',
            params: { filterSearch: item.firstname + ' ' + item.lastname }
          }"
          ><v-icon>fal fa-shopping-cart</v-icon></v-btn
        >
        <v-btn icon v-on:click="$emit('customer-edit', item.id)"
          ><v-icon>fal fa-edit</v-icon></v-btn
        >
        <!--<v-btn icon v-on:click="$emit('customer-delete', item)"><v-icon>fal fa-trash</v-icon></v-btn>-->
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/utils/Table";
import CustomersMerge from "@/components/customer/CustomersMerge";
export default {
  name: "CustomersTable",
  props: ["searchCustomer"],
  components: { Table, CustomersMerge },
  data() {
    return {
      showMergeDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Straße", value: "street" },
        { text: "Ort", value: "city" },
        { text: "E-Mail", value: "email" },
        { text: "Aktion", value: "action" }
      ],
      customers: [],
      search: { term: "" }
    };
  },
  computed: {
    customersStore: function() {
      return this.$store.state.customers.all;
    },
    searchTerm: {
      set: function(value) {
        this.search.term = value;
        this.$store.dispatch("search/set", {
          type: "customer",
          search: this.search
        });
      },
      get: function() {
        return this.$store.state.search.customer.term;
      }
    }
  },
  methods: {
    initiate: function() {
      this.customers = this.$store.state.customers.all.reduce(
        (result, value) => {
          value.name = `${value.firstname} ${value.lastname}`;
          result.push(value);
          return result;
        },
        []
      );
    },
    exportCustomerData() {
      this.$store.dispatch("customers/export").then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "Kundendaten.csv");
        a.click();
      });
    }
  },
  mounted() {
    this.initiate();
    if (this.searchCustomer !== undefined)
      this.searchTerm = this.searchCustomer;
  },
  created() {
    if (this.searchCustomer !== undefined)
      this.searchTerm = this.searchCustomer;
  },
  watch: {
    customersStore: function() {
      this.initiate();
    }
  }
};
</script>
