<template>
  <v-container fluid>
    <v-row class="items-center">
      <v-col v-for="instructor in instructors" :key="instructor.id" cols="3">
        <v-checkbox
          :rules="rules || defaultRules"
          v-model="instructorsProxy"
          @click="toggleCheckbox(instructor.id)"
          :value="instructor.id"
          :label="instructor.firstname + ' ' + instructor.lastname"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InstructorCheckboxes",
  props: ["instructorIds", "rules"],
  computed: {
    instructors: function() {
      return this.$store.state.instructors.all;
    },
    instructorsProxy: {
      set: function() {},
      get: function() {
        return this.instructorsValue;
      }
    }
  },
  data() {
    return {
      instructorsValue: [],
      defaultRules: [
        v => (v && v.length > 0) || "Bitte mindestens ein Element auswählen."
      ]
    };
  },
  methods: {
    toggleCheckbox: function(id) {
      const index = this.instructorsValue.indexOf(id);
      if (index !== -1) {
        this.instructorsValue.splice(index, 1);
      } else {
        this.instructorsValue.push(id);
      }
      this.$emit("update:instructorIds", this.instructorsValue);
    }
  },
  mounted() {
    this.instructorsValue = JSON.parse(JSON.stringify(this.instructorIds));
  },
  watch: {
    instructorIds: function(value) {
      this.instructorsValue = JSON.parse(JSON.stringify(value));
    }
  }
};
</script>

<style scoped>
.items-center {
  display: flex;
  align-items: center;
}

.v-input {
  margin-top: 0;
  padding-top: 0;
}
</style>
