<template>
  <v-form @submit.prevent="login" class="fill-height">
    <v-main class="fill-height">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title class="headline text-uppercase"
                  >Schwimmschulagentur Manager
                  {{ environment }}</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  data-cy="usernameTextField"
                  label="Benutzername"
                  name="username"
                  prepend-icon="fal fa-user"
                  type="text"
                  v-model="username"
                ></v-text-field>
                <v-text-field
                  data-cy="passwordTextField"
                  label="Passwort"
                  name="password"
                  prepend-icon="fal fa-key"
                  type="password"
                  v-model="password"
                ></v-text-field>
                <v-select
                  data-cy="locationSelect"
                  :items="locations"
                  item-value="id"
                  item-text="label"
                  label="Standort"
                  prepend-icon="fal fa-location"
                  v-model="location"
                ></v-select>
                <v-alert :value="alert" type="error"
                  >Bei der Kombination aus Benutzername und Passwort ist ein
                  Fehler aufgetreten</v-alert
                >
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" data-cy="loginButton"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-form>
</template>

<script>
import { version } from "../../package";

export default {
  data: () => ({
    locations: [],
    username: "",
    password: "",
    location: +sessionStorage.getItem("location") || 1,
    alert: false
  }),
  created: function() {
    this.getLocations();
  },
  watch: {
    location(val) {
      if (val) {
        sessionStorage.setItem("location", val);
      }
    }
  },
  methods: {
    getLocations: async function() {
      this.locations = await this.$store.getters["location/getAll"];
    },
    login: function() {
      this.$store
        .dispatch("auth/login", {
          username: this.username,
          password: this.password,
          location: this.location
        })
        .catch(() => {
          this.alert = true;
        });
    }
  },
  computed: {
    environment: function() {
      if (process.env.VUE_APP_TITLE === "") return " - " + version;
      return " - " + process.env.VUE_APP_TITLE;
    }
  }
};
</script>
