<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <CustomersTable
        :searchCustomer="searchCustomer"
        @customer-edit="onCustomerEdit"
        @customer-delete="onCustomerDelete"
        @new-customer="onCustomerNew"
      />
      <v-dialog v-model="dialogNewUser" max-width="800">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Kunden anlegen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogNewUser = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-form ref="customer">
              <CustomersEdit v-model="customer"></CustomersEdit>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="saveCustomer()">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CustomersTable from "@/components/customer/CustomersTable";
import CustomersEdit from "@/components/customer/CustomersEdit";

export default {
  name: "Bookings",
  props: ["searchCustomer"],
  components: {
    CustomersEdit: CustomersEdit,
    CustomersTable
  },
  computed: {},
  methods: {
    saveCustomer() {
      if (this.$refs.customer.validate()) {
        if (this.customer.id === 0 || this.customer.id === undefined) {
          this.$store
            .dispatch("customers/addCustomer", this.customer)
            .then(() => {
              this.dialogNewUser = false;
              this.customer = {};
            });
          return;
        }

        if (this.customer.id > 0) {
          this.$store.dispatch("customers/update", this.customer).then(() => {
            this.dialogNewUser = false;
            this.customer = {};
          });
          return;
        }
      }
    },
    onCustomerNew() {
      this.customer = Object.assign({});
      this.dialogNewUser = true;
    },
    onCustomerEdit(customerId) {
      this.customer = Object.assign(
        {},
        this.$store.getters["customers/getCustomer"](customerId)
      );
      this.dialogNewUser = true;
    },
    onCustomerDelete(customer) {
      if (
        confirm(
          "Soll der Kunde #" +
            customer.id +
            " " +
            customer.firstname +
            " " +
            customer.lastname +
            " wirklich gelöscht werden?"
        )
      ) {
        this.$store.dispatch("customers/delete");
      }
    }
  },
  data() {
    return {
      dialogNewUser: false,
      customer: {}
    };
  }
};
</script>
