import JwtService from "../../common/jwt.service";
import ApiService from "../../common/api.service";
import router from "@/router";
import * as Sentry from "@sentry/vue";

const state = {
  isAuthenticated: false,
  user: null,
  location: null,
  roles: [],
  errors: {}
};

// getters
const getters = {
  currentLocation(state) {
    return state.location;
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getRoles(state) {
    return state.roles;
  },
  checkRole: state => role => {
    if (state.roles === undefined) return false;
    if (typeof state.roles === "object")
      return Object.values(state.roles).indexOf(role) !== -1;
    return state.roles.indexOf(role) !== -1;
  }
};

// actions
const actions = {
  async login(context, credentials) {
    try {
      context.commit("PURGE_AUTH");
      ApiService.clearHeader();
      const response = await ApiService.post("login_check", {
        username: credentials.username,
        password: credentials.password,
        location: credentials.location
      });
      JwtService.saveToken(response.data.token);
      const data = JwtService.getData();
      if (data.isActive === false) {
        //@FIXME errors is object
        context.commit("SET_ERROR", "Der Benutzer wurde deaktiviert");
        context.commit("PURGE_AUTH");
        return Promise.reject();
      }
      ApiService.setHeader();
      context.commit("SET_AUTH", data);
      if (router.history.current.path !== "/")
        router.push({ name: "dashboard" });
      return response;
    } catch (error) {
      context.commit("SET_ERROR", error);
      context.commit("PURGE_AUTH");
      return Promise.reject();
    }
  },
  logout(context) {
    JwtService.destroyToken();
    ApiService.clearHeader();
    context.commit("PURGE_AUTH");
  },
  checkAuth(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      /*ApiService.get("user")
                .then(({ data }) => {
                    context.commit('SET_AUTH', data.user);
                })
                .catch(({ response }) => {
                    context.commit('SET_ERROR', response.data.errors);
                });*/
    } else {
      context.commit("PURGE_AUTH");
    }
  }
};

// mutations
const mutations = {
  SET_ERROR(state, error) {
    state.errors = error;
  },
  SET_AUTH(state, data) {
    state.isAuthenticated = true;
    state.user = {
      username: data.username,
      id: data.id,
      shortcut: data.shortcut
    };
    state.location = data.location;
    state.roles = data.roles;
    state.errors = {};
    Sentry.setUser({ id: data.id, username: data.username, email: data.email });
  },
  PURGE_AUTH(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.roles = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
