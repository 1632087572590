<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <EventsTable
          @new-event="addNewItem"
          @item-edit="editItem"
          @item-delete="deleteItem"
          :bathLanes="getBathLanes"
          :filter-bath="filterBath"
          :filter-course="filterCourse"
          :filter-upcoming="filterUpcoming"
        >
          <v-spacer></v-spacer>
          <v-autocomplete
            :items="coursesAutocomplete"
            v-model="filterCourse"
            label="Kurs"
            clearable
          ></v-autocomplete>
          <v-spacer></v-spacer>
          <v-autocomplete
            :items="getBathLanes"
            v-model="filterBath"
            label="Bad"
            clearable
          ></v-autocomplete>
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="filterUpcoming"
            label="Nur kommende Termine anzeigen"
          ></v-checkbox>
          <v-spacer></v-spacer>
        </EventsTable>
      </v-card>
      <v-dialog max-width="1000" v-model="showModal">
        <EventEdit
          :bathLanes="getBathLanes"
          :courses="courses"
          @close="closeDialog"
          v-if="type === 'edit'"
          :ev="event"
        />
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import EventsTable from "@/components/event/EventsTable";
import EventEdit from "@/components/event/EventEdit";

export default {
  name: "Events",
  props: ["eventId", "parentType"],
  components: {
    EventsTable,
    EventEdit
  },
  data() {
    return {
      showModal: false,
      event: this.eventId
        ? this.$store.state.events.all.find(item => item.id === this.eventId)
        : {},
      type: this.parentType,
      filterBath: null,
      filterCourse: null,
      filterInstructor: null,
      filterUpcoming: true
    };
  },
  computed: {
    baths: function() {
      return this.$store.getters["baths/getAll"];
    },
    bathLanes: function() {
      return this.$store.getters["bathLanes/getAll"];
    },
    getBathLanes() {
      let bathLanes = [];
      this.bathLanes.forEach(bathLane => {
        const bathTitle = this.baths.find(bath => bath.id === bathLane.bath)
          .title;
        bathLanes.push({
          text: bathTitle + " - " + bathLane.title,
          value: bathLane.id
        });
      });
      return bathLanes;
    },
    courses: function() {
      return this.$store.state.courses.all;
    },
    coursesAutocomplete: function() {
      let courses = [];
      this.courses.forEach(item => {
        courses.push({
          text: item.id + " " + item.title,
          value: item.id
        });
      });
      return courses;
    }
  },

  methods: {
    editItem(event) {
      this.type = "edit";
      this.event = { ...event };
      this.openDialog();
    },
    deleteItem(event) {
      if (confirm("Soll der Termin wirklich gelöscht werden?")) {
        this.$store.dispatch("events/delete", event);
      }
    },
    addNewItem() {
      this.type = "edit";
      this.event = {};
      this.openDialog();
    },
    openDialog() {
      this.showModal = true;
    },
    closeDialog() {
      this.type = null;
      this.showModal = false;
    }
  },
  mounted() {
    if (["edit", "participants"].includes(this.type)) {
      this.openDialog();
    }
  }
};
</script>

<style scoped></style>
