import { render, staticRenderFns } from "./ParticipantEdit.vue?vue&type=template&id=01372112&v-bind%3Avalue=value&v-on%3Ainput=%24emit('input'%2C%20%24event.target.value)"
import script from "./ParticipantEdit.vue?vue&type=script&lang=js"
export * from "./ParticipantEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports