<template>
  <v-dialog v-model="showDialogProxy" max-width="1000" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Kurs benachrichtigen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialogProxy = false"
          ><v-icon>fal fa-times</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-list>
          <v-list-item v-for="customer in customers" :key="customer.bookingId">
            <v-list-item-action>
              <v-checkbox
                color="primary"
                :disabled="!/^(0|\+49)1[5-7]{1}[0-9]{8,9}/.test(customer.phone)"
                v-model="customer.sendNotification"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-title
              >{{ customer.firstname }} {{ customer.lastname }} #{{
                customer.id
              }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ customer.phone }}</v-list-item-subtitle>
            <v-list-item-subtitle
              ><template v-if="customer.event !== null">{{
                getEvent(customer.event).startDate | formatDate
              }}</template></v-list-item-subtitle
            >
            <v-list-item-icon>
              <v-progress-circular
                v-if="customer.requestStatus === 'start'"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon
                v-else-if="customer.requestStatus === 'finished'"
                color="success"
                >fal fa-check</v-icon
              >
              <v-icon
                v-else-if="customer.requestStatus === 'error'"
                color="error"
                >fal fa-times</v-icon
              >
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-textarea
          label="Nachricht"
          v-model="message"
          v-on:keydown="fixUmlaute()"
          counter="160"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="sendNotification">SMS senden</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CourseNotification",
  props: ["course", "showDialog"],
  data() {
    return {
      customers: [],
      showDialogValue: false,
      message: ""
    };
  },
  computed: {
    showDialogProxy: {
      set: function(value) {
        this.$emit("update:showDialog", value);
        this.showDialogValue = value;
      },
      get: function() {
        return this.showDialogValue;
      }
    }
  },
  methods: {
    sendNotification() {
      if (this.customers.length === 0 || this.message === "") return;
      this.customers.forEach(item => {
        if (item.sendNotification === true) {
          this.$store
            .dispatch("customers/sendSms", {
              id: item.id,
              message: this.message
            })
            .then(() => {
              item.requestStatus = "finished";
            })
            .catch(() => {
              item.requestStatus = "error";
            });
          item.requestStatus = "start";
        }
      });
    },
    loadCustomersByCourse() {
      const vue = this;
      const course = this.course;
      const bookings = this.$store.getters["bookings/getByCourseId"](course.id);

      this.customers = bookings.reduce((result, booking) => {
        if (
          course.type === "club" &&
          booking.clubEndDate !== null &&
          new Date(booking.clubEndDate) < new Date()
        ) {
          return result;
        }

        const participant = vue.$store.getters["participants/getParticipant"](
          booking.participant
        );

        if (participant) {
          const customer = vue.$store.getters["customers/getCustomer"](
            participant.customer
          );

          if (customer) {
            result.push(
              Object.assign(
                {
                  bookingId: booking.id,
                  event: booking.event,
                  requestStatus: null,
                  sendNotification: false
                },
                customer
              )
            );
          }
        }
        return result;
      }, []);

      this.customers.sort((a, b) => {
        if (a.event > b.event) return 1;
        if (a.event > b.event) return -1;
        return 0;
      });
    },
    fixUmlaute: function() {
      this.message = this.message.replace(/ä/g, "ae");
      this.message = this.message.replace(/ö/g, "oe");
      this.message = this.message.replace(/ü/g, "ue");
      this.message = this.message.replace(/ß/g, "ss");
      this.message = this.message.replace(/Ä/g, "AE");
      this.message = this.message.replace(/Ö/g, "OE");
      this.message = this.message.replace(/Ü/g, "UE");
    },
    getEvent(id) {
      return this.$store.getters["events/getById"](id);
    }
  },
  watch: {
    showDialog() {
      this.loadCustomersByCourse();
    },
    course() {
      this.showDialogProxy = true;
      this.loadCustomersByCourse();
    }
  },
  created() {
    this.showDialogValue = this.showDialog;
  }
};
</script>
