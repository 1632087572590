<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <CourseTable
          v-on:item-delete="onItemDelete"
          v-on:item-edit="onItemEdit"
          v-on:item-new="onItemNew"
          v-on:item-notification="showCourseNotification"
        ></CourseTable>
        <v-dialog v-model="dialogEdit" max-width="1000">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Kurs ändern</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogEdit = false"
                ><v-icon>fal fa-times</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-form ref="course">
                <h2 class="mt-4">Kurszeiten</h2>
                <v-row>
                  <v-col
                    cols="6"
                    v-for="(time, index) in course.times"
                    :key="index"
                  >
                    <v-radio-group v-model="time.weekday" row>
                      <v-radio
                        v-for="weekday in weekdays"
                        :key="weekday.value"
                        :label="weekday.text"
                        :value="weekday.value"
                      ></v-radio>
                    </v-radio-group>
                    <v-time-picker
                      class="mt-4"
                      format="24hr"
                      landscape
                      v-model="time.time"
                      :allowed-minutes="getFiveMinuteSteps"
                    ></v-time-picker>
                    <v-text-field v-model="time.time" readonly></v-text-field>
                  </v-col>
                </v-row>
                <h2 class="mt-4">Trainer</h2>
                <InstructorCheckboxes
                  :instructor-ids.sync="course.instructor"
                ></InstructorCheckboxes>
                <v-row>
                  <v-col cols="3"
                    ><v-text-field
                      label="Maximale Auslastung"
                      v-model="course.capacity"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      label="Preis pro Club/Kurs"
                      v-model="course.price"
                      prefix="EUR"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      label="Preis pro Einheit"
                      v-model="course.priceUnit"
                      prefix="EUR"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3" v-if="course.type === 'club'"
                    ><DatePicker
                      :date.sync="course.endDate"
                      label="Clubende"
                    ></DatePicker
                  ></v-col>
                </v-row>
                <v-textarea
                  label="Kommentar"
                  v-model="course.comment"
                ></v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="saveItem()">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
    <CourseNotification
      :show-dialog.sync="dialogNotification"
      :course="notificationItem"
    ></CourseNotification>
  </v-row>
</template>

<script>
import CourseTable from "@/components/course/CourseTable";
import InstructorCheckboxes from "@/components/instructor/InstructorCheckboxes";
import DatePicker from "@/components/picker/DatePicker";
import CourseNotification from "@/components/course/CourseNotification";

export default {
  name: "Courses",
  components: {
    CourseNotification,
    DatePicker,
    InstructorCheckboxes,
    CourseTable
  },
  computed: {},
  methods: {
    showCourseNotification(course) {
      this.dialogNotification = true;
      this.notificationItem = course;
    },
    getFiveMinuteSteps: minutes => minutes % 5 === 0,
    saveItem() {
      const vue = this;
      this.$store.dispatch("courses/update", this.course).then(function() {
        vue.$store.dispatch("messages/add", {
          message: "Kurs wurde gespeichert",
          color: "success"
        });
        vue.dialogEdit = false;
      });
    },
    onItemEdit(id) {
      this.dialogEdit = true;
      const course = this.$store.state.courses.all.find(item => item.id === id);
      this.course = Object.assign({}, course);
    },
    onItemNew() {
      this.$router.push({ name: "courseGenerator" });
    },
    onItemDelete(course) {
      if (
        confirm(
          "Soll der Kurs #" +
            course.id +
            " " +
            course.title +
            " wirklich gelöscht werden?"
        )
      ) {
        this.$store.dispatch("courses/delete", course);
      }
    }
  },
  data() {
    return {
      notificationItem: null,
      dialogNotification: false,
      dialogEdit: false,
      course: {},
      weekdays: [
        { text: "Montag", value: 1 },
        { text: "Dienstag", value: 2 },
        { text: "Mittwoch", value: 3 },
        { text: "Donnerstag", value: 4 },
        { text: "Freitag", value: 5 },
        { text: "Samstag", value: 6 },
        { text: "Sonntag", value: 0 }
      ]
    };
  }
};
</script>
