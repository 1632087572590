<template>
  <v-card class="pb-4" flat>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Teilnehmer zusammenführen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"><v-icon>fal fa-times</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <h2 class="mt-4">Von Teilnehmer</h2>
            <v-autocomplete
              v-model="secondaryParticipant"
              :items="participants"
              item-value="id"
              :item-text="
                ({ id, name, customerName }) =>
                  `#${id}: ${name} (${customerName})`
              "
            />
          </v-col>
          <v-col>
            <h2 class="mt-4">Auf Teilnehmer</h2>
            <v-autocomplete
              v-model="primaryParticipant"
              :items="participants"
              item-value="id"
              :item-text="
                ({ id, name, customerName }) =>
                  `#${id}: ${name} (${customerName})`
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn @click.prevent="mergeData" color="primary">
        Zusammenführen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CustomersMerge",
  components: {},
  props: ["participants"],
  data: function() {
    return {
      primaryParticipant: null,
      secondaryParticipant: null,
    };
  },
  computed: {},
  methods: {
    mergeData: async function() {
      this.$store.dispatch("messages/del");
      if (!this.primaryParticipant || !this.secondaryParticipant) {
        return this.$store.dispatch("messages/add", {
          message: "Bitte Teilnehmer auswählen",
          color: "warning",
        });
      } else if (this.primaryParticipant === this.secondaryParticipant) {
        return this.$store.dispatch("messages/add", {
          message: "Teilnehmer müssen sich unterscheiden",
          color: "warning",
        });
      }
      if (
        !confirm(
          `Wollen sie wirkich alle Daten von #${this.secondaryParticipant} auf #${this.primaryParticipant} übertragen?\nHierbei wird #${this.secondaryParticipant} gelöscht.`
        )
      ) {
        return this.$emit("close");
      }
      const bookings = this.$store.state.bookings.all?.filter(
        ({ participant }) => participant === this.secondaryParticipant
      );

      await Promise.all([
        ...bookings.map(async (booking) => {
          booking = { ...booking };
          booking.participant = this.primaryParticipant;
          try {
            await this.$store.dispatch(`bookings/update`, booking);
          } catch (error) {
            this.$store.dispatch("messages/add", {
              message: "Es ist ein Fehler aufgetreten",
              color: "error",
            });
          }
        }),
      ]);
      const participant = this.$store.getters["participants/getParticipant"](
        this.secondaryParticipant
      );
      await this.$store.dispatch(`participants/delete`, participant);
      this.$emit("close");
      this.$store.dispatch("messages/add", {
        message: "Teilnehmer wurden erfolgreich zusammengeführt",
        color: "success",
      });
    },
  },
};
</script>
