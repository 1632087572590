<template>
  <v-list multiple>
    <v-list-item v-for="item in courses" :key="item.id">
      <v-list-item-avatar :size="50">
        <CourseWorkloadCircle
          :size="50"
          :capacity-used="item.capacityUsed"
          :capacity-min="item.capacityMin"
          :capacity="item.capacity"
        ></CourseWorkloadCircle>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          ><CourseTitle :course="item"></CourseTitle
        ></v-list-item-title>
        <v-list-item-subtitle
          >Startdatum: {{ item.startDate }}</v-list-item-subtitle
        >
        <v-list-item-subtitle
          >Ort: {{ getBathByLaneId(item.bathLane).title }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn :to="{ name: 'courses', params: { filterSearch: item.id } }"
          >Details</v-btn
        >
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import CourseWorkloadCircle from "@/components/course/CourseWorkloadCircle";
import CourseTitle from "@/components/course/CourseTitle";

export default {
  name: "CourseOverview",
  components: {
    CourseTitle,
    CourseWorkloadCircle
  },
  computed: {
    courses: function() {
      let courses = [];
      const now = new Date();
      courses = this.$store.state.courses.all?.filter(course => {
        if (
          course.type !== "club" &&
          new Date(
            course.startDate
              .split(".")
              .reverse()
              .join("-")
          ) > now
        )
          return course;
      });
      return courses.splice(0, 5);
    },
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAll"];
    },
    baths: function() {
      return this.$store.getters["baths/getAll"];
    },
    bathLanes: function() {
      return this.$store.getters["bathLanes/getAll"];
    }
  },
  methods: {
    getBathByLaneId: function(id) {
      const bathLane = this.bathLanes.find(bathLane => bathLane.id === id);
      if (bathLane === undefined) return { title: "" };

      const bath = this.baths.find(item => item.id === bathLane.bath);
      if (bath === undefined) return {};

      return this.baths.find(item => item.id === bathLane.bath);
    }
  }
};
</script>
