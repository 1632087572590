import moment from "moment/src/moment";
import Vue from "vue";
moment.locale("de");

const weekdays = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
  7: "Sonntag"
};

Vue.filter("formatDateWeekday", function(value) {
  if (value) {
    return weekdays[moment(String(value)).format("E")];
  }
});

Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY HH:mm");
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});

Vue.filter("formatDateEn", function(value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});

Vue.filter("formatYear", function(value) {
  if (value) {
    return moment(String(value)).format("YYYY");
  }
});

Vue.filter("formatTime", function(value) {
  if (value) {
    return moment(String(value)).format("HH:mm");
  }
});

Vue.filter("formatCurrency", function(value) {
  return (
    parseFloat(value)
      .toFixed(2)
      .replace(".", ",") + " EUR"
  );
});
