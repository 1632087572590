<template>
  <v-card flat>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-form ref="bookingEdit" @submit.prevent="saveBooking()">
              <v-card>
                <v-card-title>Buchung</v-card-title>
                <BookingEditForm
                  :id="id"
                  :course-id="course"
                  :booking.sync="booking"
                ></BookingEditForm>
                <v-card-actions>
                  <v-btn
                    v-if="booking.event === null"
                    :to="{ name: 'bookings' }"
                    >Zurück</v-btn
                  >
                  <v-btn v-else :to="{ name: 'bookingsSingle' }">Zurück</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="saveBooking" color="primary" class="mr-2"
                    >Speichern</v-btn
                  >
                  <v-menu offset-x v-if="id > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                      >
                        E-Mail
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="sendConfirmationMail"
                        >Bestätigung</v-list-item
                      >
                      <v-list-item @click="sendBalanceInformation"
                        >Kontostand</v-list-item
                      >
                      <!--<v-list-item @click="sendCoronaStatusUpdate"
                        >Corona Status Update</v-list-item
                      >-->
                      <!--<v-list-item @click="alert('Send')">Erinnerung</v-list-item>
                        <v-list-item>Feedback</v-list-item>
                        <v-list-item v-if="booking.type === 'club'">Kündigung</v-list-item>-->
                      <v-list-item
                        v-if="booking.type === 'hybrid-course'"
                        @click="sendHybridCourseNotification"
                        >Hybridkurs Link</v-list-item
                      >
                    </v-list>
                  </v-menu>
                  <v-btn
                    color="error"
                    @click="cancelBooking"
                    v-if="id > 0 && booking.paymentAmount === 0"
                    >Stornieren</v-btn
                  >
                  <!--<v-btn color="error" @click="cancelClub" v-if="id > 0 && booking.type === 'club'">Kündigen</v-btn>-->
                </v-card-actions>
              </v-card>
            </v-form>
            <v-card class="mt-8">
              <v-card-title>Weitere Buchungen</v-card-title>
              <BookingListParticipant
                class="mt-8"
                :participant="booking.participant"
              ></BookingListParticipant>
            </v-card>
          </v-col>
          <v-col cols="3" v-if="booking.id > 0">
            <v-card>
              <v-card-title>Saldo</v-card-title>
              <PaymentBalance
                :booking="booking"
                source="booking"
              ></PaymentBalance>
            </v-card>
            <v-card class="mt-8">
              <v-card-title>Zahlungen & Rechnungen</v-card-title>
              <PaymentHistory
                :booking="booking"
                source="booking"
              ></PaymentHistory>
            </v-card>
          </v-col>
          <v-col cols="3" v-if="booking.id > 0">
            <v-card>
              <v-card-title>Anwesenheiten</v-card-title>
              <Presence :booking="booking"></Presence>
            </v-card>

            <v-card class="mt-8">
              <v-card-title>Log</v-card-title>
              <LogListItem :items="logEntries"></LogListItem>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import BookingEditForm from "@/components/booking/BookingEditForm";
import PaymentBalance from "@/components/payment/PaymentBalance";
import PaymentHistory from "@/components/payment/PaymentHistory";
import BookingListParticipant from "@/components/booking/BookingListParticipant";
import convertToFloat from "@/utils/convertToFloat";
import Presence from "@/components/presence/Presence";
import LogListItem from "@/components/log/LogListItem.vue";
export default {
  name: "BookingEdit",
  components: {
    LogListItem,
    Presence,
    BookingListParticipant,
    PaymentHistory,
    PaymentBalance,
    BookingEditForm
  },
  props: ["id", "course", "participant"],
  data() {
    return {
      booking: {},
      logEntries: []
    };
  },
  computed: {
    courses: function() {
      return this.$store.getters["courses/getAll"];
    }
  },
  methods: {
    initiate: function() {
      const bookingData = this.$store.getters["bookings/getById"](this.id);
      this.booking = {
        clubStartDate: null,
        clubEndDate: null,
        price: null,
        ...bookingData,
        oldPrice: bookingData?.price ?? null
      };

      if (this.id !== 0) return;

      const now = new Date();
      this.booking.date = now.toISOString();
      if (this.course > 0) {
        this.booking.course = this.course;
        this.booking.price = this.courses.find(
          item => item.id === this.booking.course
        ).price;
      }
      if (this.participant > 0) {
        this.booking.participant = this.participant;
      }
    },
    sendConfirmationMail: async function() {
      try {
        await this.$store.dispatch(
          "bookings/sendConfirmationMail",
          this.booking
        );
        this.$store.dispatch("messages/add", {
          message: "Bestätigungsmail an Kunden versandt",
          color: "success"
        });
      } catch (e) {
        this.$store.dispatch("messages/add", {
          message: "Bestätigungsmail an Kunden konnte nicht versandt werden",
          color: "error"
        });
      }
    },
    sendBalanceInformation: async function() {
      try {
        await this.$store.dispatch(
          "bookings/sendBalanceInformation",
          this.booking
        );
        this.$store.dispatch("messages/add", {
          message: "Kontostand wurde an Kunden versandt",
          color: "success"
        });
      } catch (e) {
        this.$store.dispatch("messages/add", {
          message: "Kontostand an Kunden konnte nicht versandt werden",
          color: "error"
        });
      }
    },
    sendHybridCourseNotification: async function() {
      try {
        await this.$store.dispatch(
          "bookings/sendHybridCourseNotification",
          this.booking
        );
        this.$store.dispatch("messages/add", {
          message: "Link wurde an Kunden versandt",
          color: "success"
        });
      } catch (e) {
        this.$store.dispatch("messages/add", {
          message: "Link an Kunden konnte nicht versandt werden",
          color: "error"
        });
      }
    },
    sendCoronaStatusUpdate: async function() {
      try {
        await this.$store.dispatch(
          "bookings/sendCoronaStatusUpdate",
          this.booking
        );
        this.$store.dispatch("messages/add", {
          message: "Corona Status Abfrage wurde an Kunden versandt",
          color: "success"
        });
      } catch (e) {
        this.$store.dispatch("messages/add", {
          message:
            "Corona Status Abfrage konnte nicht an Kunden versandt werden",
          color: "error"
        });
      }
    },
    cancelBooking: async function() {
      if (
        confirm(
          `Soll die Buchung #${this.booking.id} wirklich storniert werden?`
        )
      ) {
        await this.$store.dispatch("bookings/delete", this.booking);
        this.goBack();
      }
    },
    cancelClub: async function() {
      if (
        confirm(
          `Soll die Club Buchung #${this.booking.id} zum ${this.booking.clubCancelDate} storniert werden?`
        )
      ) {
        await this.$store.dispatch("bookings/delete", this.booking);
        this.goBack();
      }
    },
    saveBooking: async function() {
      this.$store.dispatch("messages/del");
      if (!this.$refs.bookingEdit.validate()) {
        return this.$store.dispatch("messages/add", {
          message: "Bitter überprüfen Sie Ihre Eingabe.",
          color: "error"
        });
      }

      if (this.booking.id > 0) {
        if (
          this.booking.type !== "club" &&
          this.booking.price &&
          this.booking.oldPrice &&
          this.booking.price.toString() !== this.booking.oldPrice.toString()
        ) {
          const price = convertToFloat(this.booking.price);
          this.booking.price = price;
          this.booking.oldPrice = price;
          await this.$store.dispatch("bookings/update", this.booking);
          const invoice = this.$store.getters["invoices/getByBooking"](
            this.booking.id
          )?.[0];
          if (invoice) {
            await this.$store.dispatch("invoices/delete", invoice);
            await this.$store.dispatch("bookings/createInvoice", this.booking);
          }
        } else {
          await this.$store.dispatch("bookings/update", this.booking);
        }
      } else {
        await this.$store.dispatch("bookings/add", this.booking);
      }
      this.goBack("success", "Buchung erfolgreich gespeichert");
    },
    goBack: async function(color, message) {
      await this.$store.dispatch("bookings/getAll");
      await this.$store.dispatch("invoices/getAll");
      await this.$store.dispatch("payments/getAll");
      if (color && message) {
        this.$store.dispatch("messages/add", {
          message,
          color
        });
      }

      this.$router.push({ name: "bookings" });
    }
  },
  created: function() {
    this.initiate();
  },
  mounted() {
    this.$store.dispatch("log/getByBookingId", this.id).then(response => {
      this.logEntries = response;
    });
  }
};
</script>

<style scoped>
.text-white {
  color: white;
}
</style>
