<template v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
  <v-container>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="value.gender"
          label="Geschlecht"
          row
          :rules="[rules.requiredRadio]"
        >
          <v-radio value="male" label="Männlich">Männlich</v-radio>
          <v-radio value="female" label="Weiblich">Weiblich</v-radio>
          <v-radio value="divers" label="Divers">Divers</v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.firstname"
          label="Vorname"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="value.lastname"
          label="Nachname"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-text-field
          v-model="value.street"
          label="Straße"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="value.streetNumber"
          label="Hausnummer"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="value.postal"
          label="Postleitzahl"
          :rules="[rules.required, rules.postal]"
        ></v-text-field>
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="value.city"
          label="Ort"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.phone"
          label="Telefon"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="value.email"
          label="E-Mail"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea v-model="value.comment" label="Kommentar"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CustomersEdit",
  components: {},
  props: ["value"],
  computed: {},
  methods: {},
  data: () => ({
    rules: {
      requiredRadio: v => (v && !!v) || "Pflichtfeld",
      required: v => !!v || "Pflichtfeld",
      postal: v =>
        /^\d{5}$/.test(v) || "Bitte eine gültige Postleitzahl eingeben",
      email: v => /.+@.+\..+/.test(v) || "Ungültige E-Mailadresse"
    }
  })
};
</script>
