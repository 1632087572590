<template>
  <v-card :class="isInstructorActive(item) ? 'active' : ''">
    <v-img
      :src="apiUrl + item.image || '/img/example.png'"
      height="300px"
      v-if="item.image"
    />
    <v-sheet
      v-else
      color="grey lighten-3"
      height="300px"
      class="d-flex align-center justify-center"
    >
      <div class="text-white text-h1">
        {{ getShortname(item) }}
      </div>
    </v-sheet>
    <v-card-title>{{ item.firstname }} {{ item.lastname }}</v-card-title>
    <v-card-text class="pb-2">
      <a :href="`mailto:${item.email}`">{{ item.email }}</a
      ><br />
    </v-card-text>
    <v-card-text class="pt-0">
      <a :href="`tel:${item.phone}`">{{ item.phone }}</a
      ><br />
    </v-card-text>

    <v-divider class="mx-4" />
    <v-card-actions>
      <v-tooltip bottom v-if="checkRole('ROLE_ADMIN')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            {{ item.lastLogin | formatDate }}
          </v-btn>
        </template>
        Letzter Login:<br />
        {{ item.lastLogin | formatDateTime }}<br />
        <br />
        Letzte Aktivität:<br />
        {{ item.lastActivity | formatDateTime }}
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-auto"
        icon
        v-on:click="$parent.$parent.$emit('edit-item', item)"
        ><v-icon>fal fa-edit</v-icon></v-btn
      >
      <v-btn icon v-on:click="$parent.$parent.$emit('delete-item', item)"
        ><v-icon>fal fa-trash</v-icon></v-btn
      >
      <v-btn icon v-on:click="$parent.$parent.$emit('resend-password', item)"
        ><v-icon>fal fa-key</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "UserCard",
  props: ["item"],
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL + "/";
    }
  },
  methods: {
    getShortname(item) {
      return item.firstname.substring(0, 1) + item.lastname.substring(0, 1);
    },
    isInstructorActive(instructor) {
      const lastActivity = new Date(instructor.lastActivity);
      return (new Date() - lastActivity) / 1000 / 60 < 10;
    },
    checkRole: function(role) {
      return this.$store.getters["auth/checkRole"](role);
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  border: 1px solid green;
}
</style>
