import ApiService from "../../common/api.service";
import Vue from "vue";

const state = {
  all: [],
  cache: {}
};

// getters
const getters = {
  getById: state => id => {
    return state.all[state.cache[id]];
  },
  getByCourseId: state => courseId => {
    return state.all.filter(item => item.course === courseId);
  },
  getSingleBookings: state => {
    return state.all.filter(item => item.event > 0);
  },
  getCompleteBookings: state => {
    return state.all.filter(item => item.event === null);
  },
  getAllBookings: state => {
    return state.all;
  }
};

// actions
const actions = {
  add(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/bookings", item)
        .then(response => {
          context.commit("ADD", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.put("/bookings/" + item.id, item)
        .then(response => {
          context.commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delete(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/bookings/" + item.id, item)
        .then(() => {
          context.commit("DEL", item);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAll(context) {
    ApiService.get("/bookings").then(response => {
      context.commit("SET", response.data);
    });
  },
  getPresence(context, id) {
    return ApiService.get("/bookings/" + id + "/presence");
  },
  addPaymentToBooking(context, payment) {
    return ApiService.post(
      "/bookings/" + payment.booking + "/payment",
      payment
    );
  },
  sendConfirmationMail(context, booking) {
    return ApiService.post("/bookings/" + booking.id + "/confirmation");
  },
  sendBalanceInformation(context, booking) {
    return ApiService.post("/bookings/" + booking.id + "/balanceInformation");
  },
  sendHybridCourseNotification(context, booking) {
    return ApiService.post(
      "/bookings/" + booking.id + "/hybrid-course-notification"
    );
  },
  sendCoronaStatusUpdate(context, booking) {
    return ApiService.post(
      "/bookings/" + booking.id + "/corona-update-notification"
    );
  },
  createInvoice(context, booking) {
    return ApiService.post("/bookings/" + booking.id + "/invoice");
  },
  export(context, filter) {
    return new Promise(resolve => {
      filter.type = "booking";
      ApiService.query("/export", { params: filter }).then(response => {
        resolve(response);
      });
    });
  }
};

// mutations
const mutations = {
  ADD(state, item) {
    state.all.push(item);
    state.cache[item.id] = state.all.length - 1;
  },
  SET(state, items) {
    state.all = items;
    createCache(items);
  },
  UPDATE(state, item) {
    const index = state.cache[item.id];
    Vue.set(state.all, index, item);
  },
  DEL(state, item) {
    state.all.splice(state.cache[item.id], 1);
  }
};

function createCache(items) {
  for (let i = 0; i < items.length; i++) {
    state.cache[items[i].id] = i;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
