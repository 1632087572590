import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import store from "../store";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    axios.interceptors.request.use(
      config => {
        store.dispatch("config/changeAjaxCounter", true);
        return config;
      },
      error => {
        store.dispatch("config/changeAjaxCounter", false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        store.dispatch("config/changeAjaxCounter", false);
        if (response.status === 401) {
          store.dispatch("auth/logout");
          return;
        }
        return response;
      },
      function(error) {
        store.dispatch("config/changeAjaxCounter", false);
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
          return;
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    const data = JwtService.getData();
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["X-LOCATION"] = data.location;
  },

  clearHeader() {
    if (Vue.axios.defaults.headers.common["Authorization"] !== undefined) {
      delete Vue.axios.defaults.headers.common["Authorization"];
      delete Vue.axios.defaults.headers.common["X-LOCATION"];
    }
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      if (error.response.status === 500) {
        throw new Error(`[RWV] ApiService ${error}`);
      }
    });
  },

  get(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
      if (error.response.status === 500) {
        throw new Error(`[RWV] ApiService ${error}`);
      }
    });
  },

  async post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      if (error.response.status === 500) {
        throw new Error(`[RWV] ApiService ${error}`);
      }
    });
  }
};

export default ApiService;
