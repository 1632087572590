import ApiService from "../../common/api.service";
import Vue from "vue";

const state = {
  all: []
};

// getters
const getters = {
  getById: state => id => {
    return state.all.find(item => item.id === id);
  },
  getByBooking: state => bookingId => {
    return state.all.filter(invoice => invoice.booking === bookingId);
  }
};

// actions
const actions = {
  getByBooking(context, bookingId) {
    return new Promise((resolve, reject) => {
      ApiService.get("/bookings/" + bookingId + "/invoices")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAll(context) {
    ApiService.get("/invoices").then(response => {
      context.commit("SET", response.data);
    });
  },
  delete(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/invoices/" + item.id)
        .then(() => {
          context.commit("DEL", item);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  ADD(state, definition) {
    state.all.push(definition);
  },
  SET(state, definitions) {
    state.all = definitions;
  },
  DEL(state, item) {
    state.all.splice(state.all.indexOf(item), 1);
  },
  UPDATE(state, item) {
    const index = state.all.findIndex(element => element.id === item.id);
    Vue.set(state.all, index, item);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
