<template>
  <v-app>
    <template v-if="isAuthenticated">
      <v-app-bar app clipped-left>
        <v-app-bar-nav-icon
          @click="showNavigation = !showNavigation"
        ></v-app-bar-nav-icon>
        <v-app-bar-title class="text--primary text-no-wrap">
          {{ location }} {{ environment }}
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="!this.$vuetify.breakpoint.mobile"
            @click="syncData"
            depressed
            >Reload</v-btn
          >
          <v-btn @click="dialogProfile = true" icon color="primary">{{
            userShortCut
          }}</v-btn>
          <v-btn icon @click="logout"><v-icon>fal fa-sign-out</v-icon></v-btn>
        </v-toolbar-items>
        <v-progress-linear
          :active="ajaxRequestRunning"
          absolute
          bottom
          indeterminate
        ></v-progress-linear>
      </v-app-bar>

      <Navigation :show-navigation.sync="showNavigation"></Navigation>

      <v-main>
        <v-container fluid>
          <h1>{{ title }}</h1>
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-dialog v-model="dialogProfile" max-width="800" persistent>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Benutzer {{ user.username }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogProfile = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <ProfileEdit v-model="profile"></ProfileEdit>
        </v-card>
      </v-dialog>
    </template>
    <template v-else class="fill-height">
      <Login></Login>
    </template>
    <v-snackbars :objects.sync="messages" top right :timeout="10000">
      <template v-slot:action="{ close }">
        <v-btn text @click="close()"><v-icon>fal fa-times</v-icon></v-btn>
      </template>
    </v-snackbars>
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      Eine neue Software Version ist verfügbar
      <v-btn text @click="refreshApp">Update durchführen</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import ProfileEdit from "./components/profile/ProfileEdit";
import VSnackbars from "v-snackbars";

export default {
  name: "App",
  components: {
    ProfileEdit,
    Login,
    Navigation,
    VSnackbars
  },
  computed: {
    messages: {
      set: function() {
        this.$store.dispatch("messages/del");
      },
      get: function() {
        return this.$store.state.messages.all;
      }
    },
    user: function() {
      return this.$store.getters["auth/currentUser"];
    },
    isAuthenticated: function() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    title: function() {
      return this.$route.meta.title;
    },
    userShortCut: function() {
      return this.user.shortcut;
    },
    instructors: function() {
      if (this.isAuthenticated)
        return this.$store.getters["instructors/getAll"];
      return [];
    },
    profile: function() {
      return Object.assign(
        {},
        this.instructors.find(
          instructor => instructor.username === this.user.username
        )
      );
    },
    ajaxRequestRunning: function() {
      return this.$store.getters["config/getAjaxCounter"] > 0;
    },
    environment: function() {
      if (process.env.VUE_APP_TITLE === "") return "";
      return " - " + process.env.VUE_APP_TITLE;
    }
  },
  data: () => ({
    registration: null,
    updateExists: false,
    dialogProfile: false,
    showNavigation: true,
    location: ""
  }),
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    syncData() {
      this.$store.dispatch("customers/loadCustomers");
      this.$store.dispatch("participants/loadParticipants");
      this.$store.dispatch("courseDefinitions/loadCourseDefinitions");
      this.$store.dispatch("courseDefinitionCategories/getAll");
      this.$store.dispatch("courses/getAll");
      this.$store.dispatch("baths/getAll");
      this.$store.dispatch("bathLanes/getAll");
      this.$store.dispatch("instructors/getAll");
      this.$store.dispatch("events/getAll");
      this.$store.dispatch("bookings/getAll");
      this.$store.dispatch("payments/getAll");
      this.$store.dispatch("coupons/loadAll");
      this.$store.dispatch("invoices/getAll");
      this.$store.dispatch("waitingList/getAll");
      this.$store.dispatch("settings/load");

      this.$store
        .dispatch(
          "location/getLocationData",
          this.$store.getters["auth/currentLocation"]
        )
        .then(data => {
          this.location = data.company;
        });

      return location.company;
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  mounted() {},
  watch: {
    isAuthenticated: function(val) {
      if (val === true) {
        this.syncData();
      }
    }
  }
};
</script>

<style>
.v-app-bar-title__content {
  width: 275px !important;
}
</style>
