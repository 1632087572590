<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <ParticipantsTable
        :searchParticipant="searchParticipant"
        @participant-edit="onParticipantEdit"
        @participant-delete="onParticipantDelete"
        @new-participant="createNewParticipant"
      />
      <v-dialog v-model="dialogEditParticipant" max-width="1000">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title v-if="this.participant.id > 0"
              >Teilnehmer ändern</v-toolbar-title
            >
            <v-toolbar-title v-else>Teilnehmer anlegen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogEditParticipant = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-form ref="participant">
              <ParticipantEdit v-model="participant"></ParticipantEdit>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="saveParticipant()">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import ParticipantsTable from "@/components/participant/ParticipantsTable";
import ParticipantEdit from "@/components/participant/ParticipantEdit";

export default {
  name: "Participants",
  components: {
    ParticipantEdit,
    ParticipantsTable
  },
  props: ["searchParticipant"],
  computed: {},
  methods: {
    createNewParticipant() {
      this.participant = {};
      this.dialogEditParticipant = true;
    },
    saveParticipant() {
      if (this.$refs.participant.validate()) {
        if (this.participant.id > 0) {
          this.$store
            .dispatch("participants/updateParticipant", this.participant)
            .then(() => {
              this.dialogEditParticipant = false;
              this.participant = {};
            });
        } else {
          this.$store
            .dispatch("participants/addParticipant", this.participant)
            .then(() => {
              this.dialogEditParticipant = false;
              this.participant = {};
            });
        }
      }
    },
    onParticipantEdit(participantId) {
      this.loadParticipant(participantId);
      this.dialogEditParticipant = true;
    },
    loadParticipant(participantId) {
      this.participant = Object.assign(
        {},
        this.$store.getters["participants/getParticipant"](participantId)
      );
    },
    onParticipantDelete(participant) {
      if (
        confirm(
          "Soll der Teilnehmer #" +
            participant.id +
            " " +
            participant.firstname +
            " " +
            participant.lastname +
            " wirklich gelöscht werden?"
        )
      ) {
        this.$store.dispatch("participants/delete", participant);
      }
    }
  },
  data() {
    return {
      dialogEditParticipant: false,
      participant: {}
    };
  }
};
</script>
