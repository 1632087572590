<template>
  <span :class="workloadColor + '--text'">
    {{ onWaitingList }} / {{ capacityUsed }} / {{ capacity }}
  </span>
</template>

<script>
export default {
  name: "CourseWorkloadText",
  props: ["capacity", "capacityUsed", "capacityMin", "onWaitingList"],
  computed: {
    workload: function() {
      return ((this.capacityUsed / this.capacity) * 100).toFixed(0);
    },
    workloadColor: function() {
      //if (this.capacityUsed <= this.capacityMin) return 'error'
      if (this.workload > 75) return "success";
      if (this.workload > 30) return "warning";
      return "error";
    }
  }
};
</script>

<style scoped></style>
