var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.showMergeDialog),callback:function ($$v) {_vm.showMergeDialog=$$v},expression:"showMergeDialog"}},[(_vm.showMergeDialog)?_c('CustomersMerge',{attrs:{"customers":_vm.customers},on:{"close":function($event){_vm.showMergeDialog = false}}}):_vm._e()],1),_c('Table',{attrs:{"items":_vm.customers,"search":_vm.searchTerm,"headers":_vm.headers,"itemsPerPage":20},on:{"update:search":function($event){_vm.searchTerm=$event}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{staticClass:"mr-8",on:{"click":function($event){return _vm.exportCustomerData()}}},[_c('v-icon',[_vm._v("fal fa-download")]),_vm._v("Export")],1),_c('v-btn',{staticClass:"mr-8",on:{"click":function($event){_vm.showMergeDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-users")]),_vm._v("Kunden zusammenführen")],1),_c('v-btn',{on:{"click":function($event){return _vm.$emit('new-customer')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v("Neuer Kunde")],1)]},proxy:true},{key:"item.street",fn:function({ item }){return [_vm._v(" "+_vm._s(item.street)+" "+_vm._s(item.streetNumber)+" ")]}},{key:"item.action",fn:function({ item }){return [(item.comment !== '')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-info")])],1)]}}],null,true)},[_c('p',[_vm._v(_vm._s(item.comment))])]):_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"disabled":""}},[_vm._v("fal fa-info")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + item.email}},[_c('v-icon',[_vm._v("fal fa-envelope")])],1),_c('v-btn',{attrs:{"icon":"","href":'tel:' + item.phone}},[_c('v-icon',[_vm._v("fal fa-phone")])],1),_c('v-btn',{attrs:{"icon":"","to":{
          name: 'participants',
          params: { searchParticipant: item.firstname + ' ' + item.lastname }
        }}},[_c('v-icon',[_vm._v("fal fa-swimmer")])],1),_c('v-btn',{attrs:{"icon":"","to":{
          name: 'bookings',
          params: { filterSearch: item.firstname + ' ' + item.lastname }
        }}},[_c('v-icon',[_vm._v("fal fa-shopping-cart")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('customer-edit', item.id)}}},[_c('v-icon',[_vm._v("fal fa-edit")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }