<template v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.title"
          label="Titel"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="value.type"
          label="Typ"
          :items="types"
          :rules="[rules.required]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-select
          v-model="value.category"
          label="Kategorie"
          :items="categories"
          item-value="id"
          item-text="title"
          :rules="[rules.required]"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="value.shortcut"
          label="Kürzel"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.number="value.lessonQuantity"
          type="number"
          min="1"
          steps="1"
          label="Anzahl Termine"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="value.lessonDuration"
          type="number"
          min="1"
          steps="1"
          label="Dauer eines Termins in Minuten"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="value.lessonPerWeek"
          type="number"
          min="1"
          steps="1"
          label="Termine pro Woche"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.number="value.price"
          prefix="€"
          type="number"
          min="1"
          steps="1"
          label="Preis"
          :rules="[rules.required, rules.greaterEqualZero]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="value.priceUnit"
          prefix="€"
          type="number"
          min="0"
          steps="1"
          label="Preis pro Einheit"
          :rules="[rules.required, rules.greaterEqualZero]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="value.participantMin"
          type="number"
          min="1"
          steps="1"
          label="Teilnehmer minimum"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="value.participantMax"
          type="number"
          min="1"
          steps="1"
          label="Teilnehmer maximum"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="value.requirement"
          label="Anforderungen"
          :rules="[rules.required]"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="value.workflow"
          label="Ablauf"
          :rules="[rules.required]"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="value.showOnHomepage"
          label="Auf der Homepage anzeigen"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CourseDefinitionEdit",
  components: {},
  props: ["value"],
  computed: {
    categories() {
      return this.$store.state.courseDefinitionCategories.all;
    }
  },
  methods: {},
  data() {
    return {
      types: [
        { text: "Club", value: "club" },
        { text: "Hybrid Kurs", value: "hybrid-course" },
        { text: "Kurs", value: "course" }
      ],
      areas: [
        { id: "original", title: "Original" },
        { id: "premium", title: "Premium" },
        { id: "single", title: "Einzel" }
      ],
      rules: {
        required: v => !!v || v === 0 || "Pflichtfeld",
        currency: v => /^\d+,?\d*$/.test(v) || "Wert ist nicht valide",
        greaterEqualZero: v => {
          if (v >= 0) return true;
          return "Wert muss 0 € übersteigen";
        }
      }
    };
  }
};
</script>
