<template v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
  <v-container>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="value.gender"
          label="Geschlecht"
          :rules="[rules.requiredRadio]"
          row
        >
          <v-radio value="male" label="Männlich">Männlich</v-radio>
          <v-radio value="female" label="Weiblich">Weiblich</v-radio>
          <v-radio value="divers" label="Divers">Divers</v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="value.firstname"
          label="Vorname"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="value.lastname"
          label="Nachname"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BirthdayPicker
          :date.sync="value.dateOfBirth"
          :rules="[rules.required]"
        ></BirthdayPicker>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="value.customer"
          label="Kunde"
          item-value="id"
          clearable
          single-line
          :items="customers"
          :filter="filterCustomer"
          :rules="[rules.required]"
        >
          <template v-slot:selection="data" class="d-flex">
            {{ data.item.firstname }} {{ data.item.lastname }}
          </template>
          <template v-slot:item="data">
            {{ data.item.firstname }} {{ data.item.lastname }}
            {{ data.item.email }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="value.performance"
          label="Leistungsstand"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea v-model="value.comment" label="Kommentar"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BirthdayPicker from "@/components/picker/BirthdayPicker";
export default {
  name: "ParticipantEdit",
  components: {
    BirthdayPicker
  },
  props: ["value"],
  computed: {
    customers() {
      return this.$store.state.customers.all;
    }
  },
  data: () => ({
    rules: {
      requiredRadio: v => (v && !!v) || "Pflichtfeld",
      required: v => !!v || "Pflichtfeld",
      postal: v =>
        /^\d{5}$/.test(v) || "Bitte eine gültige Postleitzahl eingeben",
      email: v => /.+@.+\..+/.test(v) || "Ungültige E-Mailadresse"
    }
  }),
  methods: {
    filterCustomer(item, queryText) {
      return (
        item.firstname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.lastname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    formatDate(dateTime) {
      let formattedDate = dateTime.split(" ");
      formattedDate[0] = formattedDate[0]
        .split("-")
        .reverse()
        .join(".");

      return formattedDate.join(" - ");
    }
  }
};
</script>
