import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/filter";
import "./plugins/sentry";
import "./plugins/vuemask";
import ApiService from "./common/api.service";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "fa"
});

Vue.config.productionTip = false;

ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) =>
  Promise.all([store.dispatch("auth/checkAuth")]).then(next)
);

new Vue({
  router,
  store,
  vuetify,
  theme: {
    themes: {
      light: {
        primary: "#34688F",
        secondary: "#F18904",
        accent: "#82BDDE",
        error: "#FF5252",
        info: "#D1E7F4",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {
        primary: "#34688F",
        secondary: "#F18904",
        accent: "#82BDDE",
        error: "#FF5252",
        info: "#D1E7F4",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  render: h => h(App)
}).$mount("#app");
