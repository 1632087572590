<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <WaitinglistTable v-on:item-delete="onItemDelete"></WaitinglistTable>
    </v-col>
  </v-row>
</template>

<script>
import WaitinglistTable from "@/components/waitinglist/WaitinglistTable";
export default {
  name: "WaitingList",
  components: { WaitinglistTable },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onItemDelete(waitinglist) {
      if (
        confirm(
          "Soll der Eintrag #" + waitinglist.id + " wirklich gelöscht werden?"
        )
      ) {
        this.$store.dispatch("waitingList/delete", waitinglist).then(() => {
          this.$store.dispatch("waitingList/getAll");
        });
      }
    }
  },
  mounted() {},
  created() {
    this.$store.dispatch("waitingList/getAll");
  }
};
</script>
