<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateProxy"
        label="Geburtsdatum"
        prepend-icon="fa-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="dateProxy"
      :max="new Date().toISOString().substr(0, 10)"
      min="1900-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "BirthdayPicker",
  props: ["date", "rules"],
  data() {
    return {
      menu: false,
      dateValue: this.date
    };
  },
  computed: {
    dateProxy: {
      set: function(value) {
        this.$emit("update:date", value);
        this.dateValue = value;
      },
      get: function() {
        return this.dateValue;
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  methods: {
    save(value) {
      this.$refs.menu.save(value);
    }
  }
};
</script>

<style scoped></style>
