import ApiService from "../../common/api.service";
import Vue from "vue";

const state = {
  all: [],
  cache: {}
};

// getters
const getters = {
  getAll: state => {
    return state.all;
  },
  getById: state => id => {
    return state.all[state.cache[id]];
  },
  getByBooking: state => bookingId => {
    return state.all.filter(payment => payment.booking === bookingId);
  }
};

// actions
const actions = {
  add(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payments", item)
        .then(response => {
          context.commit("ADD", response.data);
          context.dispatch("getAll");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  import(context, items) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payments/import", { payments: items })
        .then(response => {
          context.dispatch("getAll");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.put("/payments/" + item.id, item)
        .then(response => {
          context.commit("UPDATE", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delete(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/payments/" + item.id, item)
        .then(() => {
          context.commit("DEL", item);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAll(context) {
    ApiService.get("/payments").then(response => {
      context.commit("SET", response.data);
    });
  },
  export(context, options) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payments/export", options)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  ADD(state, item) {
    state.all.push(item);
    state.cache[item.id] = state.all.length - 1;
  },
  SET(state, items) {
    state.all = items;
    createCache(items);
  },
  UPDATE(state, item) {
    const index = state.cache[item.id];
    Vue.set(state.all, index, item);
  },
  DEL(state, item) {
    state.all.splice(state.cache[item.id], 1);
  }
};

function createCache(items) {
  for (let i = 0; i < items.length; i++) {
    state.cache[items[i].id] = i;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
