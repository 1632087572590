<template>
  <v-progress-circular :size="size" :value="workload" :color="workloadColor">
    {{ capacityUsed }} / {{ capacity }}
  </v-progress-circular>
</template>

<script>
export default {
  name: "CourseWorkloadCircle",
  props: ["capacity", "capacityUsed", "capacityMin", "size"],
  computed: {
    workload: function() {
      return ((this.capacityUsed / this.capacity) * 100).toFixed(0);
    },
    workloadColor: function() {
      if (this.capacityUsed <= this.capacityMin) return "error";
      if (this.workload > 75) return "success";
      if (this.workload > 30) return "warning";
      return "error";
    }
  }
};
</script>

<style scoped></style>
