import ApiService from "../../common/api.service";
import Vue from "vue";

const state = {
  all: []
};

// getters
const getters = {
  getAll: state => {
    return state.all;
  },
  getAllWithBalance: state => {
    return state.all.filter(item => item.balance !== 0)
  },
  getById: state => id => {
    return state.all.find(item => item.id === id);
  },
  getByCustomerId: state => id => {
    return state.all.filter(item => item.customer === id);
  }
};

// actions
const actions = {
  create(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/coupons", item)
        .then(response => {
          context.commit("ADD", response.data);
          context.dispatch("loadAll");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  import(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/coupons/import", item)
        .then(response => {
          context.commit("ADD", response.data);
          context.dispatch("loadAll");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.put("/coupons/" + item.id, item)
        .then(response => {
          context.commit("UPDATE", response.data);
          context.dispatch("loadAll");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delete(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/coupons/" + item.id)
        .then(() => {
          context.commit("DEL", item);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadAll(context) {
    ApiService.get("/coupons").then(response => {
      context.commit("SET", response.data);
    });
  },
  loadById(context, id) {
    ApiService.get("/coupons/" + id).then(response => {
      context.commit("UPDATE", response.data);
    });
  },
  payOff(context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/payoff", data)
        .then(() => {
          context.dispatch("loadById", data.coupon);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  ADD(state, item) {
    state.all.push(item);
  },
  SET(state, items) {
    state.all = items;
  },
  UPDATE(state, item) {
    const index = state.all.findIndex(element => element.id === item.id);
    Vue.set(state.all, index, item);
  },
  DEL(state, item) {
    state.all.splice(state.all.indexOf(item), 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
