<template>
  <router-link
    class="body-1"
    :to="{ name: 'courses', params: { filterSearch: course.id } }"
    >K{{ course.id }} {{ courseTitle }}</router-link
  >
</template>

<script>
export default {
  name: "CourseTitle",
  props: ["course", "link"],
  data() {
    return {
      courseTitle: ""
    };
  },
  computed: {
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAllWithDeleted"];
    }
  },
  mounted() {
    if (this.courseDefinitions !== undefined && this.course.definition) {
      const courseDefinition = this.courseDefinitions.find(
        courseDefinition => this.course.definition === courseDefinition.id
      );
      if (courseDefinition !== undefined)
        this.courseTitle = courseDefinition.title;
    }
  }
};
</script>

<style scoped lang="scss">
.v-application a {
  color: black;
  text-decoration: none;
}
</style>
