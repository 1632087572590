import ApiService from "../../common/api.service";

const state = {
  all: []
};

// getters
const getters = {
  get: state => {
    return state.all;
  }
};

// actions
const actions = {
  load(context) {
    ApiService.get("/settings").then(response => {
      context.commit("SET", response.data);
    });
  }
};

// mutations
const mutations = {
  SET(state, items) {
    state.all = items;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
