const state = {
  all: [
    { role: "ROLE_ADMIN", label: "Administrator" },
    { role: "ROLE_USER", label: "Benutzer" },
    { role: "ROLE_ACCOUNTING", label: "Buchhaltung" },
    { role: "ROLE_INSTRUCTOR", label: "Schwimmlehrer" }
  ]
};

// getters
const getters = {
  getLabelByRole: state => role => {
    const roleItem = state.all.find(item => item.role === role);
    if (undefined === roleItem) return "";
    return roleItem.label;
  }
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
