<template>
  <div>
    <Table
      :items="bookingsFiltered"
      :headers="headers"
      :itemsPerPage="20"
      :search.sync="searchTerm"
    >
      <template v-slot:actions>
        <v-checkbox
          @change="filterBookings()"
          v-model="showCanceledBookings"
          label="Zeige gekündigte Buchungen an"
          :value="!showCanceledBookings"
        ></v-checkbox>
      </template>
      <template v-slot:button>
        <ExportDialog
          css-class="mr-8"
          :type="showOpen ? 'openBooking' : 'booking'"
          :show-bath="true"
          :show-course-definition="true"
        ></ExportDialog>
        <v-btn
          v-if="!assignMode"
          :to="{ name: 'bookingEdit', params: { id: 0 } }"
          ><v-icon left>fal fa-plus</v-icon> Neue Buchung</v-btn
        >
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.startDate | formatDate }}
      </template>
      <template v-slot:item.courseTitle="{ item }">
        <v-chip outlined label @click="search = item.courseTitle">{{
          item.courseTitle
        }}</v-chip>
      </template>
      <template v-slot:item.customerName="{ item }">
        <v-chip outlined label @click="search = item.customerName">
          <div class="-ml-2 br-2 mr-2">
            <v-btn
              icon
              :to="{
                name: 'customers',
                params: { searchCustomer: item.customerName }
              }"
              ><v-icon>fal fa-users</v-icon></v-btn
            >
          </div>
          {{ item.customerName }}</v-chip
        >
      </template>
      <template v-slot:item.participantName="{ item }">
        <v-chip outlined label @click="search = item.participantName">
          <div class="-ml-2 br-2 mr-2">
            <v-btn
              icon
              :to="{
                name: 'participants',
                params: { searchParticipant: item.participantName }
              }"
              ><v-icon>fal fa-swimmer</v-icon></v-btn
            >
          </div>

          {{ item.participantName }}</v-chip
        >
      </template>
      <template v-slot:item.price="{ item }">
        <v-btn
          text
          v-if="!assignMode"
          @click="openInvoice(item.id)"
          :class="getColorByBalance(item.invoiceAmount - item.paymentAmount)"
          >{{ item.price | formatCurrency }}</v-btn
        >
        <v-btn text v-else>{{ item.price | formatCurrency }}</v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip left v-if="item.clubEndDate !== null">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon>fas fa-times-circle</v-icon></v-btn
            >
          </template>
          <p>Kündigung zum {{ item.clubEndDate | formatDate }}</p>
        </v-tooltip>
        <v-tooltip left v-if="item.information !== ''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon>fas fa-info</v-icon></v-btn
            >
          </template>
          {{ item.information }}
        </v-tooltip>
        <v-btn v-else icon><v-icon disabled>fal fa-info</v-icon></v-btn>
        <v-btn v-if="assignMode" icon @click="$emit('item-assign', item)"
          ><v-icon>fal fa-link</v-icon></v-btn
        >
        <v-btn
          v-if="!assignMode"
          icon
          :to="{ name: 'bookingEdit', params: { id: item.id } }"
          ><v-icon>fal fa-edit</v-icon></v-btn
        >
      </template>
    </Table>
    <v-dialog v-model="invoiceDialog" max-width="800">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Rechnungen und Zahlungen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="invoiceDialog = false"
            ><v-icon>fal fa-times</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <PaymentBalance
            :booking="getBooking(invoiceDialogBookingId)"
            class="my-4"
          ></PaymentBalance>
          <CouponOverview
            class="my-4"
            :customer-id="getBooking(invoiceDialogBookingId).customer"
            v-on:pay-by-coupon="payByCoupon"
          ></CouponOverview>
          <v-form ref="paymentAdd" @submit.prevent="addPayment">
            <v-card class="my-4" flat>
              <v-card-title>Zahlung hinzufügen</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      ><DatePicker
                        :rules="[rules.required]"
                        :date.sync="payment.date"
                      ></DatePicker
                    ></v-col>
                    <v-col
                      ><v-text-field
                        label="Betrag"
                        suffix="EUR"
                        :rules="[rules.required, rules.currency]"
                        v-model="payment.value"
                      ></v-text-field
                    ></v-col>
                    <v-col
                      ><v-text-field
                        label="Information"
                        v-model="payment.information"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                ><v-btn type="submit">Zahlung speichern</v-btn></v-card-actions
              >
            </v-card>
          </v-form>
          <PaymentHistory
            :booking="getBooking(invoiceDialogBookingId)"
          ></PaymentHistory>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="sendBalanceInformation(getBooking(invoiceDialogBookingId))"
            >Kontostand E-Mail</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from "@/components/picker/DatePicker";
import PaymentBalance from "@/components/payment/PaymentBalance";
import PaymentHistory from "@/components/payment/PaymentHistory";
import CouponOverview from "@/components/coupon/CouponOverview";
import moment from "moment/moment";
import Table from "@/components/utils/Table";
import ExportDialog from "@/components/ExportDialog.vue";

export default {
  name: "BookingsTable",
  components: {
    ExportDialog,
    CouponOverview,
    PaymentHistory,
    PaymentBalance,
    DatePicker,
    Table
  },
  props: ["assignMode", "showOpen"],
  data() {
    return {
      showDeletedBookings: false,
      showCanceledBookings: false,
      invoiceDialog: false,
      invoiceDialogBookingId: 0,
      exportDialog: false,
      exportFilter: {
        startDate: null,
        endDate: null,
        template: null,
        bath: null
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Startdatum", value: "startDate" },
        { text: "Kurs ID", value: "courseId" },
        { text: "Kurs", value: "courseTitle" },
        { text: "Teilnehmer", value: "participantName" },
        { text: "Kunde", value: "customerName" },
        { text: "Preis", value: "price", filterable: false },
        { text: "Aktion", value: "action", align: "end" }
      ],
      search: { term: "" },
      rules: {
        required: v => !!v || "Pflichtfeld",
        currency: v =>
          /^-?\d+(,\d{1,2})?$/.test(v) ||
          "Bitte eine Zahl mit maximal zwei Nachkommastellen angeben"
      },
      payment: {
        date: "",
        value: 0,
        information: "",
        booking: 0,
        source: "bank",
        coupon: null
      },
      bookingsPrepared: [],
      bookingsFiltered: [],
      courseCache: {}
    };
  },
  computed: {
    paymentAmount: function() {
      return this.getBooking(this.invoiceDialogBookingId).paymentAmount;
    },
    invoiceAmount: function() {
      return this.getBooking(this.invoiceDialogBookingId).invoiceAmount;
    },
    user: function() {
      return this.$store.state.user;
    },
    bookings: function() {
      if (!this.assignMode)
        return this.$store.getters["bookings/getCompleteBookings"];
      return this.$store.getters["bookings/getAllBookings"];
    },
    baths: function() {
      return this.$store.getters["baths/getAll"];
    },
    courses: function() {
      return this.$store.state.courses.all;
    },
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAllWithDeleted"];
    },
    participants: function() {
      return this.$store.state.participants.all;
    },
    customer: function() {
      return this.$store.state.customers.all;
    },
    invoices: function() {
      const invoices = this.$store.state.invoices.all.filter(
        item => item.booking === this.invoiceDialogBookingId
      );
      if (invoices !== undefined) return invoices;
      return [];
    },
    searchTerm: {
      set: function(value) {
        this.search.term = value;
        this.$store.dispatch("search/set", {
          type: "booking",
          search: this.search
        });
      },
      get: function() {
        return this.$store.state.search.booking.term;
      }
    }
  },
  methods: {
    payByCoupon: function(coupon) {
      this.payment.value = coupon.balance.toString().replace(".", ",");
      this.payment.coupon = coupon.id;
      this.payment.information = "Gutschein " + coupon.token;
      this.payment.source = "coupon";
    },
    getColorByBalance: function(balance) {
      if (balance > 0) return "red--text";
      if (balance === 0) return "green--text";
      if (balance < 0) return "blue--text";
    },
    getCourse: function(id) {
      if (this.courseCache[id] === undefined) {
        const course = Object.assign(
          {},
          this.courses[this.$store.state.courses.cache[id]]
        );
        if (course !== undefined) {
          course.definition = this.courseDefinitions.find(
            courseDefinition => courseDefinition.id === course.definition
          );
          this.courseCache[id] = course;
        }
      }

      return this.courseCache[id];
    },
    getBooking: function(id) {
      const booking = this.$store.getters["bookings/getById"](id);
      if (booking === undefined) {
        return {};
      }
      return this.bookings.find(booking => booking.id === id);
    },
    getParticipant: function(id) {
      return this.$store.getters["participants/getParticipant"](id);
    },
    getCustomer: function(id) {
      return this.$store.getters["customers/getCustomer"](id);
    },
    openInvoice: function(bookingId) {
      this.payment = {
        date: moment().format("YYYY-MM-DD"),
        value: 0,
        information: "",
        booking: bookingId,
        source: "bank",
        coupon: null
      };
      this.invoiceDialogBookingId = bookingId;
      this.invoiceDialog = true;
    },
    addPayment: function() {
      if (this.$refs.paymentAdd.validate()) {
        this.$store
          .dispatch("bookings/addPaymentToBooking", this.payment)
          .then(() => {
            this.$store.dispatch("bookings/getAll");
            this.$store.dispatch("payments/getAll");
            this.$store.dispatch("coupons/loadAll");
            this.openInvoice(this.invoiceDialogBookingId);
            this.$refs.paymentAdd.reset();
            this.payment.source = "bank";
            this.payment.coupon = null;
            this.payment.date = new Date();
          });
      }
    },
    sendBalanceInformation: function(booking) {
      const vue = this;
      this.$store
        .dispatch("bookings/sendBalanceInformation", booking)
        .then(function() {
          vue.$store.dispatch("messages/add", {
            message: "Kontostand wurde an Kunden versandt",
            color: "success"
          });
        })
        .catch(function() {
          vue.$store.dispatch("messages/add", {
            message: "Kontostand an Kunden konnte nicht versandt werden",
            color: "error"
          });
        });
    },
    filterBookings: function() {
      let bookingsFiltered = [...this.bookingsPrepared];
      if (!this.showDeletedBookings)
        bookingsFiltered = bookingsFiltered.filter(
          item => item.deleted === false
        );
      if (this.showOpen === true)
        bookingsFiltered = bookingsFiltered.filter(
          item => item.invoiceAmount !== item.paymentAmount
        );

      if (this.showCanceledBookings === false && this.showOpen === false) {
        const now = new Date();
        bookingsFiltered = bookingsFiltered.filter(
          item =>
            item.type !== "club" ||
            (item.type === "club" &&
              (item.clubEndDate === null || new Date(item.clubEndDate) > now))
        );
      }

      this.bookingsFiltered = bookingsFiltered;
    },
    prepareBookingData: function() {
      let bookings = [...this.bookings];
      bookings.forEach(booking => {
        const course = this.getCourse(booking.course);
        booking.courseId = "K" + booking.course;
        booking.courseTitle = course.title;

        if (booking.type === "club") {
          booking.startDate = booking.clubStartDate;
        } else {
          booking.startDate = this.$store.getters["courses/getById"](
            booking.course
          )
            .startDate.split(".")
            .reverse()
            .join("-");
        }

        const participant = this.getParticipant(booking.participant);
        booking.participantName = "";
        booking.customerName = "";
        if (participant !== undefined) {
          booking.participantName =
            participant.firstname + " " + participant.lastname;

          const customer = this.getCustomer(participant.customer);
          if (customer !== undefined) {
            booking.customerName = customer.firstname + " " + customer.lastname;
            booking.customer = customer.id;
          }
        }
      });

      this.bookingsPrepared = bookings;
    },
    openExportDialog: function() {
      this.exportDialog = true;
    },
    exportBookingData() {
      this.$store
        .dispatch("bookings/export", { filter: this.exportFilter })
        .then(response => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("href", url);
          a.setAttribute("download", "Buchungen.csv");
          a.click();
        });
    }
  },
  mounted() {
    this.filterBookings();
    if (this.$route.params.filterSearch !== undefined) {
      this.searchTerm = this.$route.params.filterSearch;
    }
  },
  created() {
    this.prepareBookingData();
    if (this.$route.params.filterSearch !== undefined) {
      this.searchTerm = this.$route.params.filterSearch;
    }
  },
  watch: {
    bookings() {
      this.prepareBookingData();
    }
  }
};
</script>

<style scoped>
.-ml-2 {
  margin-left: -1rem;
}
.br-2 {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
</style>
