<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-text-field
          label="Buchungsdatum"
          :value="bookingProxy.date | formatDate"
          :rules="[rules.required]"
          :readonly="id === 0"
          :disabled="id > 0"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete
          v-if="id === 0"
          label="Teilnehmer"
          v-model="bookingProxy.participant"
          :items="participants"
          :item-text="
            item =>
              `T${item.id} ${item.firstname} ${item.lastname} (${item.customerFirstname} ${item.customerLastname})`
          "
          item-value="id"
          :rules="[rules.required]"
          :readonly="booking.id > 0"
        ></v-autocomplete>
        <v-text-field
          v-else
          label="Teilnehmer"
          v-model="participantName"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-if="id === 0"
          label="Kurs"
          v-model="bookingProxy.course"
          :items="courses"
          item-value="id"
          :item-text="item => 'K' + item.id + ' ' + item.title"
          @change="selectCourse"
          :rules="[rules.required]"
        ></v-autocomplete>
        <v-text-field
          v-else
          label="Kurs"
          v-model="courseTitle"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Preis"
          v-model="price"
          :rules="[rules.required, rules.currency]"
          suffix="EUR"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-if="id === 0"
          label="Termin"
          v-model="bookingProxy.event"
          :items="courseEvents"
          item-value="id"
          :item-text="item => item.startDate"
          @change="selectPrice"
        ></v-autocomplete>
        <v-text-field
          v-else
          label="Termin"
          v-model="eventDate"
          disabled
        ></v-text-field>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-row v-if="courseType === 'club' && bookingProxy.event === null">
      <v-col cols="6">
        <v-text-field
          v-model="bookingProxy.clubStartDate"
          label="Start"
        ></v-text-field>
        <v-row v-if="id === 0">
          <v-col cols="4" v-for="startDate in startDates" :key="startDate">
            <v-btn
              text
              outlined
              small
              color="success"
              @click.prevent="setStartDate(startDate)"
              >{{ startDate | formatDate }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" v-if="bookingProxy.clubStartdate !== null">
        <v-text-field
          v-model="bookingProxy.clubEndDate"
          label="Kündigung zum"
          :rules="[rules.afterStartDate]"
          clearable
        ></v-text-field>
        <v-row>
          <v-col cols="4" v-for="cancelDate in cancelDates" :key="cancelDate"
            ><v-btn
              outlined
              text
              small
              color="error"
              @click.prevent="setEndDate(cancelDate)"
              >{{ cancelDate | formatDate }}</v-btn
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          label="Weitere Informationen"
          v-model="bookingProxy.information"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          label="Empfehlungsquelle"
          :items="recommendationSource"
          v-model="bookingProxy.recommendation"
          :rules="[rules.required]"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Buchungsquelle"
          :items="bookingSource"
          v-model="bookingProxy.source"
          :rules="[rules.required]"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "BookingEditForm",
  props: ["id", "courseId", "booking"],
  data() {
    return {
      bookingSource: [
        { text: "Webseite", value: "website" },
        { text: "E-Mail", value: "email" },
        { text: "Telefon", value: "phone" },
        { text: "Trainer", value: "instructor" },
        { text: "Sonstiges", value: "other" }
      ],
      recommendationSource: [
        { text: "Facebook", value: "facebook" },
        { text: "Flyer", value: "flyer" },
        { text: "Google", value: "google" },
        { text: "Internetanzeige", value: "ad-internet" },
        { text: "Messe", value: "exhibition" },
        { text: "Mitglied Holmes Place", value: "member-holmes-place" },
        { text: "Persönliche Empfehlung", value: "personal-recommendation" },
        { text: "Print Anzeige", value: "print" },
        { text: "Sonstiges", value: "other" }
      ],
      courseDefinition: {},
      rules: {
        required: v => !!v || "Pflichtfeld",
        currency: v => /^\d+,?\d*$/.test(v) || "Wert ist nicht valide",
        afterStartDate: v =>
          v === null ||
          v > this.bookingProxy.clubStartDate ||
          "Kündigung darf nicht vor Start liegen"
      },
      participantsAndCustomer: [],
      courseEvents: []
    };
  },
  computed: {
    bookingProxy: {
      set: function(value) {
        this.$emit("update:booking", value);
        this.booking = value;
      },
      get: function() {
        return this.booking;
      }
    },
    price: {
      set: function(value) {
        this.bookingProxy.price = value.replace(",", ".");
      },
      get: function() {
        if (/.+\..+/.test(this.bookingProxy.price))
          return (this.bookingProxy.price + "").replace(".", ",");
        return this.bookingProxy.price;
      }
    },
    participants: function() {
      return this.$store.getters["participants/getAll"];
    },
    participantName() {
      if (this.booking.id > 0) {
        const participant = this.participants.find(
          item => item.id === this.booking.participant
        );
        return participant.firstname + " " + participant.lastname;
      }
      return "";
    },
    courses: function() {
      return this.$store.getters["courses/getAll"];
    },
    courseType: function() {
      const course = this.courses.find(
        course => course.id === this.booking.course
      );
      if (course !== undefined) return course.type;
      return "";
    },
    courseTitle: function() {
      const course = this.courses.find(
        course => course.id === this.booking.course
      );
      if (course !== undefined) return "K" + course.id + " " + course.title;
      return "";
    },
    cancelDates: function() {
      let dates = [];
      let date = new moment();
      let startDate = new moment(this.clubStartDate, "DD.MM.YYYY");
      if (this.clubStartDate !== null && startDate > date) date = startDate;

      for (let i = 1; i <= 6; i++) {
        date = date.add(1, "M");
        dates.push(date.endOf("month").format("YYYY-MM-DD"));
      }
      return dates;
    },
    startDates: function() {
      let dates = [];
      let date = new moment();

      for (let i = 1; i <= 6; i++) {
        date = date.add(1, "M");
        dates.push(date.startOf("month").format("YYYY-MM-DD"));
      }
      return dates;
    },
    clubStartDate: function() {
      return this.$options.filters.formatDate(this.booking.clubStartDate);
    },
    clubEndDate: function() {
      return this.$options.filters.formatDate(this.booking.clubEndDate);
    },
    events: function() {
      return this.$store.getters["events/getByCourseId"](this.booking.course);
    },
    eventDate: function() {
      if (this.bookingProxy.event === null) return "Kompletter Club/Kurs";
      return moment(String(this.getEventDate(this.bookingProxy.event))).format(
        "DD.MM.YYYY HH:mm"
      );
    }
  },
  methods: {
    selectPrice: function() {
      const course = this.courses.find(item => item.id === this.booking.course);
      if (course !== undefined) {
        const price =
          this.bookingProxy.event === null ? course.price : course.priceUnit;
        this.bookingProxy.price = price;
        this.bookingProxy.oldPrice = price;
      } else {
        this.bookingProxy.price = 0;
        this.bookingProxy.oldPrice = 0;
      }
    },
    selectCourse: function() {
      this.courseEvents = [{ id: null, startDate: "Kompletter Club/Kurs" }];
      const events = this.events.filter(
        event => event.course === this.booking.course
      );
      events.forEach(event => {
        const date = new Date(event.startDate);
        if (date > new Date()) {
          this.courseEvents.push({
            id: event.id,
            startDate: moment(String(event.startDate)).format(
              "DD.MM.YYYY HH:mm"
            )
          });
        }
      });
      this.selectPrice();
    },
    setStartDate: function(date) {
      this.bookingProxy.clubStartDate = date;
    },
    setEndDate: function(date) {
      this.bookingProxy.clubEndDate = date;
    },
    getEventDate: function(id) {
      return this.$store.getters["events/getById"](id)?.startDate;
    }
  },
  created() {
    if (this.id === 0) {
      this.participants.map(participant => {
        const { firstname, lastname } = this.$store.getters[
          "customers/getCustomer"
        ](participant.customer);
        participant.customerFirstname = firstname;
        participant.customerLastname = lastname;
      });
    }
  }
};
</script>

<style scoped></style>
