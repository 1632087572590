<template>
  <v-row>
    <v-col v-if="checkRole('ROLE_ADMIN')" cols="6" sm="6" md="4" lg="3">
      <v-card class="fill-height">
        <v-card-title>Buchungen heute</v-card-title>
        <p class="text-h1 text-center">{{ bookingsToday }}</p>
      </v-card>
    </v-col>
    <v-col v-if="checkRole('ROLE_ADMIN')" cols="6" sm="6" md="4" lg="3">
      <v-card class="fill-height">
        <v-card-title>Umsatz heute</v-card-title>
        <p class="text-h2 text-center">
          {{ businessVolumeToday | formatCurrency }}
        </p>
      </v-card>
    </v-col>
    <v-col v-if="checkRole('ROLE_ADMIN')" cols="6" sm="6" md="4" lg="3">
      <v-card class="fill-height">
        <v-card-title>Kurstermine heute</v-card-title>
        <p class="text-h1 text-center">{{ eventsToday }}</p>
      </v-card>
    </v-col>
    <v-col v-if="checkRole('ROLE_ADMIN')" cols="6" sm="6" md="4" lg="3">
      <v-card class="fill-height">
        <v-card-title></v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="3">
      <v-card class="fill-height">
        <v-card-title>Changelog</v-card-title>
        <Changelog></Changelog>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="4" xl="3">
      <v-card class="fill-height">
        <v-card-title>Die letzten Buchungen</v-card-title>
        <BookingOverview></BookingOverview>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="4" xl="3">
      <v-card class="fill-height">
        <v-card-title>Kursauslastung kommender Kurse</v-card-title>
        <CourseOverview></CourseOverview>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="4" xl="3">
      <v-card class="fill-height">
        <v-card-title></v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BookingOverview from "../components/BookingOverview";
import CourseOverview from "@/components/course/CourseOverview";
import Changelog from "@/components/changelog/Changelog";

export default {
  name: "Dashboard",
  components: {
    Changelog,
    CourseOverview,
    BookingOverview
  },
  computed: {
    todos: function() {
      return this.$store.state.todos;
    },
    users: function() {
      return this.$store.state.users;
    },
    bookingsToday: function() {
      let now = new Date();
      const today = now.toISOString().split("T")[0];
      const bookings = this.$store.state.bookings.all.filter(
        booking => booking.date.split(" ")[0] === today
      );
      return bookings.length;
    },
    businessVolumeToday: function() {
      const now = new Date();
      const today = now.toISOString().split("T")[0];
      const bookings = this.$store.state.bookings.all.filter(
        booking => booking.date.split(" ")[0] === today
      );
      let businessVolume = 0;
      bookings.forEach(booking => {
        businessVolume += booking.price;
      });
      return businessVolume;
    },
    eventsToday: function() {
      const now = new Date();
      const today = now.toISOString().split("T")[0];
      const events = this.$store.state.events.all.filter(
        event => event.startDate.split("T")[0] === today
      );
      return events.length;
    }
  },
  methods: {
    getUser: function(id) {
      return this.users.find(user => user.id === id);
    },
    checkRole: function(role) {
      return this.$store.getters["auth/checkRole"](role);
    }
  }
};
</script>
