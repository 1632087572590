<template>
  <div>
    <v-dialog max-width="1000" v-model="showMultipleEdit">
      <EventMultipleEdit
        v-if="showMultipleEdit"
        :bathLanes="bathLanes"
        :selectedEvents="selectedEvents"
        @saved="showMultipleEdit = false"
        @close="showMultipleEdit = false"
      />
    </v-dialog>
    <Table
      :items="filteredEvents"
      :headers="headers"
      :search="search"
      :itemsPerPage="20"
    >
      <template v-slot:actions>
        <slot />
        <v-btn class="mb-2" @click="editMultipleEvents">{{
          showCheckboxes && selectedEvents.length > 0
            ? " Termine bearbeiten"
            : " Termine auswählen"
        }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mb-2" @click="$emit('new-event')">Neuer Termin</v-btn>
      </template>
      <template v-slot:item.selection="{ item }">
        <v-checkbox v-model="selection[item.id]"></v-checkbox>
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.startDate | formatDateTime }}
      </template>
      <template v-slot:item.error="{ item }">
        <v-btn fab x-small :color="item.error ? 'red' : 'green'"></v-btn>
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ item.endDate | formatDateTime }}
      </template>
      <template v-slot:item.courseTitle="{ item }">
        <v-chip outlined label @click="search = item.courseTitle">{{
          item.courseTitle
        }}</v-chip>
      </template>
      <template v-slot:item.instructorNames="{ item }">
        <v-chip
          class="mr-2"
          label
          outlined
          v-for="instructor in item.instructors"
          :key="instructor.shortcut"
          @click="search = instructor.name"
          >{{ instructor.shortcut }}</v-chip
        >
      </template>
      <template v-slot:item.bathTitle="{ item }">
        <v-chip label outlined @click="search = item.bathTitle"
          >{{ item.bathShortcut }} {{ item.bathLaneTitle }}</v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon @click="$emit('item-edit', item)"
          ><v-icon>fal fa-edit</v-icon></v-btn
        >
        <v-btn icon @click="$emit('item-delete', item)"
          ><v-icon>fal fa-trash</v-icon></v-btn
        >
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/utils/Table";
import EventMultipleEdit from "@/components/event/EventMultipleEdit";
export default {
  name: "EventsTable",
  components: { Table, EventMultipleEdit },
  props: [
    "filterCourse",
    "filterBath",
    "filterInstructor",
    "filterUpcoming",
    "bathLanes"
  ],
  data() {
    return {
      selection: {},
      search: "",
      showMultipleEdit: false,
      showCheckboxes: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Fehler", value: "error" },
        { text: "Startdatum", value: "startDate" },
        { text: "Enddatum", value: "endDate" },
        { text: "Kurs", value: "courseTitle" },
        { text: "Schwimmlehrer", value: "instructorNames" },
        { text: "Bad", value: "bathTitle" },
        { text: "Aktion", value: "action" }
      ],
      eventsTable: []
    };
  },
  computed: {
    showSnackbar: function() {
      return !this.showMultipleEdit && this.selectedEvents.length > 0;
    },
    selectedEvents: function() {
      return Object.entries(this.selection).reduce((result, [key, value]) => {
        if (value) {
          result.push(key);
        }
        return result;
      }, []);
    },
    courses: function() {
      return this.$store.state.courses.all;
    },
    events: function() {
      return this.$store.state.events.all;
    },
    filteredEvents: function() {
      const now = new Date();
      let events = this.eventsTable;
      if (this.filterBath !== null)
        events = events.filter(item => item.bathLane === this.filterBath);
      if (this.filterCourse !== null)
        events = events.filter(item => item.course === this.filterCourse);
      if (this.filterUpcoming === true)
        events = events.filter(item => new Date(item.startDate) > now);
      return events;
    }
  },
  methods: {
    editMultipleEvents() {
      if (!this.showCheckboxes) {
        this.showCheckboxes = true;
        this.headers.unshift({ text: "", value: "selection" });
      } else if (this.showCheckboxes && this.selectedEvents.length < 1) {
        this.showCheckboxes = false;
        this.headers = this.headers.filter(
          ({ value }) => value !== "selection"
        );
      } else {
        this.showMultipleEdit = true;
      }
    },
    close() {
      this.selection = {};
      this.showMultipleEdit = false;
    },
    getCourseTitle(id) {
      return this.$store.getters["courses/getById"](id).title;
    },
    getInstructor(id) {
      const instructor = this.$store.getters["instructors/getById"](id);
      if (instructor !== undefined) return instructor;
      return {};
    },
    getBath(bathLaneId) {
      const bathLane = this.getBathLane(bathLaneId);
      return this.$store.getters["baths/getById"](bathLane.bath);
    },
    getBathLane(bathLaneId) {
      return this.$store.getters["bathLanes/getById"](bathLaneId);
    },
    initiate: function() {
      let eventsArray = [];
      const events = this.events;
      events.forEach(item => {
        let event = Object.assign({}, item);
        event.courseTitle = this.getCourseTitle(item.course);

        event.instructorNames = [];
        event.instructors = [];
        event.instructor.forEach(instructorId => {
          const instructor = this.getInstructor(instructorId);
          event.instructorNames.push(
            instructor.firstname + " " + instructor.lastname
          );
          event.instructors.push({
            name: instructor.firstname + " " + instructor.lastname,
            shortcut: instructor.shortcut
          });
        });

        const bathLane = this.getBathLane(item.bathLane);
        const bath = this.getBath(item.bathLane);
        event.bathTitle = bath.title;
        event.bathShortcut = bath.shortcut;
        event.bathLaneTitle = bathLane.title;

        eventsArray.push(event);
      });
      this.eventsTable = eventsArray;
    }
  },
  created() {
    this.initiate();
  },
  watch: {
    showMultipleEdit: function(value) {
      if (!value) {
        this.close();
      }
    },
    events: function() {
      this.initiate();
    }
  }
};
</script>

<style scoped>
.center {
  display: block;
  margin: auto;
}
</style>
