<template>
  <v-navigation-drawer app clipped v-model="showNavigationProxy">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Manager
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <template v-for="item in items">
        <v-list-item
          v-if="item.subItems.length === 0 && checkRole(item.role)"
          :key="item.title"
          :to="{ name: item.routeName }"
        >
          <v-list-item-icon class="text-center">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else-if="checkRole(item.role)"
          no-action
          :key="item.title"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="subitem in item.subItems"
            :key="subitem.title"
            :to="{ name: subitem.routeName }"
            :sub-group="false"
          >
            <v-list-item-icon class="text-center">
              <v-icon>{{ subitem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
      <v-list-item
        :href="'https://buchung.schwimmschul.app/' + settings.alias"
        target="_blank"
      >
        <v-list-item-title>Buchungsseite</v-list-item-title>
        <v-list-item-action>
          <v-icon>fal fa-external-link</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-footer absolute padless>
      <v-card flat tile width="100%" class="text-center">
        <v-card-text>
          <v-btn text v-on:click="sendBugReport()">Fehler melden</v-btn>
        </v-card-text>
      </v-card>
      <v-card flat tile width="100%" class="accent lighten-1 text-center">
        <v-card-text class="white--text">
          Manager Version {{ version }}<br />
          <a href="https://www.schwimmschulagentur.de" target="_blank"
            >Schwimmschulagentur</a
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { version } from "../../package";
import * as Sentry from "@sentry/vue";

export default {
  props: ["showNavigation"],
  computed: {
    showNavigationProxy: {
      get: function() {
        return this.showNavigationValue;
      },
      set: function(value) {
        this.showNavigationValue = value;
        this.$emit("update:showNavigation", value);
      }
    },
    settings() {
      return this.$store.getters["settings/get"];
    }
  },
  data: () => ({
    version: version,
    showNavigationValue: true,
    items: [
      {
        title: "Übersicht",
        icon: "fal fa-home",
        routeName: "dashboard",
        subItems: [],
        role: "ROLE_USER"
      },
      {
        title: "Trainer",
        subItems: [
          {
            title: "Kalender",
            icon: "fal fa-calendar",
            routeName: "instructorCalendar",
            role: "ROLE_INSTRUCTOR"
          }
        ],
        role: "ROLE_INSTRUCTOR"
      },
      {
        title: "Buchungen",
        subItems: [
          {
            title: "Kalender",
            icon: "fal fa-calendar",
            routeName: "bookingCalendar",
            role: "ROLE_USER"
          },
          {
            title: "Buchungen",
            icon: "fal fa-shopping-cart",
            routeName: "bookings",
            role: "ROLE_USER"
          },
          {
            title: "Einzelbuchungen",
            icon: "fal fa-shopping-basket",
            routeName: "bookingsSingle",
            role: "ROLE_USER"
          },
          {
            title: "Warteliste",
            icon: "fal fa-hourglass",
            routeName: "waitingList",
            role: "ROLE_USER"
          },
          {
            title: "Kunden",
            icon: "fal fa-users",
            routeName: "customers",
            role: "ROLE_USER"
          },
          {
            title: "Teilnehmer",
            icon: "fal fa-swimmer",
            routeName: "participants",
            role: "ROLE_USER"
          }
        ],
        role: "ROLE_USER"
      },
      {
        title: "Kurse & Clubs",
        subItems: [
          {
            title: "Kurse & Clubs",
            icon: "fal fa-calendar",
            routeName: "courses",
            role: "ROLE_USER"
          },
          {
            title: "Termine",
            icon: "fal fa-calendar-day",
            routeName: "events",
            role: "ROLE_USER"
          },
          {
            title: "Monatsplan",
            icon: "fal fa-calendar-check",
            routeName: "planMonth",
            role: "ROLE_ADMIN"
          },
          {
            title: "Fehlerliste",
            icon: "fal fa-exclamation-circle",
            routeName: "errorList",
            role: "ROLE_USER"
          }
        ],
        role: "ROLE_USER"
      },
      {
        title: "Buchhaltung",
        subItems: [
          {
            title: "Gutscheine",
            icon: "fal fa-money-check",
            routeName: "coupons",
            role: "ROLE_USER"
          },
          {
            title: "Zahlungen",
            icon: "fal fa-wallet",
            routeName: "payments",
            role: "ROLE_USER"
          },
          {
            title: "Offene Buchungen",
            icon: "fal fa-coins",
            routeName: "openInvoices",
            role: "ROLE_USER"
          }
        ],
        role: "ROLE_USER"
      },
      {
        title: "Stammdaten",
        subItems: [
          //{ title: 'Qualifikationen', icon: 'fal fa-water', routeName: '/' },
          {
            title: "Standorteinstellungen",
            icon: "fal fa-location",
            routeName: "location",
            role: "ROLE_ADMIN"
          },
          {
            title: "Benutzer",
            icon: "fal fa-user",
            routeName: "instructors",
            role: "ROLE_ADMIN"
          },
          {
            title: "Schwimmbäder",
            icon: "fal fa-swimming-pool",
            routeName: "baths",
            role: "ROLE_USER"
          },
          {
            title: "Vorlagen",
            icon: "fal fa-file-spreadsheet",
            routeName: "courseDefinitions",
            role: "ROLE_USER"
          }
          //{ title: 'Einstellungen', icon: 'fal fa-cog', routeName: '/' }
        ],
        role: "ROLE_ADMIN"
      }
    ]
  }),
  methods: {
    checkRole: function(role) {
      return this.$store.getters["auth/checkRole"](role);
    },
    sendBugReport: function() {
      Sentry.feedbackIntegration({
        colorScheme: "light",
        autoInject: false,
        showBranding: false,
        showName: false,
        showEmail: false,
        formTitle: "Fehler melden",
        messageLabel: "Beschreibung",
        messagePlaceholder: "",
        successMessageText: "Vielen Dank für Ihre Nachricht.",
        cancelButtonLabel: "Abbrechen",
        submitButtonLabel: "Bericht senden"
      }).openDialog();
    }
  },
  mounted() {
    this.showNavigationValue = this.showNavigation;
  },
  watch: {
    showNavigation: function(value) {
      this.showNavigationProxy = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 0.5rem !important;
}

footer a {
  text-decoration: none;
}
</style>
