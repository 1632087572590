<template>
  <Table :items="courseDefinitions" :headers="headers" :itemsPerPage="20">
    <template v-slot:item.category="{ item }">
      {{ getCategory(item.category).title }}
    </template>
    <template v-slot:item.type="{ item }">
      <template v-if="item.type === 'course'">Kurs</template>
      <template v-else-if="item.type === 'club'">Club</template>
      <template v-else-if="item.type === 'hybrid-course'">Hybrid Kurs</template>
    </template>
    <template v-slot:item.price="{ item }"> {{ item.price }} € </template>
    <template v-slot:item.action="{ item }">
      <v-btn icon v-on:click="$emit('item-edit', item.id)"
        ><v-icon>fal fa-edit</v-icon></v-btn
      >
      <v-btn icon v-on:click="$emit('item-delete', item)"
        ><v-icon>fal fa-trash</v-icon></v-btn
      >
    </template>
    <template v-slot:action-button>
      <v-btn @click="$emit('item-new')"
        ><v-icon left>fal fa-plus</v-icon> Neue Kursvorlage</v-btn
      >
    </template>
  </Table>
</template>

<script>
import Table from "@/components/utils/Table";
export default {
  name: "CourseDefinitionsTable",
  components: {
    Table
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Titel", value: "title" },
        { text: "Kategorie", value: "category" },
        { text: "Typ", value: "type" },
        { text: "Einheiten pro Woche", value: "lessonPerWeek", align: "right" },
        { text: "Dauer", value: "lessonDuration", align: "right" },
        { text: "Preis", value: "price", align: "right" },
        { text: "Aktion", value: "action" }
      ],
      typeSelect: [
        { text: "Club", value: "club" },
        { text: "Kurs", value: "course" },
        { text: "Hybridkurs", value: "hybrid-course" }
      ]
    };
  },
  computed: {
    courseDefinitions: function() {
      return this.$store.getters["courseDefinitions/getAll"];
    },
    courseDefinitionCategories: function() {
      return this.$store.state.courseDefinitionCategories.all;
    }
  },
  methods: {
    getCategory(id) {
      const category = this.courseDefinitionCategories.find(
        item => item.id === id
      );
      return category ?? { title: "Keine Kategorie vorhanden" };
    }
  }
};
</script>
