<template>
  <v-select
    v-model="genderProxy"
    label="Geschlecht"
    :items="genderOptions"
    :rules="rules"
  ></v-select>
</template>

<script>
export default {
  name: "Gender",
  props: ["gender", "rules"],
  computed: {
    genderProxy: {
      set: function(value) {
        this.$emit("update:gender", value);
        this.value = value;
      },
      get: function() {
        return this.value;
      }
    }
  },
  data: function() {
    return {
      value: this.gender,
      genderOptions: [
        { value: "divers", text: "Divers" },
        { value: "male", text: "Männlich" },
        { value: "female", text: "Weiblich" }
      ]
    };
  },
  methods: {},
  watch: {
    gender(gender) {
      this.value = gender;
    }
  }
};
</script>

<style scoped></style>
