<template>
  <v-container fluid>
    <v-form ref="form" lazy-validation>
      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">Allgemein</v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="ID"
                readonly
                disabled
                v-model="values.id"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Firma"
                v-model="values.company"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Eigentümer"
                v-model="values.owner"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">Adresse</v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Straße und Hausnummer"
                v-model="values.street"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="PLZ"
                v-model="values.postal"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Stadt"
                v-model="values.city"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">Kommunikation</v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Telefonnummer"
                v-model="values.phone"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="E-Mail"
                v-model="values.email"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Homepage"
                v-model="values.homepage"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Facebook"
                v-model="values.facebook"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Instagram"
                v-model="values.instagram"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">Bank</v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Bank"
                v-model="values.bank_name"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="IBAN"
                v-model="values.iban"
                :rules="[rules.required]"
            /></v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="BIC"
                v-model="values.bic"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col />
          </v-row>
        </v-container>
      </v-card>
      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">PayPal</v-card-title>
          <v-row>
            <v-col>
              <v-text-field
                type="text"
                label="Client ID"
                v-model="values.paypalClientId"
              />
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">Buchungen</v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-switch
                label="Kleinunternehmerregelung nach § 19 UStG"
                v-model="values.smallBusinessRegulation"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-switch
                label="Einzelbuchungen erlauben"
                v-model="values.enableSingleBookings"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-switch
                disabled
                label="Sollen Kurse nach Kursstart gebucht werden können?"
                v-model="values.enableBookingAfterCourseStart"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="number"
                min="0"
                steps="1"
                label="Kündigungsdauer für Clubbuchungen"
                v-model="values.monthToCancelClub"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Erinnerungsmail Club"
                hint="An wen sollen die monatlichen Erinnerungsmails für
                Clubteilnehmer versendet werden?"
                v-model="values.clubReminderSendingIntervall"
                :items="[
                  { text: 'Alle', value: 'all' },
                  { text: 'Nur offene Buchungen', value: 'ońlyOpenInvoices' }
                ]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Link AGB"
                v-model="values.linkAgb"
                prepend-icon="fal fa-globe"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Link Datenschutz"
                prepend-icon="fal fa-globe"
                v-model="values.linkDataPrivacy"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="text"
                label="Link Danke Seite"
                prepend-icon="fal fa-globe"
                hint="Wenn ein Link zu einer Danke Seite angegeben ist, wird nach dem Abschluss einer Buchung dorthin weiter geleitet."
                v-model="values.linkThankYouPage"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card flat class="mb-4">
        <v-container>
          <v-card-title class="pl-0">Einstellungen</v-card-title>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                type="number"
                min="0"
                steps="1"
                label="Nach wie vielen Tagen sollen Einträge aus der Warteliste automatisch gelöscht werden?"
                hint="Wenn keine Einträge gelöscht werden sollen bitte 0 eintragen."
                v-model="values.deleteWaitingListEntriesAfterDays"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card-actions>
        <v-btn @click="saveLocationData">Speichern</v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      changeSuccessful: false,
      values: {},
      rules: {
        required: v => !!v || "Dies ist ein Pflichtfeld"
      }
    };
  },
  created() {
    const id = this.$store.getters["auth/currentLocation"];
    this.$store.dispatch("location/getLocationData", id).then(result => {
      if (result) {
        this.values = result;
      }
    });
  },
  methods: {
    saveLocationData: function() {
      const vue = this;
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("location/setLocationData", this.values)
          .then(() => {
            vue.$store.dispatch("messages/add", {
              message: "Standort gespeichert",
              color: "success"
            });
          })
          .catch(() => {
            vue.$store.dispatch("messages/add", {
              message: "Ein unerwarteter Fehler ist aufgetreten",
              color: "error"
            });
          });
      } else {
        this.$store.dispatch("messages/add", {
          message:
            "Das Formular kann nicht gespeichert werden. Bitte prüfen Sie die rot markierten Felder.",
          color: "error"
        });
      }
    }
  }
};
</script>

<style scoped></style>
