<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <PaymentsTable
        @check-open-payments="checkOpenPayments"
        @open-dialog="openImportDialog"
        @open-export-dialog="openExportDialog"
        :filterItems="filterTableItems"
      />
      <v-dialog max-width="1000" v-model="dialogImport">
        <v-card flat>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>CSV Import</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogImport = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-file-input
              accept="text/csv"
              show-size
              label="CSV-Datei auswählen"
              @change="parseFile"
            ></v-file-input>
            <v-row>
              <v-col>SEPA-Gutschrift: {{ standingOrder }}</v-col>
              <v-col>SEPA-Echtzeitüberweisung: {{ bankingOrder }}</v-col>
            </v-row>
            <v-data-table :items="csvImport" :headers="csvHeader">
              <template v-slot:item.action="{ item }">
                <v-btn icon v-on:click="deleteImportItem(item)"
                  ><v-icon>fal fa-trash</v-icon></v-btn
                >
              </template>
              <template v-slot:item.value="{ item }">
                {{ item.value | formatCurrency }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="importData">Importieren</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog max-width="1000" v-model="dialogExport">
        <v-card flat>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>CSV Export</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogExport = false"
              ><v-icon>fal fa-times</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-row>
              <v-col>
                <h2 class="ma-8">Startdatum</h2>
                <v-date-picker
                  v-model="exportDateFrom"
                  locale="de-de"
                  full-width
                  landscape
                  :first-day-of-week="1"
                ></v-date-picker>

                <v-text-field v-model="exportDateFrom" readonly></v-text-field>
              </v-col>
              <v-col>
                <h2 class="ma-8">Enddatum</h2>
                <v-date-picker
                  v-model="exportDateTill"
                  locale="de-de"
                  full-width
                  landscape
                  :first-day-of-week="1"
                ></v-date-picker>

                <v-text-field v-model="exportDateTill" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Art des Datums"
                  v-model="exportDateType"
                  :items="dateTypes"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="exportData">exportieren</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import PaymentsTable from "@/components/payment/PaymentsTable";
import Papa from "papaparse";
import sleep from "@/utils/sleep";

const SUBJECT_REGEX = /^RINP Dauerauftrag|^CASH CashMgmtTransakt|^SEPA|^Betreff|^Buchung/i;
const BOOKING_NUMBER_REGEX = /^\d+/;
const LETTERS_TO_FIRST_NUMBER = /[^\d]*/;

export default {
  name: "Payments",
  components: { PaymentsTable },
  data: function() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Buchung", value: "booking" },
        { text: "Datum", value: "date" },
        { text: "Information", value: "information" },
        { text: "Betrag", value: "value" },
        { text: "Aktion", value: "action" }
      ],
      search: "",
      filterTable: "all",
      filterTableItems: [
        { text: "Alle", value: "all" },
        { text: "Ohne Zuordnung", value: "open" }
      ],
      dialogImport: false,
      dialogExport: false,
      csvImport: [],
      csvFile: "",
      csvHeader: [
        { text: "Datum", value: "date" },
        { text: "Betreff", value: "subject" },
        { text: "Von", value: "from" },
        { text: "Betrag", value: "value" },
        { text: "Aktion", value: "action" }
      ],
      dataParsed: null,
      exportDateFrom: null,
      exportDateTill: null,
      exportDateType: null,
      dateTypes: [
        { value: "paymentDate", text: "Datum der Zahlung" },
        { value: "importDate", text: "Datum des Imports" }
      ]
    };
  },
  computed: {
    standingOrder: function() {
      return this.csvImport.filter(item => item.type === "SEPA-Gutschrift")
        .length;
    },
    bankingOrder: function() {
      return this.csvImport.filter(
        item => item.type === "SEPA-Echtzeitüberweisung"
      ).length;
    },
    settings: function() {
      return this.$store.getters["settings/get"];
    }
  },
  methods: {
    async checkOpenPayments() {
      const vue = this;
      const payments = this.$store.getters["payments/getAll"].filter(
        ({ booking }) => !booking
      );
      this.$store.dispatch("messages/del");

      if (payments.length < 1) {
        return this.$store.dispatch("messages/add", {
          message: `Es wurden keine unzugeordnete Zahlungen gefunden`,
          color: "success"
        });
      }

      this.$store.dispatch("messages/add", {
        message: `Es wurden insgesamt ${payments.length} unzugeordnete Zahlungen gefunden`,
        color: "warning"
      });

      await sleep(1000);

      let counter = 0;

      await Promise.all(
        payments.map(async payment => {
          payment = { ...payment };

          const bookingID = vue.getBookingIdFromString(
            payment.information,
            LETTERS_TO_FIRST_NUMBER
          );
          if (bookingID && vue.$store.getters["bookings/getById"](+bookingID)) {
            payment.booking = +bookingID;
            try {
              await vue.$store.dispatch("payments/update", payment);
              counter += 1;
            } catch (error) {
              vue.$store.dispatch("messages/add", {
                message: "Es ist ein Fehler aufgetreten",
                color: "error"
              });
            }
          }
        })
      );
      this.$store.dispatch("messages/add", {
        message: `Es wurden ${counter} Zahlungen zugewiesen`,
        color: "success"
      });
    },
    getBookingIdFromString(text, firstRegex) {
      return text
        ?.replace(firstRegex, "")
        ?.trim()
        ?.match(BOOKING_NUMBER_REGEX)?.[0];
    },
    openImportDialog() {
      this.csvFile = "";
      this.csvImport = [];
      this.dataParsed = null;
      this.dialogImport = true;
    },
    openExportDialog() {
      this.dialogExport = true;
    },
    importData: function() {
      const vue = this;
      this.$store
        .dispatch("payments/import", this.csvImport)
        .then(function() {
          vue.csvImport = [];
          vue.csvFile = "";
          vue.dialogImport = false;
          vue.$store.dispatch("messages/add", {
            message: "Zahlungen wurden importiert",
            color: "success"
          });
        })
        .catch(function() {
          vue.$store.dispatch("messages/add", {
            message: "Zahlungen konnten nicht importiert werden",
            color: "error"
          });
        });
    },
    exportData: function() {
      let dateTypeLabel = "Buchung";
      if (this.exportDateType === "importDate") {
        dateTypeLabel = "Import";
      }
      this.$store
        .dispatch("payments/export", {
          from: this.exportDateFrom,
          till: this.exportDateTill,
          dateType: this.exportDateType
        })
        .then(response => {
          const csv = Papa.unparse(
            {
              fields: ["id", "booking", "date", "value", "information"],
              data: response.data
            },
            {
              delimiter: ";"
            }
          );
          const blob = new Blob([csv], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("href", url);
          a.setAttribute(
            "download",
            "Zahlungen vom " +
              this.exportDateFrom +
              " bis " +
              this.exportDateTill +
              " (" +
              dateTypeLabel +
              ").csv"
          );
          a.click();
        });
    },
    deleteImportItem: function(item) {
      const index = this.csvImport.indexOf(item);
      this.csvImport.splice(index, 1);
    },
    parseFile: function(file) {
      if (file === null) return;
      const vue = this;
      const reader = new FileReader();
      reader.onload = function(e) {
        let dataRaw = e.target.result;
        let data = dataRaw.split("\n");
        if (data[0].startsWith("Ums") || data[1].startsWith('"Ums')) {
          data = dataRaw
            .split("\n")
            .slice(5, -1)
            .join("\n");
        } else if (
          data[0].startsWith('"Kontoname') ||
          data[0].startsWith("Kontoname")
        ) {
          data = dataRaw
            .split("\n")
            .slice(1)
            .join("\n");
        } else {
          data = data.join("\n");
        }

        vue.csvFile = data;
        vue.parseData();
      };
      reader.readAsText(file);
    },
    parseData: function() {
      const vue = this;
      Papa.parse(this.csvFile, {
        config: {
          delimiter: ";"
        },
        complete: function(results) {
          vue.csvImport = results.data.reduce((result, item) => {
            const data = vue.parseBankData(item);

            if (data?.type) result.push(data);
            return result;
          }, []);
        }
      });
    },
    parseBankData: function(item) {
      if (this.settings.id === 1 && item.length === 6) {
        let data = {};
        const content = item[3].match(/^Gutschrift.+$/);

        if (content === null) return {};
        data.type = "SEPA-Gutschrift von";

        let subject = "";
        if (item[3]?.split("+")[2] !== undefined) {
          subject = item[3]
            ?.split("+")[2]
            .replace(SUBJECT_REGEX, "")
            ?.trim();
        }
        const bookingID = this.getBookingIdFromString(subject, "");

        if (bookingID && this.$store.getters["bookings/getById"](+bookingID)) {
          data.booking = +bookingID;
        }

        return {
          ...data,
          date: item[1],
          valueDate: item[2],
          from: item[3]
            .split("+")[0]
            .replace("Gutschrift ", "")
            .replace(" EREF", ""),
          subject: subject,
          value: item[4].replace(".", "").replace(",", ".")
        };
      } else if (this.settings.id === 4 && item.length === 6) {
        let data = {};

        const content = item[3].match(
          /^Auftraggeber: (.+) Buchungstext: (.+) Ref.+$/
        );

        if (content === null) return {};

        const subject = content[2]?.replace(SUBJECT_REGEX, "")?.trim();

        const bookingID = this.getBookingIdFromString(
          content[2],
          SUBJECT_REGEX
        );

        if (/.*berweisung/.test(item[2])) {
          data.type = "SEPA-Gutschrift von";
        }

        if (bookingID && this.$store.getters["bookings/getById"](+bookingID)) {
          data.booking = +bookingID;
        }

        return {
          ...data,
          date: item[0],
          valueDate: item[1],
          from: content[1],
          subject: subject,
          value: item[4].replace(".", "").replace(",", ".")
        };
      } else if (item.length === 18) {
        let data = {};

        const bookingID = this.getBookingIdFromString(item[4], SUBJECT_REGEX);

        const subject = item[4]?.replace(SUBJECT_REGEX, "")?.trim();

        if (/SEPA-Gutschrift von/.test(item[2])) {
          data.type = "SEPA-Gutschrift von";
        } else if (/SEPA-Echtzeit(.*)von/.test(item[2])) {
          data.type = "SEPA-Echtzeitüberweisung von";
        }

        if (bookingID && this.$store.getters["bookings/getById"](+bookingID)) {
          data.booking = +bookingID;
        }

        return {
          ...data,
          date: item[0],
          valueDate: item[1],
          from: item[3],
          subject: subject,
          value: item[16].replace(".", "").replace(",", ".")
        };
      } else if (item.length === 19) {
        let data = {};

        const bookingID = this.getBookingIdFromString(item[4], SUBJECT_REGEX);

        const subject = item[4]?.replace(SUBJECT_REGEX, "")?.trim();

        if (/SEPA-Gutschrift von/.test(item[2])) {
          data.type = "SEPA-Gutschrift von";
        } else if (/SEPA-Echtzeit(.*)von/.test(item[2])) {
          data.type = "SEPA-Echtzeitüberweisung von";
        }

        if (bookingID && this.$store.getters["bookings/getById"](+bookingID)) {
          data.booking = +bookingID;
        }

        return {
          ...data,
          date: item[0],
          valueDate: item[1],
          from: item[3],
          subject: subject,
          value: item[17].replace(".", "").replace(",", ".")
        };
      }
    }
  }
};
</script>

<style scoped></style>
