<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
        <slot />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Grid",
};
</script>
