<template>
  <v-card flat>
    <v-toolbar color="primary" dark>
      <v-toolbar-title v-if="event">Termin ändern</v-toolbar-title>
      <v-toolbar-title v-else>Termin anlegen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"><v-icon>fal fa-times</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="course">
        <h2 class="mt-4">Kurs</h2>
        <v-autocomplete
          label="Kurs"
          v-model="event.course"
          :items="courses"
          item-value="id"
          :item-text="(item) => 'K' + item.id + ' ' + item.title"
        ></v-autocomplete>
        <h2 class="mt-4">Termin</h2>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-date-picker
                v-model="event.startDate"
                full-width
              ></v-date-picker>
            </v-col>
            <v-col>
              <v-time-picker
                v-model="event.startTime"
                format="24hr"
                full-width
              ></v-time-picker>
            </v-col>
          </v-row>
        </v-container>
        <h2 class="mt-4">Bad</h2>
        <v-autocomplete
          label="Bad und Bahn"
          :items="bathLanes"
          v-model="event.bathLane"
        ></v-autocomplete>
        <h2 class="mt-4">Trainer</h2>
        <InstructorCheckboxes
          :instructor-ids.sync="event.instructor"
        ></InstructorCheckboxes>

        <v-switch
          v-model="event.error"
          label="Fehler/Muss noch bearbeitet werden"
        ></v-switch>
      </v-form>
      <v-alert :value="errors.length > 0" type="error">
        <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
      </v-alert>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="secondary" :disabled="!isValid" @click="checkItem"
        >Termin prüfen</v-btn
      >
      <v-btn
        color="primary"
        :disabled="!isValid || !isChecked"
        @click="saveItem"
        >Speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import InstructorCheckboxes from "@/components/instructor/InstructorCheckboxes";

export default {
  name: "EventEdit",
  props: ["ev", "courses", "bathLanes"],
  components: {
    InstructorCheckboxes,
  },
  data() {
    return {
      isChecked: false,
      errors: [],
      event: { ...this.ev },
    };
  },
  methods: {
    editItem(event) {
      this.event = Object.assign({}, event);
      this.event.startDate = this.$options.filters.formatDateEn(
        event.startDate
      );
      this.event.startTime = this.$options.filters.formatTime(event.startDate);
    },
    saveItem() {
      const vue = this;
      if (this.event.id > 0) {
        this.$store.dispatch("events/update", this.event).then(() => {
          vue.$emit("close");
          vue.$store.dispatch("messages/add", {
            message: "Termine wurde gespeichert",
            color: "success",
          });
        });
      } else {
        this.$store.dispatch("events/add", this.event).then(() => {
          vue.$emit("close");
          vue.$store.dispatch("messages/add", {
            message: "Termine wurde gespeichert",
            color: "success",
          });
        });
      }
    },
    checkItem() {
      const vue = this;
      this.isChecked = false;
      this.$store.dispatch("events/check", this.event).then(function(data) {
        if (data.length > 0) {
          vue.$store.dispatch("messages/add", {
            message: "Bei der Prüfung wurden Fehler gefunden",
            color: "error",
          });
        } else {
          vue.$store.dispatch("messages/add", {
            message: "Termine wurde gerpüft und keine Fehler gefunden",
            color: "success",
          });
        }
        vue.isChecked = true;
        vue.errors = data;
      });
    },
  },
  computed: {
    isValid() {
      if (
        this.event.instructor === undefined ||
        this.event.instructor.length === 0
      )
        return false;
      if (
        this.event.startDate === undefined ||
        !/[0-9.]+/.test(this.event.startDate)
      )
        return false;
      if (
        this.event.startTime === undefined ||
        !/[0-9:]+/.test(this.event.startTime)
      )
        return false;
      return true;
    },
  },
  beforeMount() {
    if (this.event.id) {
      this.editItem(this.event);
    } else {
      this.event = { instructor: [] };
    }
  },
};
</script>

<style scoped></style>
