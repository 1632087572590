<template>
  <v-card flat>
    <v-card-title>
      <v-text-field label="Suche" v-model="searchProxy" clearable />
      <v-spacer />
      <slot name="actions" />
      <v-spacer />
      <v-spacer />
      <slot name="button" />
    </v-card-title>
    <v-data-table
      :items="items"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :search="intSearch"
    >
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="item">
        <slot :name="slot" v-bind="item || {}" />
      </template>
    </v-data-table>
    <v-card-actions>
      <slot name="action-button" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Table",
  props: ["headers", "itemsPerPage", "items", "search"],
  data() {
    return {
      intSearch: this.search
    };
  },
  computed: {
    searchProxy: {
      set: function(value) {
        this.$emit("update:search", value);
        this.intSearch = value;
      },
      get: function() {
        return this.intSearch;
      }
    }
  },
  mounted() {
    this.intSearch = this.search;
  }
};
</script>
