<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12">
      <v-card outlined>
        <v-row>
          <v-col
            lg="3"
            v-for="participant in participantsWithMoreThanOneClubBooking"
            :key="participant"
          >
            <v-card flat>
              <v-card-title>
                {{ getParticipantName(participant) }}
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="booking in getBookingsFromParticipants(participant)"
                    :key="booking.id"
                    three-line
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ booking.id }}
                        {{ getCourseTitle(booking.course) }} #{{
                          booking.course
                        }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        Start: {{ booking.clubStartDate | formatDate }}<br />
                        Ende: {{ booking.clubEndDate | formatDate }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "ErrorList",
  data() {
    return {
      participantsWithMoreThanOneClubBooking: []
    };
  },
  computed: {
    bookings() {
      return this.$store.state.bookings.all.filter(
        booking => booking.type === "club"
      );
    },
    participants() {
      return this.$store.state.participants.all;
    },
    course() {
      return this.$store.state.courses.all;
    },
    courseDefinition() {
      return this.$store.getters["courseDefinitions/getAllWithDeleted"];
    }
  },
  methods: {
    getCourseTitle: function(courseId) {
      const course = this.course.find(course => course.id === courseId);
      const courseDefinition = this.courseDefinition.find(
        courseDefinition => courseDefinition.id === course.definition
      );

      return courseDefinition.title;
    },
    getParticipantName: function(id) {
      const participant = this.participants.find(
        participant => participant.id === id
      );
      if (participant === undefined) return "";

      return participant.firstname + " " + participant.lastname;
    },
    getBookingsFromParticipants: function(participant) {
      const bookings = this.bookings.filter(
        booking => booking.participant === participant && booking.event === null
      );
      if (bookings === undefined) return {};

      return bookings;
    }
  },
  mounted() {},
  created() {
    ApiService.get("/participants/doubleBookings").then(response => {
      if (response.data.length > 0) {
        this.participantsWithMoreThanOneClubBooking = response.data;
      }
    });
  }
};
</script>
