<template>
  <v-list>
    <v-list-item v-for="event in items" :key="event.id">
      <v-list-item-icon>
        <v-icon color="green" v-if="event.status === '0'">fal fa-check</v-icon>
        <v-icon color="warning" v-else-if="event.status === '1'"
          >fal fa-business-time</v-icon
        >
        <v-icon color="error" v-else-if="event.status === '2'"
          >fal fa-times</v-icon
        >
        <v-icon v-else color="grey">fal fa-question</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          event.startDate | formatDateTime
        }}</v-list-item-title>
        <v-list-item-subtitle
          >Trainer: {{ getInstructor(event.instructor) }}<br />
          {{ event.comment }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="eventsInPast > 5">
      <v-list-item-content @click="limitItems = !limitItems">
        <v-btn text v-if="limitItems">Weitere anzeigen</v-btn>
        <v-btn text v-else>Weniger anzeigen</v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "Presence",
  props: ["booking"],
  data() {
    return {
      limitItems: true,
      eventsInPast: 0,
      events: []
    };
  },
  computed: {
    items() {
      const vue = this;
      const now = new Date();

      if (vue.events.length > 0) {
        let events = [];
        vue.events.forEach(event => {
          const eventDate = new Date(event.startDate);
          let addElement = true;
          if (vue.booking.type === "club") {
            const clubStartDate = new Date(vue.booking.clubStartDate);
            if (vue.booking.clubStartDate !== null && clubStartDate > eventDate)
              addElement = false;

            const clubEndDate = new Date(vue.booking.clubEndDate);
            if (vue.booking.clubEndDate !== null && clubEndDate < eventDate)
              addElement = false;
          }
          if (eventDate > now) addElement = false;

          if (addElement) events.push(event);
        });
        vue.eventsInPast = events.length;

        events.sort((a, b) => {
          return new Date(b.startDate) - new Date(a.startDate);
        });

        vue.eventsInPast = events.length;

        return this.limitItems ? events.splice(0, 5) : events;
      }
      return vue.events;
    },
    instructors() {
      return this.$store.state.instructors.all;
    }
  },
  methods: {
    getInstructor: function(instructorIds) {
      const vue = this;
      const instructorNames = instructorIds.map(id => {
        const instructor = vue.instructors.find(
          instructor => instructor.id === id
        );
        if (instructor === undefined) return "";
        return instructor.firstname + " " + instructor.lastname;
      });
      return instructorNames.join(", ");
    }
  },
  created() {
    this.$store
      .dispatch("bookings/getPresence", this.booking.id)
      .then(response => {
        this.events = response.data;
      });
  }
};
</script>
