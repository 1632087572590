import ApiService from "../../common/api.service";
import Vue from "vue";

const state = {
  all: []
};

// getters
const getters = {
  getById: state => id => {
    return state.all.find(item => item.id === id);
  },
  getByCourseId: state => courseId => {
    return state.all.filter(item => item.course === courseId);
  }
};

// actions
const actions = {
  check(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/events/check", item)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  add(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.post("/events", item)
        .then(response => {
          context.commit("ADD", response.data);
          context.dispatch("getAll");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.put("/events/" + item.id, item)
        .then(response => {
          context.commit("UPDATE", response.data);
          context.dispatch("getAll");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delete(context, item) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/events/" + item.id, item)
        .then(() => {
          context.commit("DEL", item);
          context.dispatch("getAll");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAll(context) {
    ApiService.get("/events").then(response => {
      context.commit("SET", response.data);
    });
  },
  getPresence(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/events/${id}/presence`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addPresence(context, { id, booking, status, comment }) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/events/${id}/presence`, { booking, status, comment })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updatePresence(context, { id, booking, status, comment, presenceId }) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/events/${id}/presence/${presenceId}`, {
        booking,
        status,
        comment
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  ADD(state, definition) {
    state.all.push(definition);
  },
  SET(state, definitions) {
    state.all = definitions;
  },
  DEL(state, item) {
    state.all.splice(state.all.indexOf(item), 1);
  },
  UPDATE(state, item) {
    const index = state.all.findIndex(element => element.id === item.id);
    Vue.set(state.all, index, item);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
